import {
	VictoryPie,
	VictoryTooltip,
	VictoryContainer,
	VictoryPieProps,
} from 'victory';
import {
	Legend,
	LegendColor,
	LegendItem,
	LegendLabel,
	ChartAndLegendContainer,
} from 'PortfolioAnalytics/styles/PortfolioUI';
import { CHART_HEIGHT } from './chartsConstants';
import { colors } from '@compstak/ui-kit';
import { PORTFOLIO_CHART_COLORS } from '../constants';

export const PortfolioPieChart = ({
	data,
	events,
}: {
	data: VictoryPieProps['data'];
	events: VictoryPieProps['events'];
}) => {
	if (!data) return null;

	return (
		<ChartAndLegendContainer>
			<VictoryPie
				labelComponent={
					<VictoryTooltip
						flyoutStyle={{
							stroke: 'none',
							fill: colors.gray.gray700,
						}}
						style={{ fill: colors.white.white }}
						text={(d) => `${d.datum.xName} - ${d.datum.label}`}
					/>
				}
				containerComponent={
					<VictoryContainer responsive={false} style={{ margin: '0 auto' }} />
				}
				colorScale={PORTFOLIO_CHART_COLORS}
				data={data}
				height={CHART_HEIGHT}
				style={{
					parent: {
						height: CHART_HEIGHT,
					},
					data: {
						cursor: 'pointer',
						stroke: colors.white.white,
						strokeWidth: 2,
					},
				}}
				animate={{
					duration: 500,
				}}
				events={events}
			/>
			<Legend>
				{data.map((dataPoint, i) =>
					dataPoint.x && dataPoint.label && dataPoint.y ? (
						<LegendItem key={i}>
							<LegendColor color={PORTFOLIO_CHART_COLORS[i]} />
							<LegendLabel>
								{dataPoint.x} - {dataPoint.label}
							</LegendLabel>
						</LegendItem>
					) : null
				)}
			</Legend>{' '}
		</ChartAndLegendContainer>
	);
};
