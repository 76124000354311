import { usePropertyLeasesQuery, usePropertyLeasesSummaryQuery } from 'api';
import { useIsExchange } from 'hooks';
import { getIsLeaseCompActive } from 'utils/getIsLeaseCompActive';

export const useOnlyOneLockedActiveLease = (propertyId: number) => {
	const isExchange = useIsExchange();
	const { data: summary, isFetching: isFetchingSummary } =
		usePropertyLeasesSummaryQuery(propertyId);

	const isOneActiveLeaseOnExchange =
		isExchange && summary?.activeLeaseCount === 1;

	const { data: leases, isFetching: isFetchingLeases } = usePropertyLeasesQuery(
		{ propertyId: propertyId },
		{ enabled: isOneActiveLeaseOnExchange }
	);

	const lockedActiveLease =
		isOneActiveLeaseOnExchange &&
		leases?.comps.find(
			(lease) => getIsLeaseCompActive(lease) && lease.own === false
		);

	return {
		data: lockedActiveLease,
		isFetching: isFetchingSummary || isFetchingLeases,
	};
};
