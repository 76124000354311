import { Pending, TooltipV2 } from '@compstak/ui-kit';
import { Fragment, ReactNode, useMemo } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { getTicks } from './getTicks';
import { useChart } from './useChart';

export type MarketStatsChartItem = {
	label: ReactNode;
	start?: number;
	end?: number;
	middle?: number;
};

type Props = {
	items: MarketStatsChartItem[];
	formatValue?: (value: number) => ReactNode;
	formatXTick?: (value: number) => ReactNode;
	/** @default 5 */
	maxNumberOfXTicks?: number;
	isLoading?: boolean;
};

// padding at the start and end of chart
const PADDING_PERCENTAGE = 0.075;
const X_AXIS_LABEL_MARGIN = 3;
const Y_AXIS_LABEL_MARGIN = 3;

const DEFAULT_MAX_NUM_X_TICKS = 5;

const APPROX_ITEM_HEIGHT = 30;
const CHART_HEIGHT = 4 * APPROX_ITEM_HEIGHT;

const GRID_MARGIN_TOP = 16;
const GRID_MARGIN_BOTTOM = 20;

export const MarketStatsChart = (props: Props) => {
	const {
		formatValue,
		formatXTick,
		maxNumberOfXTicks = DEFAULT_MAX_NUM_X_TICKS,
		isLoading,
	} = props;

	const items = useMemo(() => {
		return props.items.filter(
			(item) => item.start != null || item.end != null || item.middle != null
		);
	}, [props.items]);

	const values: number[] = [];

	items.forEach((item) => {
		if (item.start != null) values.push(item.start);
		if (item.end != null) values.push(item.end);
		if (item.middle != null) values.push(item.middle);
	});

	const min = Math.min(...values);
	const max = Math.max(...values);

	const xTicks = getTicks(min, max, maxNumberOfXTicks);

	const minXTick = xTicks[0];
	const maxXTick = xTicks[xTicks.length - 1];
	const xOffset = PADDING_PERCENTAGE * maxXTick;

	// we use ticks for minX and maxX because we want
	// the ticks to always be at the very beginning and end of the chart
	const minX = minXTick - xOffset;
	const maxX = maxXTick + xOffset;

	// to distribute the items on the y axis more equally
	const optimalNumOfItems = CHART_HEIGHT / APPROX_ITEM_HEIGHT;
	const remainingItems = optimalNumOfItems - items.length;

	const minYTick = 0;
	const maxYTick = items.length - 1;
	const yOffset = Math.max(
		remainingItems / optimalNumOfItems,
		PADDING_PERCENTAGE * maxYTick
	);

	const minY = minYTick - yOffset;
	const maxY = maxYTick + yOffset;

	const { xScale, getXTickStyle, getYTickStyle, getLinePath, refChart } =
		useChart({
			minX,
			maxX,
			minY,
			maxY,
		});

	if (isLoading) {
		return <StyledPending margin="0px" />;
	}

	return (
		<Grid>
			<div style={{ position: 'relative' }}>
				{items.map((item, index) => (
					<div
						key={index}
						style={{
							...getYTickStyle(index),
							width: '100%',
						}}
					>
						<GridLabel
							style={{
								transform: `translate(-${Y_AXIS_LABEL_MARGIN}px, -50%)`,
								textAlign: 'right',
							}}
						>
							{item.label}
						</GridLabel>
					</div>
				))}
			</div>
			<Plot ref={refChart}>
				{items.map((_, index) => {
					return (
						<GridLine
							key={index + 'line'}
							style={{
								...getYTickStyle(index),
								width: '100%',
								height: 1,
							}}
						/>
					);
				})}
				{xTicks.map((tick) => {
					return (
						<div
							key={tick + 'grid'}
							style={{
								...getXTickStyle(tick),
								height: '100%',
							}}
						>
							<GridLine
								style={{
									height: '100%',
									width: 1,
								}}
							/>
							<GridLabel
								style={{
									transform: `translate(-50%, ${X_AXIS_LABEL_MARGIN}px)`,
								}}
							>
								{formatXTick ? formatXTick(tick) : tick}
							</GridLabel>
						</div>
					);
				})}
				<Svg viewBox="0 0 100 100" preserveAspectRatio="none">
					{items.map((item, index) => {
						return (
							<Fragment key={index}>
								{item.start != null && item.end != null && (
									<SvgLine
										d={getLinePath({
											data: [{ x: item.start }, { x: item.end }],
											x: (d) => d.x,
											y: () => index,
										})}
									/>
								)}
							</Fragment>
						);
					})}
				</Svg>
				{items.map((item, index) => {
					const minPct = xScale(item.start ?? 0);
					const maxPct = xScale(item.end ?? 0);
					const diff = maxPct - minPct;
					const showEnd = item.end != null && item.end !== item.start;
					const showMiddleLabel =
						item.middle != null && item.start == null && item.end == null;

					const areValuesOnTheSide = showEnd && diff < 20;

					const topValueStyle: CSSProperties = {
						top: 0,
						left: '50%',
						transform: 'translate(-50%, -100%)',
					};

					return (
						<div
							key={index + 'values'}
							style={{
								...getYTickStyle(index),
								width: '100%',
							}}
						>
							{item.middle != null &&
								item.middle !== item.start &&
								item.middle !== item.end && (
									<div
										style={{
											position: 'absolute',
											transform: 'translate(-50%, -50%)',
											left: `${xScale(item.middle)}%`,
										}}
									>
										{showMiddleLabel && (
											<ValueLabel style={topValueStyle}>
												{formatValue ? formatValue(item.middle) : item.middle}
											</ValueLabel>
										)}
										<TooltipV2
											content={
												formatValue
													? `Avg. ${formatValue(item.middle)}`
													: `Avg. ${item.middle}`
											}
											sideOffset={0}
											delayDuration={0}
										>
											<GreenDot />
										</TooltipV2>
									</div>
								)}
							{item.start != null && (
								<div
									style={{
										position: 'absolute',
										transform: 'translate(-50%, -50%)',
										left: `${minPct}%`,
									}}
								>
									<ValueLabel
										style={
											areValuesOnTheSide
												? {
														top: 0,
														left: 0,
														transform: `translate(-100%, -100%)`,
													}
												: topValueStyle
										}
									>
										{formatValue ? formatValue(item.start) : item.start}
									</ValueLabel>
									<Dot />
								</div>
							)}
							{showEnd && item.end != null && (
								<div
									style={{
										position: 'absolute',
										transform: 'translate(-50%, -50%)',
										left: `${maxPct}%`,
									}}
								>
									<ValueLabel
										style={
											areValuesOnTheSide
												? {
														top: 0,
														right: 0,
														transform: `translate(100%, -100%)`,
													}
												: topValueStyle
										}
									>
										{formatValue ? formatValue(item.end) : item.end}
									</ValueLabel>
									<Dot />
								</div>
							)}
						</div>
					);
				})}
			</Plot>
		</Grid>
	);
};

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 2.5fr;
	margin-top: ${GRID_MARGIN_TOP}px;
	margin-bottom: ${GRID_MARGIN_BOTTOM}px;
`;

const Plot = styled.div`
	position: relative;
	height: ${CHART_HEIGHT}px;
`;

const Svg = styled.svg`
	width: 100%;
	height: 100%;
	position: relative;
	* {
		vector-effect: non-scaling-stroke;
	}
`;

const GridLine = styled.div`
	background-color: ${(p) => p.theme.colors.neutral.n30};
`;

const GridLabel = styled.div`
	color: ${(p) => p.theme.colors.neutral.n100};
	font-size: 0.75rem;
	font-weight: 300;
	line-height: 1.5;
	letter-spacing: 0.025em;
`;

const ValueLabel = styled(GridLabel)`
	position: absolute;
	color: #ed6140;
`;

const Dot = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 8px;
	background-color: #ed6140;
`;

const GreenDot = styled(Dot)`
	background-color: #235434;
`;

const SvgLine = styled.path`
	stroke: #2871f6;
	opacity: 0.6;
	fill: none;
	stroke-width: 5px;
`;

export const StyledPending = styled(Pending)`
	height: ${CHART_HEIGHT + GRID_MARGIN_TOP + GRID_MARGIN_BOTTOM}px;
`;
