import { BUILDING_CLASS_ID_TO_NAME, BuildingClassId } from 'api';
import { usePortfolioFilters } from '../../../PortfolioFiltersProvider';
import { CheckboxFilter } from './CheckboxFilter';
import { FilterOption } from '../types';

export const BuildingClassFilter = () => {
	const { filters, setFilters } = usePortfolioFilters();

	return (
		<CheckboxFilter
			label="Building Class"
			emptyValueLabel="All Classes"
			options={OPTIONS}
			value={filters.buildingClassIds}
			onChange={(value) => {
				setFilters((s) => ({
					...s,
					buildingClassIds: value,
				}));
			}}
			buttonProps={{
				style: {
					width: 124,
				},
			}}
		/>
	);
};

const OPTIONS: FilterOption<BuildingClassId>[] = Object.entries(
	BUILDING_CLASS_ID_TO_NAME
).reduce<FilterOption<BuildingClassId>[]>((o, [id, name]) => {
	o.push({
		label: name,
		value: Number(id) as BuildingClassId,
	});
	return o;
}, []);
