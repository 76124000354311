import { DATE_FORMATS } from 'constants/dateFormats';
import dayjs from 'dayjs';
import {
	TrendData,
	TrendFilters,
	TrendMetricFilter,
	Bubbles,
} from 'PortfolioAnalytics/portfolioAnalyticsTypes';
import { InsightDataPoint } from 'Pages/Analytics/analytics';
import { FiltersState } from '../PortfolioFiltersProvider';
import { BuildingClassId, PropertyTypeId } from 'api';

export type PortfolioMarketTrendsFetchProps = {
	portfolioId: number;
	marketTrendFilters: TrendFilters;
	filters: FiltersState;
	buildingClassIds: BuildingClassId[];
	propertyTypeIds: PropertyTypeId[];
};

export const getDateForTimeSpan = (years: number) =>
	dayjs().subtract(years, 'year').format(DATE_FORMATS['YYYY-MM-DD']);

export const formatMarketTrendFilters = ({
	portfolioId,
	marketTrendFilters,
	filters,
	buildingClassIds,
	propertyTypeIds,
}: PortfolioMarketTrendsFetchProps) => {
	const baseBody = {
		properties: [
			marketTrendFilters.metric,
			'executionDate',
			'transactionSize',
			'id',
		],
		fullDataThreshold: 50,
		quartileThreshold: 10e9,
		trendMonths: marketTrendFilters.movAverage,
	};
	const baseFilter = [
		{
			property: 'buildingPropertyTypeId',
			value: propertyTypeIds.length ? propertyTypeIds : [-1],
		},
		{
			property: 'buildingClassId',
			value: buildingClassIds.length ? buildingClassIds : [-1],
		},
		{
			property: 'marketId',
			value: [marketTrendFilters.marketId],
		},
		{
			property: 'outlier',
			value: false,
		},
		{
			property: 'hidden',
			value: false,
		},
	];

	const portfolioFilter = {
		property: 'portfolioId',
		value: portfolioId,
	};
	const leaseExpirationFilter = filters.leaseExpirationsMonths
		? [
				{
					property: 'expirationDate',
					value: {
						to: dayjs()
							.add(filters.leaseExpirationsMonths, 'month')
							.format(DATE_FORMATS['MM/DD/YYYY']),
						allowFallback: true,
					},
				},
			]
		: [];

	return {
		portfolioQuery: {
			...baseBody,
			filter: [portfolioFilter, ...leaseExpirationFilter, ...baseFilter],
		},
		marketQuery: {
			...baseBody,
			filter: [...leaseExpirationFilter, ...baseFilter],
		},
	};
};

export const formatDate = (
	date: Date,
	shouldFormatDateAsYear: boolean,
	shortenYear?: boolean
) =>
	dayjs(date).format(
		shouldFormatDateAsYear ? (shortenYear ? "'YY" : 'YYYY') : "MMM 'YY"
	);

export const getTrendlineData = (arr: TrendData[], timeSpanDate: string) =>
	arr
		.filter(({ date }) => date >= timeSpanDate)
		.map(({ date, value }) => ({ x: new Date(date), y: value }));

export const getScatterData = (
	arr: InsightDataPoint[],
	metricFilter: TrendMetricFilter
) => {
	const metricToUse = metricFilter === 'ti' ? 'workValue' : metricFilter;
	const bubbles: Bubbles[] = [];
	arr.map((point) => {
		if (!point[metricToUse]) return;
		const newPoint = {
			x: new Date(point.executionDate),
			y: point[metricToUse] || 0,
			z: point.transactionSize,
		};
		bubbles.push(newPoint);
	});
	return bubbles;
};
