import { useEffect } from 'react';
import { PortfolioByIdRouteParams, routes, useNavigate } from 'router';
import { usePortfoliosSortedByMostRecent } from './usePortfoliosSortedByMostRecent';

type Props = {
	params: PortfolioByIdRouteParams;
};

/**
 * if portfolio can not be found via `portfolioId` path variable, then redirects to:
 * - most recent portfolio from the list of all portfolios
 * - to portfolios home route if there are no portfolios
 */
export const useRedirectPortfolio = ({ params }: Props) => {
	const navigate = useNavigate();

	const { sortedPortfolios, isFetching } = usePortfoliosSortedByMostRecent();

	useEffect(() => {
		if (isFetching) return;
		const mostRecentPortfolio = sortedPortfolios[0];

		if (!mostRecentPortfolio) {
			navigate(routes.portfolios.path);
			return;
		}

		let shouldRedirect = false;

		if (params.portfolioId == null) {
			shouldRedirect = true;
		} else {
			const portfolioExists = sortedPortfolios.find(
				(p) => p.id === Number(params.portfolioId)
			);
			shouldRedirect = !portfolioExists;
		}

		if (shouldRedirect) {
			navigate(
				routes.portfolioByIdView.toHref({
					portfolioId: mostRecentPortfolio.id,
					viewType: 'dashboard',
				})
			);
		}
	}, [sortedPortfolios, isFetching, params.portfolioId, navigate]);
};
