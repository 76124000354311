import React, { useMemo, useState } from 'react';
import { Portfolio, useInsightsQuery } from 'api';
import { FiltersState } from '../PortfolioFiltersProvider';
import { TrendFilters } from 'PortfolioAnalytics/portfolioAnalyticsTypes';
import { DownloadBranding } from 'PortfolioAnalytics/Singletons/DownloadBranding';
import DownloadButton from 'PortfolioAnalytics/Singletons/DownloadButton';
import { MarketTrendChartComponent as Chart } from './Chart';
import { MarketTrendFiltersComponent as Filters } from './Filters';
import { MarketTrendLegendComponent as Legend } from './Legend';
import { PortfolioHelpIcon } from 'PortfolioAnalytics/Singletons/PortfolioHelpIcon';
import {
	ChartContainer,
	MarketsHeading,
	MarketsHeadingWrapper,
	TabCardItem,
} from 'PortfolioAnalytics/styles/PortfolioUI';
import { Spinner } from '@compstak/ui-kit';
import { DataNotAvailableMessage } from 'Components/DataNotAvailableMessage';
import { Styled } from './styled';
import { Spacer } from 'PortfolioAnalytics/UI';
import {
	getTrendlineData,
	getDateForTimeSpan,
	formatMarketTrendFilters,
} from './util';
import { PORTFOLIO_CHART_COLORS } from '../constants';

export const Colors = {
	PORTFOLIO: PORTFOLIO_CHART_COLORS[0],
	MARKET: PORTFOLIO_CHART_COLORS[1],
} as const;

type MarketTrendComponentProps = {
	portfolio: Portfolio;
	filters: FiltersState;
};

export const MarketTrendComponent = ({
	portfolio,
	filters,
}: MarketTrendComponentProps) => {
	const mainRef = React.useRef<null | HTMLDivElement>(null);

	const marketDropdownData = useMemo(() => {
		return portfolio.markets.map(({ marketId, market }) => ({
			id: marketId,
			value: market,
		}));
	}, [portfolio]);

	const [marketTrendFilters, setMarketTrendFilters] = useState<TrendFilters>({
		marketId: marketDropdownData[0].id,
		metric: 'effectiveRent',
		timeSpan: 1,
		movAverage: 3,
	});

	const { portfolioQuery, marketQuery } = useMemo(
		() =>
			formatMarketTrendFilters({
				portfolioId: portfolio.id,
				marketTrendFilters: marketTrendFilters,
				filters,
				propertyTypeIds: portfolio.propertyTypesByMarkets
					.filter((p) => p.marketId === marketTrendFilters.marketId)
					.map((p) => p.buildingPropertyTypeId),
				buildingClassIds: portfolio.buildingClassesByMarkets
					.filter((p) => p.marketId === marketTrendFilters.marketId)
					.map((p) => p.buildingClassId),
			}),
		[portfolio, filters, marketTrendFilters]
	);

	const {
		data: portfolioTrendData,
		isLoading: isPortfolioTrendLoading,
		isError: isPortfolioTrendError,
	} = useInsightsQuery({ params: portfolioQuery, context: 'marketTrend' });
	const {
		data: marketTrendData,
		isLoading: isMarketTrendLoading,
		isError: isMarketTrendError,
	} = useInsightsQuery({ params: marketQuery, context: 'marketTrend' });

	const chartData = useMemo(() => {
		if (
			!portfolioTrendData?.trendLine?.length ||
			!marketTrendData?.trendLine?.length
		) {
			return {
				portfolio: [],
				market: [],
			};
		}

		const timeSpanDate = getDateForTimeSpan(marketTrendFilters.timeSpan);

		return {
			portfolio: getTrendlineData(portfolioTrendData.trendLine, timeSpanDate),
			market: getTrendlineData(marketTrendData.trendLine, timeSpanDate),
		};
	}, [portfolioTrendData, marketTrendData, marketTrendFilters.timeSpan]);

	const noData = !chartData.portfolio.length || !chartData.market.length;

	const marketName = useMemo(() => {
		const market = portfolio.markets.find(
			({ marketId }) => marketId === marketTrendFilters.marketId
		);

		return market ? `${market.market} ` : '';
	}, [portfolio.markets, marketTrendFilters.marketId]);

	const title = useMemo(() => {
		return `${marketName}market trend`;
	}, [marketName]);

	const downloadBannerId = title.replaceAll(' ', '');

	const isLoading = isPortfolioTrendLoading || isMarketTrendLoading;

	return (
		<TabCardItem ref={mainRef}>
			<Styled.Container>
				<MarketsHeadingWrapper marginBottom={10}>
					<MarketsHeading>
						<h4>{title}</h4>
						<PortfolioHelpIcon tooltip={MARKET_TREND_TOOLTIP} />
					</MarketsHeading>
					<DownloadButton
						downloadRef={mainRef}
						filename={`${title} - ${portfolio.name}`}
						disabled={noData}
						bannerId={downloadBannerId}
						data-qa-id="download-button-pa-market-trend"
					/>
				</MarketsHeadingWrapper>
				<Filters
					marketDropdownData={marketDropdownData}
					filters={marketTrendFilters}
					setMarketTrendFilters={setMarketTrendFilters}
				/>
				<Styled.Error show={isMarketTrendError || isPortfolioTrendError}>
					<div>
						<span>Error!</span>
						<div>An error occurred while loading the Market trends </div>
					</div>
				</Styled.Error>
				{isLoading ? (
					<ChartContainer>
						<Spinner size="xl" />
					</ChartContainer>
				) : (
					<>
						{noData ? (
							<ChartContainer>
								<DataNotAvailableMessage chartName={title} />
							</ChartContainer>
						) : (
							<>
								<Chart
									data={chartData}
									noData={noData}
									loading={isPortfolioTrendLoading || isMarketTrendLoading}
									metricFilter={marketTrendFilters.metric}
									marketName={marketName}
								/>
								<Legend marketName={marketName} />
							</>
						)}
					</>
				)}
			</Styled.Container>
			<DownloadBranding bannerId={downloadBannerId} />
		</TabCardItem>
	);
};

const MARKET_TREND_TOOLTIP = (
	<>
		<Spacer>
			The Market Trend chart lets you compare the average effective rent in your
			portfolio vs. the market over the past 3 years.
		</Spacer>
		<Spacer>
			Changing the filters at the top of the page will filter down which
			properties you see reflected here.
		</Spacer>
		<Spacer>Use the dropdown below to switch between markets.</Spacer>
		<div>
			Average Net Effective Rent is calculated by taking the $/SF/YR in each
			active lease and dividing by the number of leases.
		</div>
	</>
);
