import constate from 'constate';
import { useSelectionState } from 'utils/useSelectionState';
import { useListViewProperties } from './useListViewProperties';

type Props = {
	portfolioId: number;
};

export const [PropertiesSelectionProvider, usePropertiesSelectionContext] =
	constate(({ portfolioId }: Props) => {
		const { data: properties } = useListViewProperties({
			portfolioId,
		});

		return useSelectionState(properties);
	});
