import { Button, TooltipV2 } from '@compstak/ui-kit';
import styled from 'styled-components';
import IconMap from 'ui/svg_icons/map.svg';
import IconTable from 'ui/svg_icons/table.svg';

type View = 'list' | 'map';

type Props = {
	view: View;
	onViewClick: (view: View) => void;
};

export const ListMapToggler = ({ view, onViewClick }: Props) => {
	return (
		<Container>
			<TooltipV2 content="List View">
				<IconButton
					variant={view === 'list' ? 'secondary' : 'secondary2'}
					onClick={() => onViewClick('list')}
					v2
				>
					<IconTable />
				</IconButton>
			</TooltipV2>
			<TooltipV2 content="Map View">
				<IconButton
					variant={view === 'map' ? 'secondary' : 'secondary2'}
					onClick={() => onViewClick('map')}
					v2
				>
					<IconMap />
				</IconButton>
			</TooltipV2>
		</Container>
	);
};

const Container = styled.div`
	border: 1px solid ${({ theme }) => theme.colors.gray.gray300};
	display: flex;
	place-self: flex-start;
`;

const IconButton = styled(Button)`
	border: unset;
	border-radius: unset;

	svg {
		width: 24px;
		height: 24px;
		path {
			fill: ${({ theme, variant }) =>
				variant === 'secondary'
					? theme.colors.white.white
					: theme.colors.gray.gray500};
		}
	}
`;
