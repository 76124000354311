import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Link } from 'router';
import styled, { css } from 'styled-components';
import { APP_HEADER_HEIGHT } from '../constants';
import ArrowLeftIcon from '../ui/svg_icons/arrow_left.svg';
import IconTick from '../ui/svg_icons/tick.svg';
import { LIST_SORT_ROW_HEIGHT } from './constants';
import staticMapImage from './styles/map-button.png';

export const Spacer = styled.div`
	margin-bottom: 0.5rem;
`;

const portfolioDropdownMenuItemCss = css`
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 300;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	color: ${(p) => p.theme.colors.white.white};
	text-transform: uppercase;
	padding: 0.5rem 1rem;
	text-align: left;
  cursor: pointer;
	&:hover {
		background-color: ${(p) => p.theme.colors.neutral.n300};
	}
  &[data-highlighted] {
    background-color: ${(p) => p.theme.colors.neutral.n300};
  },
`;

export const PortfolioDropdownMenuItem = styled(DropdownMenu.Item)`
	${portfolioDropdownMenuItemCss}
`;

export const PortfolioDropdownMenuItemV2 = styled(DropdownMenu.Item)`
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 325;
	line-height: 0.875rem;
	letter-spacing: 0.3px;
	display: flex;
	height: 32px;
	padding: 10px 16px;
	align-items: center;
	align-self: stretch;
	justify-content: space-between;
	gap: 0.5rem;
	cursor: pointer;
	box-sizing: border-box;
	&:hover {
		background-color: ${(p) => p.theme.colors.gray.gray200};
	}
`;

export const PortfolioDropdownMenuRadioItem = styled(DropdownMenu.RadioItem)`
	${portfolioDropdownMenuItemCss};
	justify-content: space-between;
	&[data-state='checked'] {
		font-weight: 400;
	}
`;

export const PortfolioDropdownMenuContent = styled(DropdownMenu.Content)`
	min-width: 220px;
	border-radius: 0.25rem;
	background-color: ${(p) => p.theme.colors.gray.gray700};
	z-index: ${(p) => p.theme.zIndex.dropdown};
	box-shadow:
		0 4px 6px -1px rgb(0 0 0 / 0.1),
		0 2px 4px -2px rgb(0 0 0 / 0.1);
	* {
		box-sizing: border-box;
	}

	${PortfolioDropdownMenuItem}, ${PortfolioDropdownMenuRadioItem} {
		&:first-of-type {
			border-top-left-radius: 0.25rem;
			border-top-right-radius: 0.25rem;
		}
		&:last-of-type {
			border-bottom-left-radius: 0.25rem;
			border-bottom-right-radius: 0.25rem;
		}
	}
`;

export const PortfolioDropdownMenuContentV2 = styled(DropdownMenu.Content)`
	display: flex;
	width: 216px;
	height: 108px;
	padding: 4px;
	margin-left: 4px;
	flex-direction: column;
	align-items: flex-start;
	background-color: ${(p) => p.theme.colors.white.white};
	z-index: ${(p) => p.theme.zIndex.dropdown};
	border-radius: 3px;
	box-shadow:
		0px 3px 5px 0px rgba(9, 30, 66, 0.2),
		0px 0px 1px 0px rgba(9, 30, 66, 0.31);
`;

export const PortfolioDropdownMenuArrow = styled(DropdownMenu.Arrow)`
	fill: ${(p) => p.theme.colors.gray.gray700};
`;

export const StyledIconTick = styled(IconTick)`
	fill: ${(p) => p.theme.colors.white.white};
`;

export const MapViewLink = styled(Link)`
	background-image: url(${staticMapImage});
	height: 42px;
	min-width: 256px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.125rem;
	position: relative;
	&:before {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.35);
		border-radius: 0.125rem;
		color: ${(p) => p.theme.colors.white.white};
		content: 'MAP VIEW';
		font-size: 0.875rem;
		font-weight: 300;
		width: 100%;
		height: 100%;
	}
	&:hover:before {
		background-color: rgba(0, 0, 0, 0.5);
	}
`;

export const ListSortContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: ${LIST_SORT_ROW_HEIGHT}px;
	padding: 0 1.25rem;
`;

export const ListSortButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

export const ListSortButton = styled.button`
	all: unset;
	font-size: 11px;
	text-transform: uppercase;
	cursor: pointer;
`;

export const ListSortRowInfoText = styled.div`
	font-size: 11px;
	text-transform: uppercase;
`;

export const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const BackToDashboardLink = styled(Link)`
	all: unset;
	cursor: pointer;
	font-size: 0.75rem;
	display: inline-flex;
	align-items: center;
	gap: 0.5rem;
	height: 32px;
	padding: 0.5rem;
	color: ${(p) => p.theme.colors.blue.blue400};
	border-radius: 0.125rem;
	&:hover {
		background-color: rgba(45, 119, 230, 0.1);
	}
`;

export const BackToDashboardArrowLeft = styled(ArrowLeftIcon)`
	width: 9px;
	height: 15px;
	fill: ${(props) => props.theme.colors.blue.blue500};
`;

export const Toolbar = styled.div`
	padding: 1rem;
	padding-bottom: 0;
	background-color: ${(p) => p.theme.colors.white.white};
	width: 100%;
	overflow-y: auto;
`;

export const MapListLoadingContainer = styled.div`
	height: calc(100vh - ${APP_HEADER_HEIGHT}px);
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
`;
