export const ONE_HOUR_MS = 60 * 60 * 1000;
export const TWELVE_HOURS_MS = 12 * ONE_HOUR_MS;
export const ONE_DAY_MS = 24 * ONE_HOUR_MS;
export const SEARCH_MAX_LIMIT = 99999999;
export const ONE_SECOND_MS = 1000;

export enum QUERY_KEYS {
	ALL_MARKETS_POLYGONS = 'ALL_MARKETS_POLYGONS',
	ALL_COLUMN_PRESETS_QUERY_KEY = 'ALL_COLUMN_PRESETS_QUERY_KEY',
	APN_POLYGON = 'APN_POLYGON',
	ASSOCIATED_APNS = 'ASSOCIATED_APNS',
	CLEARBIT_COMPANY_SUGGESTIONS = 'CLEARBIT_COMPANY_SUGGESTIONS',
	COUNTIES = 'COUNTIES',
	COUNTY_POLYGON_BY_FIPS = 'COUNTY_POLYGON_BY_FIPS',
	FEATURE_FLAGS = 'FEATURE_FLAGS',
	MARKETS = 'MARKETS',
	MUFA_COMPSET = 'MUFA_COMPSET',
	MUFA_PROPERTY_BY_ID = 'MUFA_PROPERTY_BY_ID',
	MUFA_SUBMARKET_POLYGONS = 'MUFA_SUBMARKET_POLYGONS',
	PROPERTY_COMPSET = 'PROPERTY_COMPSET',
	PROPERTY_INFO = 'PROPERTY_INFO',
	SALE_COMP = 'SALE_COMP',
	SEARCH_LEASES = 'SEARCH_LEASES',
	SEARCH_SALES = 'SEARCH_SALES',
	SEARCH_PROPERTIES = 'SEARCH_PROPERTIES',
	SEARCH_MUFA_PROPERTIES = 'SEARCH_MUFA_PROPERTIES',
	SUBMARKETS = 'SUBMARKETS',
	MARKET_RENT_ANALYTICS_DASHBOARD = 'MARKET_RENT_ANALYTICS_DASHBOARD',
	MARKET_RENT_ANALYTICS_PROPERTIES = 'MARKET_RENT_ANALYTICS_PROPERTIES',
	NEW_COMPS = 'NEW_COMPS',
	TENANT_INDUSTRY_ANALYTICS = 'TENANT_INDUSTRY_ANALYTICS',
	TENANT_INDUSTRY_ANALYTICS_DASHBOARD = 'TENANT_INDUSTRY_ANALYTICS_DASHBOARD',
	TOP_TENANT_IDUSTRIES = 'TOP_TENANT_IDUSTRIES',
	TOP_BUYERS = 'TOP_BUYERS',
	TOP_SELLERS = 'TOP_SELLERS',
	TOP_LANDLORDS = 'TOP_LANDLORDS',
	TOP_PROPERTIES_LANDLORDS = 'TOP_PROPERTIES_LANDLORDS',
	TOP_TENANTS = 'TOP_TENANTS',
	TREND_LINE_DASHBOARD = 'TREND_LINE_DASHBOARD',
	REFERENCE_DATA = 'REFERENCE_DATA',
	SPACE_TYPE_ANALYTICS = 'SPACE_TYPE_ANALYTICS',
	LEASE_EXPIRATION_BY_PROPERTY_TYPE = 'LEASE_EXPIRATION_BY_PROPERTY_TYPE',
	LEASE_EXPIRATION_BY_SUBMARKET = 'LEASE_EXPIRATION_BY_SUBMARKET',
	OPPORTUNITY_ZONES = 'OPPORTUNITY_ZONES',
	PROPERTY_LEASES = 'PROPERTY_LEASES',
	PROPERTY_SALES = 'PROPERTY_SALES',
	SIMILAR_SALES = 'SIMILAR_SALES',
	SPACE_TYPES_AGGREGATIONS = 'SPACE_TYPES_AGGREGATIONS',
	SUBMARKET_POLYGONS = 'SUBMARKET_POLYGONS',
	PROMOTION = 'PROMOTION',
	PROMOTIONS = 'PROMOTIONS',
	USER_SUBMISSIONS = 'USER_SUBMISSIONS',
	USER_SUBMISSION_DETAILS = 'USER_SUBMISSION_DETAILS',
	REWARD_HISTORY = 'REWARD_HISTORY',
	REWARD_REDEMPTIONS = 'REWARD_REDEMPTIONS',
	REWARD_AMOUNT = 'REWARD_AMOUNT',
	VENDORS = 'VENDORS',
	PORTFOLIO_PROPERTIES = 'PORTFOLIO_PROPERTIES',
	PORTFOLIO_PROPERTIES_INFINITE = 'PORTFOLIO_PROPERTIES_INFINITE',
	PORTFOLIO_BY_ID = 'PORTFOLIO_BY_ID',
	PORTFOLIO_LEASES = 'PORTFOLIO_LEASES',
	PORTFOLIO_PROPERTIES_SUMMARY = 'PORTFOLIO_PROPERTIES_SUMMARY',
	PORTFOLIO_MARKET_TREND = 'PORTFOLIO_MARKET_TREND',
	PORTFOLIO_MARKETS_SUMMARY = 'PORTFOLIO_MARKETS_SUMMARY',
	LEASE_EXPIRATION = 'LEASE_EXPIRATION',
	USER = 'USER',
	CHECK_LEASES_EXPORT_STATUS = 'CHECK_LEASES_EXPORT_STATUS',
	CHECK_SALES_EXPORT_STATUS = 'CHECK_SALES_EXPORT_STATUS',
	CHECK_PROPERTIES_EXPORT_STATUS = 'CHECK_PROPERTIES_EXPORT_STATUS',
	PROPERTY_LOAN = 'PROPERTY_LOAN',
	MUFA_PROPERTY_LOAN = 'MUFA_PROPERTY_LOAN',
	MARKETS_FEATURES = 'MARKETS_FEATURES',
	SUGGESTIONS_V1 = 'SUGGESTIONS_V1',
	SUGGESTIONS_V2 = 'SUGGESTIONS_V2',
	SURVEY_QUESTIONS = 'SURVEY_QUESTIONS',
	SURVEY_BANNER_STATE = 'SURVEY_BANNER_STATE',
	SEARCH_LEASES_INFINITE = 'SEARCH_LEASES_INFINITE',
	SEARCH_SALES_INFINITE = 'SEARCH_SALES_INFINITE',
	SEARCH_PROPERTIES_INFINITE = 'SEARCH_PROPERTIES_INFINITE',
	SEARCH_MUFA_PROPERTIES_INFINITE = 'SEARCH_MUFA_PROPERTIES_INFINITE',
	LARGEST_LANDLORDS = 'LARGEST_LANDLORDS',
	LARGEST_TENANTS = 'LARGEST_TENANTS',
	MARKET_SUMMARY = 'MARKET_SUMMARY',
	MARKET_SUMMARY_FEEDBACK = 'MARKET_SUMMARY_FEEDBACK',
	ALL_PORTFOLIOS = 'ALL_PORTFOLIOS',
	PREMIER_LIST_CLEAN_SECTORS = 'PREMIER_LIST_CLEAN_SECTORS',
	PROPERTY_BY_ID = 'PROPERTY_BY_ID',
	PROPERTY_SUGGESTIONS = 'PROPERTY_SUGGESTIONS',
	PROPERTY_AVERAGES = 'PROPERTY_AVERAGES',
	MUFA_PROPERTY_AVERAGES = 'MUFA_PROPERTY_AVERAGES',
	SUBMARKET_RECENT_LEASES = 'SUBMARKET_RECENT_LEASES',
	PROPERTY_COMPSET_TREND = 'PROPERTY_COMPSET_TREND',
	PROPERTY_SUBMARKET_DISTRIBUTION = 'PROPERTY_SUBMARKET_DISTRIBUTION',
	PROPERTY_LEASES_SUMMARY = 'PROPERTY_LEASES_SUMMARY',
	MUFA_PROPERTY_MARKET_TREND = 'MUFA_PROPERTY_MARKET_TREND',
	MUFA_PROPERTY_LEASE_TRADE_OUT = 'MUFA_PROPERTY_LEASE_TRADE_OUT',
	MUFA_PROPERTY_OCCUPANCY = 'MUFA_PROPERTY_OCCUPANCY',
	MUFA_PROPERTY_SUPPLY = 'MUFA_PROPERTY_SUPPLY',
	MUFA_PROPERTY_NOI = 'MUFA_PROPERTY_NOI',
	MUFA_PROPERTY_EXPENSES = 'MUFA_PROPERTY_EXPENSES',
	LEASE_AVERAGES = 'LEASE_AVERAGES',
	SALE_AVERAGES = 'SALE_AVERAGES',
	MUFA_UNIT_MIX_SUMMARY = 'MUFA_UNIT_MIX_SUMMARY',
	MUFA_FLOOR_PLANS = 'MUFA_FLOOR_PLANS',
	MUFA_KEY_METRICS = 'MUFA_KEY_METRICS',
	TRANSIT_SCREEN = 'TRANSIT_SCREEN',
	USER_W9 = 'USER_W9',
	INVITES = 'INVITES',
	TEAM_INFO = 'TEAM_INFO',
	TEAM_INFO_DASHBOARD = 'TEAM_INFO_DASHBOARD',
	LEASE_VERSIONS_WITH_PROMOTIONS = 'LEASE_VERSIONS_WITH_PROMOTIONS',
	SALE_VERSIONS_WITH_PROMOTIONS = 'SALE_VERSIONS_WITH_PROMOTIONS',
	PROPERTY_MARKET_STATS = 'PROPERTY_MARKET_STATS',
	PROPERTY_SUBMARKET_STATS = 'PROPERTY_SUBMARKET_STATS',
	PROPERTY_COMPSET_STATS = 'PROPERTY_COMPSET_STATS',
	PROPERTY_LEASES_STATS = 'PROPERTY_LEASES_STATS',
	UNDERLYING_LEASES = 'UNDERLYING_LEASES',
	PROPERTY_BY_MARKET_AND_ADDRESS = 'PROPERTY_BY_MARKET_AND_ADDRESS',
}

export const SEARCH_INFINITE_PAGE_SIZE = 50;

export enum MUTATION_KEYS {
	UNLOCK_LEASES = 'UNLOCK_LEASES',
	UNLOCK_SALES = 'UNLOCK_SALES',
	DELETE_MARKET_SUMMARY_FEEDBACK = 'DELETE_MARKET_SUMMARY_FEEDBACK',
	UPDATE_MARKET_SUMMARY_FEEDBACK = 'UPDATE_MARKET_SUMMARY_FEEDBACK',
	REFER_COLLEGUES_V2 = 'REFER_COLLEGUES_V2',
}
