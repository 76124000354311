import { Spinner } from '@compstak/ui-kit';
import { ExportCompsButton } from 'Components/Export';
import { BlueLink } from 'Components/UI';
import { UnlockButtonTable } from 'Components/Unlock';
import { useLeasesInCompset } from 'api';
import { useIsExchange } from 'hooks';
import { ReactNode, useEffect, useMemo } from 'react';
import { routes } from 'router';
import styled from 'styled-components';
import IconAsterisk from 'ui/svg_icons/asterisk.svg';
import { NoDataTab } from '../../../Components/NoDataMessaging/NoDataMessaging';
import { useTabContext } from '../Tabs';
import {
	TabContentHeader,
	TabContentHeaderRightContainer,
	TabContentTitle,
	TableActionsContainer,
} from '../UI';
import { UploadCompButton } from '../UploadCompButton';
import { PropertyPageTab } from '../types';
import { useLeasesInCompsetTableData } from './LeasesInCompsetTableDataProvider';
import { LeasesInCompsetTable } from './LeasesInCompsetTable';
import { MIN_TAB_HEIGHT } from '../constants';

export const LeasesInCompset = () => {
	const { setTab } = useTabContext();
	const params = routes.propertyById.useParams();
	const isExchange = useIsExchange();

	const { data: leases, isLoading } = useLeasesInCompset({
		propertyId: params.propertyId,
	});

	const {
		selectionState,
		setCheckedStatus,
		filteredLeases,
		leasesActive,
		compsetLeasesUrl,
	} = useLeasesInCompsetTableData();

	useEffect(() => {
		if (!isLoading && leasesActive.length > 0)
			setCheckedStatus('Only Active Leases');
	}, [setCheckedStatus, leasesActive]);

	const { selectedRows } = selectionState;

	const { compForExport, compsForUnlock } = useMemo(() => {
		return {
			compForExport: selectedRows.filter((c) => c.own),
			compsForUnlock: selectedRows.filter((c) => !c.own),
		};
	}, [selectedRows]);

	if (isLoading)
		return (
			<SpinnerContainer>
				<Spinner isCentered />
			</SpinnerContainer>
		);

	const hasLeases = leases && leases.comps.length > 0;

	if (!hasLeases) {
		let emptyMessage: ReactNode =
			'CompStak doesn’t have any Leases for this Competitive Set at the moment.';

		if (isExchange) {
			emptyMessage = (
				<>
					<div>
						CompStak doesn't have Leases for this competitive set at the moment.
					</div>
					<div>
						Be the first to report a lease for this property and earn an extra
						FREE comp!
					</div>
				</>
			);
		}

		return (
			<NoDataTab
				minHeight={`${MIN_TAB_HEIGHT}px`}
				message={emptyMessage}
				action={
					isExchange && (
						<UploadCompButton section="lease">
							Upload lease comp
						</UploadCompButton>
					)
				}
			/>
		);
	}

	return (
		<>
			<LeasesInCompsetHeader>
				<TabContentTitle>
					Leases in Competitive Set & Subject Property
				</TabContentTitle>
				<TabContentHeaderRightContainer>
					<BlueLink to={compsetLeasesUrl} data-qa-id="see-all-leases-link">
						See all leases in this set
					</BlueLink>
				</TabContentHeaderRightContainer>
			</LeasesInCompsetHeader>
			<HelpTextContainer>
				<IconAsterisk width={8} /> Subject Property Leases,{' '}
				<ClickHereButton
					onClick={() => {
						setTab(PropertyPageTab.LEASES);
					}}
				>
					click here
				</ClickHereButton>{' '}
				to see Subject Property Leases only.
			</HelpTextContainer>
			<LeasesInCompsetTable propertyId={params.propertyId} />
			{filteredLeases.length > 0 && (
				<TableActionsContainer>
					<ExportCompsButton
						compType="lease"
						comps={compForExport}
						buttonType="table"
					/>
					{isExchange && (
						<UnlockButtonTable type="lease" comps={compsForUnlock} />
					)}
				</TableActionsContainer>
			)}
		</>
	);
};

const SpinnerContainer = styled.div`
	padding: 5rem 0;
`;

const HelpTextContainer = styled.div`
	font-size: 0.75rem;
	font-weight: 300;
	line-height: 1;
	display: flex;
	align-items: center;
	gap: 0.25rem;
	padding-top: 1rem;
	padding-bottom: 0.5rem;
`;

const ClickHereButton = styled.button`
	background-color: transparent;
	border: 0;
	padding: 0;
	color: ${(p) => p.theme.colors.blue.blue400};
`;

const LeasesInCompsetHeader = styled(TabContentHeader)`
	padding-bottom: 0px;
`;
