import { PortfolioFilterQuery } from 'api';
import { encodeQuery } from 'util/encodeQuery';

export const getQueryStringFromPortfolioFilters = (
	filters: PortfolioFilterQuery
) => {
	const { buildingSize, ...otherFilters } = filters;
	const buildingSizeMin = buildingSize?.min;
	const buildingSizeMax = buildingSize?.max;
	const query = encodeQuery({
		buildingSizeMin,
		buildingSizeMax,
		...otherFilters,
	});
	return query;
};
