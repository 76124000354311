import {
	SimpleTableColumn,
	MQB,
	Flex,
	TooltipV2,
	defaultTheme,
	Spinner,
} from '@compstak/ui-kit';
import { useMediaQuery } from 'react-responsive';
import { AnyComp, LeaseComp } from 'types';
import { useRenderCompField } from 'hooks/useFormatCompField';
import { getIsMonthlyMarket } from 'utils';
import IconQuestionMark from 'ui/svg_icons/question_mark_outline.svg';
import { COMP_FIELD_TOOLTIPS } from 'constants/tooltips';
import { Checkbox as CheckboxOriginal, StopPropagation } from 'Components';
import { CheckedState } from '@radix-ui/react-checkbox';
import { usePortfolioLeases } from './PortfolioLeasesProvider';
import { ExportCompsButton } from 'Components/Export';
import styled from 'styled-components';
import { ROW_HEIGHT } from 'PortfolioAnalytics/constants';

export const usePortfolioLeasesColumns = () => {
	const { isFetchingAll, selectionState } = usePortfolioLeases();

	const isTablet = useMediaQuery({ maxWidth: MQB.T_834 });

	const renderCompField = useRenderCompField();

	const renderPortfolioLeaseCell = ({
		row,
		name,
	}: {
		row: LeaseComp;
		name: keyof LeaseComp;
	}) => {
		return renderCompField({
			name,
			row: row as AnyComp,
			isMonthly: getIsMonthlyMarket(row.marketId),
		});
	};

	const {
		areAllSelected,
		areSomeSelected,
		getIsRowSelected,
		toggleAllSelected,
		toggleRow,
	} = selectionState;

	const selectAllCheckboxState: CheckedState = areAllSelected
		? true
		: areSomeSelected
			? 'indeterminate'
			: false;

	const columns: SimpleTableColumn<LeaseComp>[] = [
		{
			id: 'selection',
			header: ({ column }) => {
				if (isFetchingAll) {
					return <Spinner size="s" isCentered />;
				}

				return (
					<Checkbox
						width={column.width}
						height={ROW_HEIGHT}
						id="selectAll"
						name="selectAll"
						data-qa-id="select-all-checkbox"
						checked={selectAllCheckboxState}
						onCheckedChange={toggleAllSelected}
						bgColorUnchecked={defaultTheme.colors.white.white}
					/>
				);
			},
			body: ({ row, column }) => {
				return (
					<StopPropagation handler="onClick">
						<Checkbox
							width={column.width}
							height={ROW_HEIGHT}
							name={`selectRow-${row.id}`}
							data-qa-id={`select-row-checkbox-${row.id}`}
							checked={getIsRowSelected(row.id)}
							onCheckedChange={() => toggleRow(row.id)}
							onClick={(e) => e.stopPropagation()}
						/>
					</StopPropagation>
				);
			},
			width: 50,
			hAlign: 'center',
		},
		{
			id: 'actions',
			header: () => '',
			body: ({ row }) => (
				<StopPropagation handler="onClick">
					<ExportCompsButton compType="lease" comps={[row]} buttonType="row" />
				</StopPropagation>
			),
			width: 75,
		},
		{
			id: 'executionQuarter',
			header: 'Trans. Qtr.',
			width: 80,
			body: ({ row }) =>
				renderPortfolioLeaseCell({ row, name: 'executionQuarter' }),
			flex: 1,
		},
		{
			id: 'tenantName',
			header: 'Tenant',
			width: isTablet ? 100 : 180,
			body: ({ row }) => renderPortfolioLeaseCell({ row, name: 'tenantName' }),
			tooltip: ({ row }) =>
				renderPortfolioLeaseCell({ row, name: 'tenantName' }),
			flex: 1,
		},
		{
			id: 'spaceType',
			header: 'Space Type',
			width: 80,
			body: ({ row }) => renderPortfolioLeaseCell({ row, name: 'spaceType' }),
			flex: 1,
		},
		{
			id: 'address',
			header: 'Address',
			width: isTablet ? 100 : 160,
			body: ({ row }) =>
				renderPortfolioLeaseCell({ row, name: 'buildingAddress' }),
			tooltip: ({ row }) =>
				renderPortfolioLeaseCell({ row, name: 'buildingAddress' }),
			flex: 1,
		},
		{
			id: 'city',
			header: 'City',
			width: isTablet ? 100 : 140,
			body: ({ row }) => renderPortfolioLeaseCell({ row, name: 'city' }),
			flex: 1,
		},
		{
			id: 'market',
			header: 'Market',
			width: isTablet ? 80 : 120,
			body: ({ row }) => renderPortfolioLeaseCell({ row, name: 'market' }),
			flex: 1,
		},
		{
			id: 'submarket',
			header: 'Submarket',
			width: isTablet ? 90 : 140,
			body: ({ row }) => renderPortfolioLeaseCell({ row, name: 'submarket' }),
			flex: 1,
		},
		{
			id: 'transactionSize',
			header: 'Transaction SqFt',
			width: 110,
			body: ({ row }) =>
				renderPortfolioLeaseCell({ row, name: 'transactionSize' }),
			flex: 1,
			hAlign: 'right',
		},
		{
			id: 'startingRent',
			header: () => (
				<Flex gap="0.25rem">
					<div>Starting Rent</div>
					<TooltipV2 content={COMP_FIELD_TOOLTIPS.startingRent}>
						<span>
							<IconQuestionMark width="1rem" />
						</span>
					</TooltipV2>
				</Flex>
			),
			width: 110,
			body: ({ row }) =>
				renderPortfolioLeaseCell({ row, name: 'startingRent' }),
			flex: 1,
			hAlign: 'right',
		},
		{
			id: 'freeMonths',
			header: 'Free Rent (mo.)',
			width: 110,
			body: ({ row }) => renderPortfolioLeaseCell({ row, name: 'freeMonths' }),
			hAlign: 'right',
		},
		{
			id: 'workValue',
			header: 'TI Value / Work Value',
			width: 140,
			body: ({ row }) => renderPortfolioLeaseCell({ row, name: 'workValue' }),
			hAlign: 'right',
		},
		{
			id: 'leaseTerm',
			header: 'Lease Term',
			width: 90,
			body: ({ row }) => renderPortfolioLeaseCell({ row, name: 'leaseTerm' }),
			hAlign: 'right',
		},
	];

	return columns;
};

const Checkbox = styled(CheckboxOriginal)<{ width: number; height: number }>`
	width: ${({ width }) => `calc(${width}px - 12px)`};
	height: ${({ height }) => height}px;
	justify-content: center;
`;
