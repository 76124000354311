import { formatNumber } from 'format';
import { BlueBoxLabel } from '../UI';
import { CompsetLabel } from './CompsetLabel';
import { MarketLabel } from './MarketLabel';
import { MarketStatsChart } from './MarketStatsChart';
import { SubmarketLabel } from './SubmarketLabel';
import { useMarketStatsChartData } from './useMarketStatsChartData';

export const FreeMonthsMarketStats = () => {
	const { data, isFetching } = useMarketStatsChartData({
		metric: 'freeMonths',
	});

	const format = (value: number) => {
		return formatNumber(value, { shorten: false, maxDecimals: 1 });
	};

	return (
		<div>
			<BlueBoxLabel>Free Rent (Months)</BlueBoxLabel>
			<MarketStatsChart
				items={[
					{ label: 'Subj. Property', middle: data?.leases.middle },
					{
						label: <CompsetLabel />,
						...data?.compset,
					},
					{ label: <SubmarketLabel />, ...data?.submarket },
					{ label: <MarketLabel />, ...data?.market },
				]}
				formatValue={format}
				formatXTick={format}
				isLoading={isFetching}
			/>
		</div>
	);
};
