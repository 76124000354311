import { routes } from 'router';
import { useCompsetData, usePropertyLeasesSummaryQuery } from 'api';
import { useCompstakEstimatesData } from './useCompstakEstimatesData';

export const usePropertyPageData = () => {
	const params = routes.propertyById.useParams();

	const compstakEstimates = useCompstakEstimatesData();

	const { data: summary, isLoading: isSummaryLoading } =
		usePropertyLeasesSummaryQuery(params.propertyId);

	const { data: compset, isLoading: isCompsetLoading } = useCompsetData({
		propertyId: params.propertyId,
	});

	return {
		compstakEstimates,
		summary,
		compset,
		isLoading: isSummaryLoading || isCompsetLoading,
	};
};
