import * as Accordion from '@radix-ui/react-accordion';
import * as Popover from '@radix-ui/react-popover';
import {
	PortfolioMarket,
	PortfolioSubmarket,
	usePortfolioByIdQuery,
} from 'api';
import { useMarkets } from 'hooks/useMarkets';
import { Checkbox } from 'Components';
import { uniq, sortBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import IconSearch from '../../../../ui/svg_icons/search.svg';
import { usePortfolioFilters } from '../../../PortfolioFiltersProvider';
import {
	FilterAccordionTrigger,
	FilterButtonIconArrow,
	FilterButtonIconContainer,
	FilterButtonLabel,
	FilterButtonLabelContainer,
	FilterButtonValue,
	FilterCheckboxItem,
	FilterPopoverButton,
	FilterPopoverContent,
} from '../UI';
import { defaultTheme } from '@compstak/ui-kit';

type Props = {
	portfolioId: number;
};

// This filter displays markets & submarkets
// but only submarket ids are in the filters state
// and market ids are computed from submarket ids
export const SubmarketFilter = ({ portfolioId }: Props) => {
	const [search, setSearch] = useState('');

	const { data: portfolio, isFetching: isFetchingPortfolio } =
		usePortfolioByIdQuery({ id: portfolioId });

	const allMarkets = useMarkets();

	const { filters, setFilters } = usePortfolioFilters();

	const isSearchActive = search !== '';

	const filteredMarkets = useMemo(() => {
		if (!portfolio) return [];

		if (!isSearchActive) {
			return portfolio.markets;
		}

		const filtered: PortfolioMarket[] = [];

		portfolio.markets.forEach((m) => {
			const filteredSubmarkets: PortfolioSubmarket[] = [];
			m.submarkets.forEach((s) => {
				if (s.submarket.toLowerCase().includes(search.toLowerCase())) {
					filteredSubmarkets.push(s);
				}
			});

			if (
				filteredSubmarkets.length > 0 ||
				m.market.toLowerCase().includes(search.toLowerCase())
			) {
				filtered.push({
					...m,
					submarkets: filteredSubmarkets,
				});
			}
		});
		return filtered;
	}, [portfolio, search, isSearchActive]);

	const getIsMarketSelectedPartially = useCallback(
		(marketId: number) => {
			return filters.marketIds.includes(marketId);
		},
		[filters.marketIds]
	);

	const getMarketFromPortfolio = useCallback(
		(marketId: number) => {
			return portfolio?.markets.find((m) => m.marketId === marketId);
		},
		[portfolio]
	);

	const getIsMarketSelected = useCallback(
		(marketId: number) => {
			const market = getMarketFromPortfolio(marketId);
			if (!market) return false;

			return (
				getIsMarketSelectedPartially(marketId) &&
				market.submarkets.every((s) =>
					filters.submarketIds.includes(s.submarketId)
				)
			);
		},
		[getMarketFromPortfolio, getIsMarketSelectedPartially, filters.submarketIds]
	);

	const getIsSubmarketSelected = (submarketId: number) => {
		return filters.submarketIds.includes(submarketId);
	};

	const areAllSelected = useMemo(() => {
		if (!portfolio) return false;
		return portfolio.markets.every((m) => getIsMarketSelected(m.marketId));
	}, [portfolio, getIsMarketSelected]);

	const onSelectAll = () => {
		if (!portfolio) return;

		setFilters((state) => ({
			...state,
			submarketIds: areAllSelected
				? []
				: portfolio.markets.reduce<number[]>((ids, market) => {
						ids.push(...market.submarkets.map((s) => s.submarketId));
						return ids;
					}, []),
		}));
	};

	const onSelectMarket = (marketId: number) => {
		const isMarketSelected = getIsMarketSelected(marketId);
		const market = getMarketFromPortfolio(marketId);
		if (!market) return;

		setFilters((state) => ({
			...state,
			submarketIds: isMarketSelected
				? state.submarketIds.filter(
						(id) => !market.submarkets.find((s) => s.submarketId === id)
					)
				: uniq([
						...state.submarketIds,
						...market.submarkets.map((submarket) => submarket.submarketId),
					]),
		}));
	};

	const onSelectSubmarket = (submarketId: number) => {
		const isSubmarketSelected = getIsSubmarketSelected(submarketId);

		setFilters((state) => ({
			...state,
			submarketIds: isSubmarketSelected
				? state.submarketIds.filter((id) => id !== submarketId)
				: [...state.submarketIds, submarketId],
		}));
	};

	const displayValue =
		filters.marketIds.length === 0
			? `All Markets`
			: `${filters.marketIds.length} Market${
					filters.marketIds.length > 1 ? 's' : ''
				}, ${filters.submarketIds.length} Submarket${
					filters.submarketIds.length > 1 ? 's' : ''
				}`;

	return (
		<Popover.Root>
			<FilterPopoverButton
				disabled={isFetchingPortfolio}
				style={{ width: 200 }}
			>
				<FilterButtonLabelContainer>
					<FilterButtonLabel>Markets & Submarkets</FilterButtonLabel>
					<FilterButtonValue>{displayValue}</FilterButtonValue>
				</FilterButtonLabelContainer>
				<FilterButtonIconContainer>
					<FilterButtonIconArrow width={10} height={6} />
				</FilterButtonIconContainer>
			</FilterPopoverButton>
			<Popover.Portal>
				<StyledFilterPopoverContent>
					<TopContainer>
						<SearchContainer>
							<IconSearch width={16} height={16} />
							<SearchInput
								type="text"
								value={search}
								onChange={(e) => {
									setSearch(e.target.value);
								}}
								placeholder="Search by market or submarket"
								data-qa-id="search"
								// eslint-disable-next-line jsx-a11y/no-autofocus
								autoFocus
							/>
						</SearchContainer>
					</TopContainer>
					<FilterCheckboxItem checked={areAllSelected} onClick={onSelectAll}>
						<Checkbox checked={areAllSelected} /> Select All
					</FilterCheckboxItem>
					<OptionsContainer>
						<Accordion.Root type="multiple">
							{filteredMarkets.map((market) => {
								const isMarketSelectedPartially = getIsMarketSelectedPartially(
									market.marketId
								);
								const isMarketSelected = getIsMarketSelected(market.marketId);
								const sortedSubmarkets = sortBy(
									market.submarkets,
									(s) => s.submarket
								);

								return (
									<Accordion.AccordionItem
										key={market.marketId}
										value={String(market.marketId)}
									>
										<FilterAccordionTrigger checked={isMarketSelected}>
											<Checkbox
												checked={
													isMarketSelected
														? true
														: isMarketSelectedPartially
															? 'indeterminate'
															: false
												}
												onClick={(e) => {
													e.stopPropagation();
													onSelectMarket(market.marketId);
												}}
											/>
											<div>{allMarkets[market.marketId].displayName}</div>
											{sortedSubmarkets.length > 0 && (
												<StyledFilterButtonIconArrow width={10} height={6} />
											)}
										</FilterAccordionTrigger>
										<AccordionContent>
											{sortedSubmarkets.map((submarket) => {
												const isSubmarketSelected = getIsSubmarketSelected(
													submarket.submarketId
												);
												return (
													<FilterCheckboxItem
														key={submarket.submarketId}
														checked={isSubmarketSelected}
														onClick={() => {
															onSelectSubmarket(submarket.submarketId);
														}}
													>
														<Checkbox
															checked={isSubmarketSelected}
															bgColorUnchecked={defaultTheme.colors.white.white}
														/>{' '}
														{submarket.submarket}
													</FilterCheckboxItem>
												);
											})}
										</AccordionContent>
									</Accordion.AccordionItem>
								);
							})}
						</Accordion.Root>
					</OptionsContainer>
				</StyledFilterPopoverContent>
			</Popover.Portal>
		</Popover.Root>
	);
};

const StyledFilterPopoverContent = styled(FilterPopoverContent)`
	width: 400px;
`;

const TopContainer = styled.div`
	padding: 0.5rem;
`;

const SearchContainer = styled.div`
	background-color: ${(p) => p.theme.colors.neutral.n20};
	border-bottom: 1px solid ${(p) => p.theme.colors.blue.blue500};
	border-radius: 0.125rem;
	padding: 0.125rem;
	display: flex;
	align-items: center;
	gap: 0.25rem;
	padding: 0.5rem;
`;

const SearchInput = styled.input`
	&&& {
		background-color: transparent;
		font-size: 0.875rem;
		padding: 0 0.5rem;
		height: auto;
		margin: 0;
		border: 0;
	}
`;

const OptionsContainer = styled.div`
	max-height: 300px;
	overflow: auto;
`;

const AccordionContent = styled(Accordion.Content)`
	padding-left: 1rem;
`;

const StyledFilterButtonIconArrow = styled(FilterButtonIconArrow)`
	margin-left: auto;
	[aria-expanded='true'] & {
		transform: rotate(180deg);
	}
`;
