import { useMemo } from 'react';
import { groupBy } from 'lodash';
import {
	Portfolio,
	PortfolioLeaseExpirationsBySpaceTypesByYears,
	SpaceTypeName,
} from 'api';
import {
	Legend,
	LegendColor,
	LegendItem,
	LegendLabel,
	ChartContainer,
	ChartAndLegendContainer,
} from 'PortfolioAnalytics/styles/PortfolioUI';
import { CHART_HEIGHT } from './chartsConstants';
import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryContainer,
	VictoryLabel,
	VictoryStack,
} from 'victory';
import { ChartBox, ChartType } from './ChartSelect';
import { PORTFOLIO_CHART_COLORS } from '../constants';
import { DataNotAvailableMessage } from 'Components/DataNotAvailableMessage';
import { useNavigate } from 'react-router';
import { routes } from 'router';

type Props = {
	portfolio: Portfolio;
};

const LEASE_EXPIRATION__BY_SPACE_TYPE_CHART_NAME = 'Upcoming Lease Expirations';
const NUMBER_OF_YEARS = 5;

export const LeaseExpirationsBySpaceTypeChart = ({ portfolio }: Props) => {
	const { leaseExpirationsBySpaceTypesByYears } = portfolio;

	const chartData = useMemo(() => {
		if (!leaseExpirationsBySpaceTypesByYears) return [];
		const expirationsByValidYears =
			leaseExpirationsBySpaceTypesByYears.filter(filterValidYears);
		const expirationsGroupedBySpaceType = groupBy(
			expirationsByValidYears,
			(e) => e.spaceType
		);
		return Object.entries(expirationsGroupedBySpaceType) as [
			SpaceTypeName,
			PortfolioLeaseExpirationsBySpaceTypesByYears[],
		][];
	}, [leaseExpirationsBySpaceTypesByYears]);

	const navigate = useNavigate();

	return (
		<ChartBox chartName={LEASE_EXPIRATION__BY_SPACE_TYPE_CHART_NAME}>
			{(selectedChart) => {
				if (!chartData.length) {
					return (
						<ChartContainer>
							<DataNotAvailableMessage
								chartName={`${LEASE_EXPIRATION__BY_SPACE_TYPE_CHART_NAME} by ${selectedChart.value}`}
							/>
						</ChartContainer>
					);
				}

				const chartKey = CHART_TYPE_TO_KEY[selectedChart.type];

				return (
					<ChartAndLegendContainer>
						<VictoryChart
							width={480}
							height={CHART_HEIGHT}
							padding={{ left: 90, top: 50, right: 50, bottom: 50 }}
							containerComponent={
								<VictoryContainer
									responsive={false}
									style={{ margin: '0 auto' }}
								/>
							}
						>
							<VictoryAxis
								dependentAxis
								offsetX={50}
								tickLabelComponent={
									<VictoryLabel renderInPortal style={{ fontSize: 11 }} />
								}
								style={{
									axis: { stroke: '#F0F0F0', strokeWidth: 2 },
									axisLabel: {
										fontSize: 20,
										color: '#A3A3A3',
										textOverflow: 'ellipsis',
										whiteSpace: 'no-wrap',
										overflow: 'hidden',
									},
									grid: { stroke: '#F0F0F0' },
									ticks: { stroke: '#F0F0F0' },
									tickLabels: {
										padding: 0,
										color: '#A3A3A3',
										fontSize: 10,
										lineHeight: 10,
									},
								}}
							/>
							<VictoryAxis
								padding={{ left: 90, top: 50, right: 10, bottom: 50 }}
								fixLabelOverlap
								tickFormat={(yAxisValue: number) => `${yAxisValue}`}
								tickLabelComponent={
									<VictoryLabel renderInPortal style={{ fontSize: 11 }} />
								}
								style={{
									axis: { stroke: '#F0F0F0', strokeWidth: 2 },
									axisLabel: { fontSize: 20, padding: 0, color: '#A3A3A3' },
									grid: { stroke: '#F0F0F0', border: '1px solid black' },
									ticks: { stroke: '#F0F0F0' },
									tickLabels: {
										padding: 5,
										color: '#A3A3A3',
										fontSize: 10,
										lineHeight: 10,
									},
								}}
							/>
							<VictoryStack
								colorScale={PORTFOLIO_CHART_COLORS}
								style={{
									parent: {
										height: 300,
									},
									data: {
										stroke: '#fff',
										strokeWidth: 2,
									},
									labels: {
										fontFamily: 'Roboto Mono',
										fontSize: 11,
									},
								}}
							>
								{chartData.map(([spaceTypeName, spaceTypeGroup]) => {
									const barData = spaceTypeGroup.map(
										(leaseExpirationByYear) => {
											return {
												x: leaseExpirationByYear.expirationYear,
												y: leaseExpirationByYear[chartKey],
												spaceType: spaceTypeName,
											};
										}
									);
									return (
										<VictoryBar
											key={spaceTypeName}
											barRatio={0.6}
											data={barData}
											style={{
												data: {
													cursor: 'pointer',
												},
											}}
											events={[
												{
													target: 'data',
													eventHandlers: {
														onClick: (e, clickedProps) => {
															const expirationYear = clickedProps.datum
																.x as number;
															navigate(
																routes.portfolioByIdView.toHref(
																	{
																		portfolioId: portfolio.id,
																		viewType: 'list',
																	},
																	{
																		expirationYears: [expirationYear],
																		spaceTypes: [spaceTypeName],
																	}
																)
															);
														},
													},
												},
											]}
										/>
									);
								})}
							</VictoryStack>
						</VictoryChart>
						<Legend>
							{chartData.map(([spaceType], i) => (
								<LegendItem key={i}>
									<LegendColor color={PORTFOLIO_CHART_COLORS[i]} />
									<LegendLabel>{spaceType}</LegendLabel>
								</LegendItem>
							))}
						</Legend>
					</ChartAndLegendContainer>
				);
			}}
		</ChartBox>
	);
};

const filterValidYears = (
	leaseExpiration: PortfolioLeaseExpirationsBySpaceTypesByYears
) => {
	const currentYear = new Date().getFullYear();
	const validYears = Array.from(Array(NUMBER_OF_YEARS).keys()).map(
		(n) => currentYear + n
	);
	return validYears.includes(leaseExpiration.expirationYear);
};

const CHART_TYPE_TO_KEY: {
	[key in ChartType]: keyof PortfolioLeaseExpirationsBySpaceTypesByYears;
} = {
	leaseAmount: 'expiringLeaseAmount',
	leaseCount: 'expiringLeaseCount',
	sf: 'totalSqFt',
};
