import { User } from '@compstak/common';

// @ts-expect-error TS7006: Parameter 'str' implicitly has...
export function email(str) {
	// regex from http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
	const re =
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(str);
}

// @ts-expect-error TS7006: Parameter 'str' implicitly has...
export function validateEmail(str) {
	return email(str);
}

export const domainsMatch = (email: string, user: User) => {
	const userEmail = user.email;
	const userDomain = userEmail.substring(userEmail.indexOf('@') + 1);
	const targetDomain = email.substring(email.indexOf('@') + 1);

	return (
		userDomain === targetDomain ||
		targetDomain.toLowerCase() === 'compstak.com' ||
		userDomain.toLowerCase() === 'compstak.com'
	);
};

// @ts-expect-error TS7006: Parameter 'str' implicitly has...
export function phone(str) {
	// regex from http://stackoverflow.com/questions/123559/a-comprehensive-regex-for-phone-number-validation
	const re =
		/^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
	return re.test(str);
}

// @ts-expect-error TS7006: Parameter 'str' implicitly has...
export function validatePhone(str) {
	return phone(str);
}

// @ts-expect-error TS7006: Parameter 'str' implicitly has...
export function hasCapitalLetter(str) {
	const re = /[A-Z]/;
	return re.test(str);
}

// @ts-expect-error TS7006: Parameter 'str' implicitly has...
export function hasNumber(str) {
	const re = /[0-9]/;
	return re.test(str);
}

// @ts-expect-error TS7006: Parameter 'str' implicitly has...
export function hasProhibitedWord(str) {
	const re = /password|user|username/i;
	return re.test(str);
}

// @ts-expect-error TS7006: Parameter 'str' implicitly has...
export function isLongerThan(str, length) {
	return str.length > length;
}
