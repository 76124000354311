import { exportImage } from '@compstak/ui-kit';
import { Portfolio } from 'api';

export const exportPortfolio = (
	portfolio: Portfolio,
	dashboardElement?: HTMLElement | null
) => {
	if (!dashboardElement) return;
	exportImage({
		element: dashboardElement,
		filename: `Portfolio Analytics - ${portfolio.name}`,
		bannerId: 'PortfolioDashboard',
		ignoreElements: (node) => node.classList.contains('mapboxgl-ctrl'),
	});
};
