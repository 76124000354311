import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
	PortfolioDropdownMenuContentV2,
	PortfolioDropdownMenuItemV2,
} from '../UI';

type Props = {
	openUpdateDialog: () => void;
	openCopyDialog: () => void;
	openDeleteDialog: () => void;
};

export const PortfolioOptionsMenuV2 = ({
	openUpdateDialog,
	openCopyDialog,
	openDeleteDialog,
}: Props) => {
	return (
		<DropdownMenu.Portal>
			<PortfolioDropdownMenuContentV2 side="right" align="start">
				<PortfolioDropdownMenuItemV2 onClick={openUpdateDialog}>
					Edit name & description
				</PortfolioDropdownMenuItemV2>
				<PortfolioDropdownMenuItemV2 onClick={openCopyDialog}>
					Duplicate Portfolio
				</PortfolioDropdownMenuItemV2>
				<PortfolioDropdownMenuItemV2 onClick={openDeleteDialog}>
					Delete Portfolio
				</PortfolioDropdownMenuItemV2>
			</PortfolioDropdownMenuContentV2>
		</DropdownMenu.Portal>
	);
};
