import { SimpleTableColumn, renderTableValue, MQB } from '@compstak/ui-kit';
import {
	formatCompQuarter,
	formatDate,
	formatMoney,
	formatMonths,
	formatRentWithPostfix,
	formatSqft,
} from '../../../../../format';
import { UnderlyingLeasesCompAttributes } from 'api/underlyingLeases/types';
import { SelectionState, getIsMonthlyMarket } from '../../../../../utils';
import { SortTriangle } from '../../../../../Components/SearchTable/UI';
import { FiltersObject } from '../../../../../models/filters/types';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { CheckedState } from '@radix-ui/react-checkbox';
import { Checkbox } from 'Components';
import { defaultTheme } from '@compstak/ui-kit';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

type UnderlyingDataColumnsProps = {
	rowSelectionMethods: SelectionState<UnderlyingLeasesCompAttributes>;
	onSort: (columnId: keyof UnderlyingLeasesCompAttributes) => void;
	filters: FiltersObject | undefined;
};

export const useUnderlyingDataColumns = ({
	rowSelectionMethods,
	onSort,
	filters,
}: UnderlyingDataColumnsProps) => {
	const renderHeaderWithSort =
		(headerName: string, headerCellProps?: HeaderCellProps) =>
		({
			column,
		}: {
			column: SimpleTableColumn<UnderlyingLeasesCompAttributes>;
		}) => {
			const hasSortIcon = filters?.sortField === column.id;
			return (
				<HeaderCell
					onClick={() => {
						onSort(column.id as keyof UnderlyingLeasesCompAttributes);
					}}
					{...headerCellProps}
				>
					<span>{headerName}</span>
					<SortIconWrap hasSortIcon={hasSortIcon}>
						{hasSortIcon &&
							(filters.sortDirection === 'asc' ? (
								<SortTriangle style={{ marginLeft: 'auto' }} />
							) : (
								<SortTriangle
									style={{ marginLeft: 'auto', transform: 'rotate(180deg)' }}
								/>
							))}
					</SortIconWrap>
				</HeaderCell>
			);
		};

	const isTablet = useMediaQuery({ maxWidth: MQB.T_834 });

	let checkboxState: CheckedState = false;
	const {
		areAllSelected,
		areSomeSelected,
		toggleRow,
		toggleAllSelected,
		selection,
	} = rowSelectionMethods;
	if (areSomeSelected && !areAllSelected) {
		checkboxState = 'indeterminate';
	} else if (areAllSelected) {
		checkboxState = true;
	}

	const { chartBuilderCanExcludeComps } = useFeatureFlags();

	const columns: SimpleTableColumn<UnderlyingLeasesCompAttributes>[] = [
		{
			id: 'executionQuarter',
			header: renderHeaderWithSort('Trans. Qtr.'),
			width: 110,
			body: ({ row }) => {
				return renderTableValue(row.executionQuarter, formatCompQuarter);
			},
		},
		{
			id: 'address',
			header: renderHeaderWithSort('Address'),
			width: isTablet ? 100 : 220,
			body: ({ row }) => {
				return renderTableValue(row.buildingAddress);
			},
		},
		{
			id: 'tenantName',
			header: renderHeaderWithSort('Tenant'),
			width: isTablet ? 90 : 170,
			body: ({ row }) => {
				return renderTableValue(row.tenantName);
			},
			flex: 1,
			hAlign: 'left',
		},
		{
			id: 'transactionSize',
			header: renderHeaderWithSort('Transaction SqFt', {
				justifyContent: 'end',
			}),
			width: isTablet ? 130 : 145,
			body: ({ row }) => {
				return renderTableValue(row.transactionSize, (v) => formatSqft(v));
			},
		},
		{
			id: 'startingRent',
			header: renderHeaderWithSort('Starting Rent', {
				justifyContent: 'end',
			}),
			width: isTablet ? 105 : 130,
			body: ({ row }) => {
				return renderTableValue(row.startingRent, (v) =>
					formatRentWithPostfix(v, getIsMonthlyMarket(row.marketId))
				);
			},
			flex: 1,
		},
		{
			id: 'effectiveRent',
			header: renderHeaderWithSort('Effective Rent', {
				justifyContent: 'end',
			}),
			width: isTablet ? 110 : 130,
			body: ({ row }) => {
				return renderTableValue(row.effectiveRent, (v) =>
					formatRentWithPostfix(v, getIsMonthlyMarket(row.marketId))
				);
			},
		},
		{
			id: 'freeMonths',
			header: renderHeaderWithSort('Free Rent (mo.)', {
				justifyContent: 'end',
			}),
			width: isTablet ? 120 : 140,
			body: ({ row }) => {
				return renderTableValue(row.freeMonths, (v) => formatMonths(v));
			},
		},
		{
			id: 'workValue',
			header: renderHeaderWithSort('TI Value / Work Value', {
				justifyContent: 'end',
				textAlign: 'right',
			}),
			width: isTablet ? 100 : 140,
			body: ({ row }) => {
				return renderTableValue(row.workValue, (v) => formatMoney(v));
			},
		},
		{
			id: 'leaseTerm',
			header: renderHeaderWithSort('Lease Term', { justifyContent: 'end' }),
			width: isTablet ? 100 : 120,
			body: ({ row }) => {
				return renderTableValue(row.leaseTerm, (v) => formatMonths(v));
			},
		},
		{
			id: 'expirationDate',
			header: renderHeaderWithSort('Expiration Date', {
				justifyContent: 'end',
			}),
			width: isTablet ? 121 : 140,
			body: ({ row }) => {
				return renderTableValue(row.expirationDate, formatDate);
			},
		},
	];

	const checkBoxesColumn = {
		id: 'selection',
		header: () => {
			return (
				<CheckboxWrap onClick={toggleAllSelected}>
					<Checkbox
						id="selectAll"
						name="selectAll"
						data-qa-id="select-all-checkbox"
						checked={checkboxState}
						bgColorUnchecked={defaultTheme.colors.white.white}
						borderColorChecked={defaultTheme.colors.gray.gray700}
						borderColorUnchecked={defaultTheme.colors.gray.gray700}
					/>
				</CheckboxWrap>
			);
		},
		body: ({ row }: { row: UnderlyingLeasesCompAttributes }) => {
			return (
				<CheckboxWrap
					onClick={() => {
						toggleRow(row.id);
					}}
				>
					<Checkbox
						borderColorChecked={defaultTheme.colors.gray.gray700}
						borderColorUnchecked={defaultTheme.colors.gray.gray700}
						name={`selectRow-${row.id}`}
						data-qa-id={`select-row-checkbox-${row.id}`}
						checked={selection.some((id) => id === row.id)}
					/>
				</CheckboxWrap>
			);
		},
		width: 50,
	};

	chartBuilderCanExcludeComps && columns.unshift(checkBoxesColumn);

	return columns;
};

type HeaderCellProps = { justifyContent?: string; textAlign?: string };

const HeaderCell = styled.div<HeaderCellProps>`
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: ${({ justifyContent = 'space-between' }) => justifyContent};
	text-align: ${({ textAlign = 'left' }) => textAlign};
`;

const SortIconWrap = styled.span<{ hasSortIcon: boolean }>`
	margin-left: ${({ hasSortIcon }) => (hasSortIcon ? 0.5 : 0)}rem;
`;

const CheckboxWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 40px;
	min-height: 40px;
`;
