import { useIsPropertyMarketMonthly } from 'api/utils/useIsPropertyMarketMonthly';
import { formatMoney, getRentPeriodPostfix } from 'format';
import { routes } from 'router';
import { BlueBoxLabel } from '../UI';
import { CompsetLabel } from './CompsetLabel';
import { MarketLabel } from './MarketLabel';
import { MarketStatsChart } from './MarketStatsChart';
import { SubmarketLabel } from './SubmarketLabel';
import { useMarketStatsChartData } from './useMarketStatsChartData';

export const EffectiveRentMarketStats = () => {
	const params = routes.propertyById.useParams();

	const { data, isFetching } = useMarketStatsChartData({
		metric: 'effectiveRent',
		shouldShowMonthly: true,
	});

	const { data: isMonthlyMarket } = useIsPropertyMarketMonthly({
		propertyId: params.propertyId,
	});

	return (
		<div>
			<BlueBoxLabel>
				Effective Rent (PSF•
				{getRentPeriodPostfix(isMonthlyMarket, { withParens: false })})
			</BlueBoxLabel>
			<MarketStatsChart
				items={[
					{ label: 'Subj. Property', middle: data?.leases.middle },
					{
						label: <CompsetLabel />,
						...data?.compset,
					},
					{ label: <SubmarketLabel />, ...data?.submarket },
					{ label: <MarketLabel />, ...data?.market },
				]}
				formatValue={formatMoney}
				formatXTick={(v) => formatMoney(v, { decimalPlaces: 0 })}
				isLoading={isFetching}
			/>
		</div>
	);
};
