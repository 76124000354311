import ShapeDataLoader, {
	ChartBuilderContentSpinner,
} from 'Components/Graphs/ShapeDataLoader';
import { Chart } from 'Pages/Analytics/analytics';
import { AnalyticsProjectActions } from 'Pages/Analytics/Repository/actions';
import withProjects from 'Pages/Analytics/Repository/withProjects';
import { MarketsState } from 'Pages/Login/reducers';
import React from 'react';
import IconExport from 'ui/svg_icons/download_icon.svg';
import EditIcon from '../../../../ui/svg_icons/edit.svg';
import TickIcon from '../../../../ui/svg_icons/tick.svg';
import { ChartBuilderActions } from '../actions';
import { SaveButton } from './SaveButton';
import styles from './styles/analytics.less';
import Toolbar from './Toolbar';
import { connect } from 'react-redux';
import wrapActions from 'util/actionWrapper';
import { menuActions } from 'Singletons/Menu/actions';
import { AppDispatch } from 'store';
import { AnalyticsExportMenuProps } from './Menus/ExportMenu';
import { Options } from 'highcharts';
import { ContentComponent } from './ContentComponent';
import './Menus/ExportMenu';
import { UnderlyingDataTableProvider } from './UnderlyingDataTableProvider';
import { Button, Flex, defaultTheme } from '@compstak/ui-kit';

const getIndexesToDisplay = (chartDraft: Chart) => {
	// Gets indexes of datasets that are visible, then matches those
	// indexes to arrays of shapes/data
	return chartDraft.dataSets.reduce((acc, dataSet, idx) => {
		return dataSet.isVisible ? acc.concat(idx) : acc;
	}, [] as number[]);
};

const LoaderizedContent = ShapeDataLoader(
	// @ts-expect-error TS2345: Argument of type '(props: Cont...
	ContentComponent,
	ChartBuilderContentSpinner,
	{
		allowErrorData: true,
	}
);

type InsightsLayoutProps = {
	chartDraft: Chart;
	markets: MarketsState;
	chartBuilderActions: ChartBuilderActions;
	analyticsProjectActions: AnalyticsProjectActions;
	copyToExcel: () => void;
	export: (chartOptions: Options) => void;
} & ReturnType<typeof mapDispatchToProps>;

type InsightsLayoutState = {
	oldTitle: string;
	title: string;
	focused: boolean;
};

const InsightsLayoutComponent = withProjects(
	class InsightsLayout extends React.Component<
		InsightsLayoutProps,
		InsightsLayoutState
	> {
		state = {
			oldTitle: this.props.chartDraft.title,
			title: this.props.chartDraft.title,
			focused: false,
		};

		// @ts-expect-error TS7006: Parameter 'newProps' implicitl...
		static getDerivedStateFromProps(newProps, prevState) {
			if (newProps.chartDraft.title !== prevState.oldTitle) {
				return {
					oldTitle: newProps.chartDraft.title,
					title: newProps.chartDraft.title,
				};
			}

			return prevState;
		}

		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		handleTitleChange = (event) => {
			if (event.target.value.length <= 100) {
				this.setState({ title: event.target.value });
			}
		};

		handleTitleFocus = () => {
			this.setState({ focused: true });
		};

		handleTitleBlur = () => {
			this.handleTitleSubmit();
			this.setState({ focused: false });
		};

		handleTitleSubmit = () => {
			const newChartDraft = {
				...this.props.chartDraft,
				title: this.state.title,
			};

			// @ts-expect-error ts-migrate(2339) FIXME: Property 'analyticsProjects' does not exist on typ... Remove this comment to see the full error message
			const currentProject = this.props.analyticsProjects.find((project) =>
				project.charts.some(
					// @ts-expect-error TS7006: Parameter 'chart' implicitly h...
					(chart) => chart.id === this.props.chartDraft.originalChartId
				)
			);

			if (
				currentProject != null &&
				this.props.chartDraft.title !== this.state.title &&
				currentProject.charts.some(
					// @ts-expect-error TS7006: Parameter 'chart' implicitly h...
					(chart) => chart.title === this.state.title
				)
			) {
				this.props.analyticsProjectActions.renameChartError();
			} else if (newChartDraft.title.trim().length === 0) {
				this.props.analyticsProjectActions.nonameChartError();
				this.setState({
					title: this.props.chartDraft.title,
				});
			} else {
				this.props.chartBuilderActions.updateChartDraft(
					newChartDraft,
					this.props.markets
				);
			}
		};

		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		handleKeyUp = (event) => {
			if (event.keyCode === 13) {
				event.currentTarget.blur();
			}
		};

		handleIconClick = () => {
			if (this.state.focused) {
				this.handleTitleSubmit();
				this.setState({ focused: false });
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'myRef' does not exist on type 'InsightsL... Remove this comment to see the full error message
				this.myRef.current.blur();
			}
		};

		// @ts-expect-error TS7006: Parameter 'event' implicitly h...
		openExportMenu = (event) => {
			this.props.menuActions.showMenu<AnalyticsExportMenuProps>(
				'analytics-export-menu',
				event.currentTarget,
				'below-onleft',
				{
					copyToExcel: this.props.copyToExcel,
					title: this.props.chartDraft.title,
					exportFn: this.props.export,
				}
			);
		};

		render() {
			let icon;
			let iconClass;
			let headerClass = '';
			if (this.state.focused) {
				icon = <TickIcon width={16} />;
				iconClass = styles.iconFocus;
				headerClass = styles.focused;
			} else {
				icon = <EditIcon width={16} />;
				iconClass = styles.iconHidden;
			}

			const indexesToDisplay = getIndexesToDisplay(this.props.chartDraft);

			if (indexesToDisplay.length === 0) {
				return (
					<div className={styles.unhideDataSet}>
						<div>Unhide a data set to see your chart.</div>
					</div>
				);
			}

			return (
				<div className={styles.container}>
					<div className={styles.chartHeaderContainer}>
						<div className={`${styles.chartNameInputContainer} ${headerClass}`}>
							<input
								className={styles.chartHeaderInput}
								value={this.state.title}
								onChange={this.handleTitleChange}
								onFocus={this.handleTitleFocus}
								onBlur={this.handleTitleBlur}
								onKeyUp={this.handleKeyUp}
							/>
							<div
								className={`${styles.chartHeaderIconContainer} ${iconClass}`}
								onClick={this.handleIconClick}
							>
								{icon}
							</div>
						</div>
						<Flex gap="0.5rem">
							<SaveButton
								chartDraft={{
									...this.props.chartDraft,
									title: this.state.title,
								}}
							/>
							<Button
								variant="primary3"
								onClick={this.openExportMenu}
								icon={
									<IconExport
										fill={defaultTheme.colors.white.white}
										width={18}
									/>
								}
							>
								Export
							</Button>
						</Flex>
					</div>
					<Toolbar
						chart={this.props.chartDraft}
						// @ts-expect-error TS2551: Property 'market' does not exi...
						market={this.props.market}
						// @ts-expect-error ts-migrate(2339) FIXME: Property 'setChartAttribute' does not exist on typ... Remove this comment to see the full error message
						setChartAttribute={this.props.setChartAttribute}
					/>
					{/* To prevent the provider from being unmounted too often, please do not lower its position in the hierarchy. */}
					<UnderlyingDataTableProvider>
						<LoaderizedContent
							{...this.props}
							// @ts-expect-error TS2322: Type '{ indexesToDisplay: numb...
							indexesToDisplay={indexesToDisplay}
						/>
					</UnderlyingDataTableProvider>
				</div>
			);
		}
	}
);

const mapDispatchToProps = (dispatch: AppDispatch) => {
	return wrapActions(
		{
			menuActions,
		},
		dispatch
	);
};

export default connect(null, mapDispatchToProps)(InsightsLayoutComponent);
