import React, { useMemo, useState } from 'react';
import { Portfolio, useInsightsQuery } from 'api';
import { FiltersState } from '../PortfolioFiltersProvider';
import { TrendFilters } from 'PortfolioAnalytics/portfolioAnalyticsTypes';
import { DownloadBranding } from 'PortfolioAnalytics/Singletons/DownloadBranding';
import { PortfolioTrendChartComponent as Chart } from './Chart/PortfolioTrendChartComponent';
import { PortfolioTrendFiltersComponent as Filters } from './Filters/PortfolioTrendFilterComponent';
import { PortfolioHelpIcon } from 'PortfolioAnalytics/Singletons/PortfolioHelpIcon';
import { ChartContainer } from 'PortfolioAnalytics/styles/PortfolioUI';
import { Spinner } from '@compstak/ui-kit';
import { NoDataTab } from 'Components/NoDataMessaging/NoDataMessaging';
import { Styled } from './styled';
import { styled } from 'styled-components';
import { Spacer } from 'PortfolioAnalytics/UI';
import { getTrendlineData, getDateForTimeSpan, getScatterData } from './util';
import { CalculationOverviewLink } from 'ui/CalculationOverviewLink';
import { getFiltersFromPortfolioFilters } from 'PortfolioAnalytics/LeaseActivity/utils';
import { filtersToServerJSON } from 'models/filters/util';
import { FULL_DATA_THRESHOLD } from 'Components/Graphs/constants';

type MarketTrendComponentProps = {
	portfolio: Portfolio;
	filters: FiltersState;
};

export const PortfolioTrend = ({
	portfolio,
	filters,
}: MarketTrendComponentProps) => {
	const [trendFilters, setTrendFilters] = useState<TrendFilters>({
		metric: 'effectiveRent',
		timeSpan: 3,
		movAverage: 3,
	});

	const portfolioQueryParams = useMemo(() => {
		const filtersFromPortfolioFilters = getFiltersFromPortfolioFilters({
			buildingClassIds: filters.buildingClassIds,
			leaseExpirationsMonths: filters.leaseExpirationsMonths,
			propertyTypeIds: filters.propertyTypeIds,
			submarketIds: filters.submarketIds,
			portfolio,
			buildingSize: filters.buildingSize,
		});
		const serverFilters = filtersToServerJSON(filtersFromPortfolioFilters);
		const baseBody = {
			properties: [
				trendFilters.metric,
				'executionDate',
				'transactionSize',
				'id',
			],
			fullDataThreshold: FULL_DATA_THRESHOLD,
			quartileThreshold: 10e9,
			trendMonths: trendFilters.movAverage,
		};
		return {
			...baseBody,
			filter: [
				...serverFilters,
				{
					property: 'outlier',
					value: false,
				},
				{
					property: 'hidden',
					value: false,
				},
			],
		};
	}, [portfolio, filters, trendFilters.metric, trendFilters.movAverage]);

	const {
		data: portfolioTrendData,
		isLoading: isPortfolioTrendLoading,
		isError: isPortfolioTrendError,
	} = useInsightsQuery({
		params: portfolioQueryParams,
		context: 'portfolioTrend',
	});

	const isBubbleChart = !!portfolioTrendData?.points;

	const chartData = useMemo(() => {
		if (
			!portfolioTrendData?.trendLine?.length ||
			(portfolioTrendData.points && !portfolioTrendData.points.length)
		) {
			return {
				portfolio: [],
				points: [],
				market: [],
			};
		}

		const timeSpanDate = getDateForTimeSpan(trendFilters.timeSpan);

		return {
			portfolio: getTrendlineData(portfolioTrendData.trendLine, timeSpanDate),
			points: getScatterData(
				portfolioTrendData.points || [],
				trendFilters.metric
			),
		};
	}, [portfolioTrendData, trendFilters.timeSpan, trendFilters.metric]);

	const noData = !chartData.portfolio.length && !chartData.points.length;

	const title = 'Portfolio Trend';
	const downloadBannerId = title.replaceAll(' ', '');

	const isLoading = isPortfolioTrendLoading;

	return (
		<PortfolioTrendContainer>
			<ChartTopRow>
				<Heading>
					<Title>{title}</Title>
					<PortfolioHelpIcon
						height={16}
						width={16}
						tooltip={MARKET_TREND_TOOLTIP}
					/>
				</Heading>
				<Filters
					filters={trendFilters}
					setMarketTrendFilters={setTrendFilters}
					isBubbleChart={isBubbleChart}
				/>
			</ChartTopRow>
			<Styled.Error show={isPortfolioTrendError}>
				<div>
					<span>Error!</span>
					<div>An error occurred while loading the Market trends </div>
				</div>
			</Styled.Error>
			{isLoading ? (
				<PortfolioChartContainer>
					<Spinner size="l" />
				</PortfolioChartContainer>
			) : (
				<>
					{noData ? (
						<PortfolioChartContainer>
							<NoDataTab minHeight={`48px`} message="" />
						</PortfolioChartContainer>
					) : (
						<Chart
							data={chartData}
							noData={noData}
							metricFilter={trendFilters.metric}
							timeSpan={trendFilters.timeSpan}
							isBubbleChart={isBubbleChart}
						/>
					)}
				</>
			)}
			<DownloadBranding bannerId={downloadBannerId} />
		</PortfolioTrendContainer>
	);
};

const PortfolioTrendContainer = styled.div`
	padding: 1.5rem;
	background-color: ${({ theme }) => theme.colors.white};
`;

const Heading = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
	column-gap: 1rem;

	${({ theme }) => theme.breakpoints.up('D_1536')} {
		width: 100px;
		margin-right: 2rem;
	}

	${({ theme }) => theme.breakpoints.up('D_1920')} {
		width: 100%;
		column-gap: 1rem;
	}
`;

const Title = styled.span`
	color: ${({ theme }) => theme.colors.gray.gray700};
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 1.5rem;
	letter-spacing: 0.1px;
`;

const ChartTopRow = styled.div`
	display: flex;
	justify-content: space-between;
`;

const PortfolioChartContainer = styled(ChartContainer)`
	padding: 2.5rem 0 0;
`;

const CalculationOverview = styled(CalculationOverviewLink)`
	color: ${({ theme }) => theme.colors.blue.blue500};
	font-size: 0.75rem;
	font-weight: 350;
	line-height: 1.125rem;
	letter-spacing: 0.3px;
	padding: 0.25rem 0;
	text-transform: uppercase;
	&:hover {
		color: ${({ theme }) => theme.colors.blue.blue800};
	}
`;

const MARKET_TREND_TOOLTIP = (
	<>
		<Spacer>
			The portfolio trend chart plots the moving averages of several portfolio
			lease metrics over time.
		</Spacer>
		<Spacer>
			You can use the Metric selector to switch between Net Effective Rent,
			Starting Rent, Lease Term, Free Rent and Tenant Improvement Allowance.
		</Spacer>
		<Spacer>
			You can also select a timespan between one year and 15 years, and adjust
			the moving average period.
		</Spacer>
		<Spacer>
			<CalculationOverview context="charts" />
		</Spacer>
	</>
);
