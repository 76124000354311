import { defaultTheme } from '@compstak/ui-kit';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { compstakEstimatesLabels } from 'Pages/PropertyPageV3/CompstakEstimates';
import {
	ROW_COUNT,
	getLabel,
	getPropertyAttributesKeys,
	keyInfoAttributes,
	shouldChangeAlignment,
} from 'Pages/PropertyPageV3/MainInfo';
import {
	useMapImageUrl,
	useStreetViewImageUrl,
} from 'Pages/PropertyPageV3/hooks/mapImages';
import { PdfPage } from '../../../../pdf/PdfPage';
import { SectionsState } from '../ExportPdfProvider';
import { PdfRealPageLogoView } from '../PdfRealPageLogoView';
import { PdfPageProps } from '../types';
import { compstakEstimatesAttributes } from './EditableCompstakEstimates';
import { leaseSnapshotLabels } from './EditableLeaseSnapshot';
import { PropertyAddress } from './PropertyAddress';
import { useEditableCompstakEstimates } from './useEditableCompstakEstimates';
import {
	EditableLeaseSnapshot,
	editableLeaseSnapshotFieldKeys,
} from './useEditableLeaseSnapshot';
import { EditableOverviewFields } from './useEditableOverview';

export const OverviewPage = (
	props: PdfPageProps & {
		editableOverviewFields: EditableOverviewFields;
		editableLeaseSnapshotFields: EditableLeaseSnapshot;
		isMufa: boolean;
		isStreetViewEnabled: boolean;
		sections: SectionsState;
	} & Pick<
			ReturnType<typeof useEditableCompstakEstimates>,
			'editableCompstakEstimates'
		>
) => {
	const {
		property,
		apn,
		editableOverviewFields,
		isMufa,
		isStreetViewEnabled,
		editableLeaseSnapshotFields,
		editableCompstakEstimates,
		sections,
	} = props;

	const propertyAttributes = getPropertyAttributesKeys(
		isMufa,
		property.buildingPropertyType
	);
	const visibleKeyInfoItems = keyInfoAttributes.filter(
		(key) => editableOverviewFields.visibleFields[key]
	);
	const visiblePropertyAttributes = propertyAttributes.filter(
		(key) => editableOverviewFields.visibleFields[key]
	);
	const visibleLeaseSnapshotFields = editableLeaseSnapshotFieldKeys.filter(
		(key) => editableLeaseSnapshotFields.visibleFields[key]
	);

	const propertyAttributesSections = [];
	for (let i = 0; i < visiblePropertyAttributes.length; i = i + ROW_COUNT) {
		propertyAttributesSections.push(
			visiblePropertyAttributes.slice(i, i + ROW_COUNT)
		);
	}

	const { geoPoint } = property;
	const mapImageUrl = useMapImageUrl({
		width: MAP_SIZE.width,
		height: MAP_SIZE.height,
		geoPoint,
	});
	const streetViewImageUrl = useStreetViewImageUrl({
		width: MAP_SIZE.width,
		height: MAP_SIZE.height,
		geoPoint,
	});

	return (
		<PdfPage
			logo={props.page.logo}
			preparedFor={props.page.preparedFor}
			title={props.page.titles.overview}
			user={props.user}
		>
			<View fixed>
				<PropertyAddress property={property} apn={apn} />
			</View>
			<View style={style.overviewContainer}>
				<View style={style.leftContainer} fixed>
					{isStreetViewEnabled && (
						<Image style={style.img} src={streetViewImageUrl.href} />
					)}
					<Image style={style.img} src={mapImageUrl.href} />
				</View>
				<View style={style.rightContainer}>
					<View style={style.keyInfoItems}>
						{visibleKeyInfoItems.slice(0, 3).map((key, index) => (
							<View
								key={key}
								style={[
									style.keyInfoItem,
									{ flex: index === 2 ? '0 0 33%' : undefined },
								]}
							>
								<Text style={style.keyInfoItemLabel}>
									{getLabel(isMufa, key)}
								</Text>
								<Text style={style.keyInfoItemValue}>
									{editableOverviewFields.keyInfoAttributes[key]}
								</Text>
							</View>
						))}
					</View>
					<View style={style.keyInfoItems}>
						{visibleKeyInfoItems.slice(3).map((key) => (
							<View key={key} style={style.keyInfoItem}>
								<Text style={style.keyInfoItemLabel}>
									{getLabel(isMufa, key)}
								</Text>
								<Text style={style.keyInfoItemValue}>
									{editableOverviewFields.keyInfoAttributes[key]}
								</Text>
							</View>
						))}
					</View>

					{!!propertyAttributesSections.length && (
						<View>
							<Text style={style.keyValuesTableTitle}>Property Attributes</Text>
							<View style={style.keyValuesTables}>
								{propertyAttributesSections.map((section, index) => (
									<View
										key={'propertyAttributeSection' + index}
										style={style.keyValuesTable}
									>
										{section.map((key) => {
											const vertical = shouldChangeAlignment(
												key,
												editableOverviewFields.propertyAttributes[key]
											);
											return (
												<View
													key={key}
													style={
														vertical
															? style.keyValuesContainerVertical
															: style.keyValuesContainer
													}
												>
													<Text
														style={
															vertical
																? style.keyValueLabelVertical
																: style.keyValueLabel
														}
													>
														{getLabel(isMufa, key)}
													</Text>
													<Text
														style={
															vertical
																? style.keyValuesValueVertical
																: style.keyValuesValue
														}
													>
														{editableOverviewFields.propertyAttributes[key]}
													</Text>
												</View>
											);
										})}
									</View>
								))}
							</View>
							{isMufa && <PdfRealPageLogoView />}
						</View>
					)}
					{sections.leaseSnapshot &&
						editableLeaseSnapshotFields.showLeaseSnapshot && (
							<View wrap={false}>
								<Text style={style.keyValuesTableTitle}>
									Property Lease Snapshot
								</Text>
								<View style={style.keyValuesTables}>
									{[
										visibleLeaseSnapshotFields.slice(0, 3),
										visibleLeaseSnapshotFields.slice(3),
									].map((section, index) => (
										<View
											key={'leaseSnapshot' + index}
											style={style.leaseSnapshotTable}
										>
											{section.map((key) => (
												<View key={key} style={style.keyValuesContainer}>
													<Text style={style.keyValueLabel}>
														{leaseSnapshotLabels[key]}
													</Text>
													<Text style={style.keyValuesValue}>
														{editableLeaseSnapshotFields[key]}
													</Text>
												</View>
											))}
										</View>
									))}
								</View>
							</View>
						)}
					{sections.marketEstimates &&
						editableCompstakEstimates.showCompstakEstimates && (
							<View wrap={false}>
								<Text style={style.keyValuesTableTitle}>
									Market Rate Estimates
								</Text>
								<View style={style.keyValuesTables}>
									{compstakEstimatesAttributes.map((key) => (
										<View
											key={key}
											style={[
												style.keyValuesContainer,
												style.leaseSnapshotTable,
											]}
										>
											<Text style={[style.keyValueLabel, { width: '50%' }]}>
												{compstakEstimatesLabels[key]}
											</Text>
											<Text style={style.keyValuesValue}>
												{editableCompstakEstimates[key]}
											</Text>
										</View>
									))}
								</View>
							</View>
						)}
				</View>
			</View>
		</PdfPage>
	);
};

const MAP_SIZE = {
	width: 240,
	height: 160,
};

const style = StyleSheet.create({
	img: {
		width: MAP_SIZE.width,
		height: MAP_SIZE.height,
	},
	overviewContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: 16,
	},
	leftContainer: {
		width: 320,
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		marginRight: 16,
	},
	rightContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
	},
	keyInfoItems: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	keyInfoItem: {
		textAlign: 'left',
		flex: '0 0 20%',
	},
	keyInfoItemLabel: {
		fontSize: 10,
		fontWeight: 300,
		color: defaultTheme.colors.neutral.n85,
	},
	keyInfoItemValue: {
		fontSize: 12,
		fontWeight: 400,
	},
	keyValuesTableTitle: {
		fontSize: 12,
		borderBottom: '1px solid black',
		paddingBottom: 4,
	},
	keyValuesTables: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		gap: 16,
	},
	keyValuesTable: {
		flexBasis: '30%',
	},
	leaseSnapshotTable: {
		flexBasis: '50%',
	},
	keyValuesContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: 16,
		paddingVertical: 4,
		borderBottom: `0.3pt solid ${defaultTheme.colors.neutral.n85}`,
	},
	keyValuesContainerVertical: {
		display: 'flex',
		flexDirection: 'column',
		gap: 4,
		paddingVertical: 4,
		borderBottom: `0.3pt solid ${defaultTheme.colors.neutral.n85}`,
	},
	keyValueLabel: {
		fontSize: 9,
		width: '25%',
		color: defaultTheme.colors.neutral.n85,
	},
	keyValueLabelVertical: {
		fontSize: 9,
		width: '100%',
		color: defaultTheme.colors.neutral.n85,
	},
	keyValuesValue: {
		fontSize: 9,
		textAlign: 'right',
		flex: 1,
	},
	keyValuesValueVertical: {
		fontSize: 9,
		textAlign: 'left',
	},
});
