import { Portfolio } from 'api';
import { PortfolioLeasesFilters } from './PortfolioLeasesFiltersProvider';
import { FiltersState } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import dayjs from 'dayjs';
import { CreateFiltersArg, createFilters } from 'models/filters';
import { LeaseFilters } from 'models/filters/lease';

export const getFiltersFromPortfolioFilters = ({
	buildingClassIds,
	leaseExpirationsMonths,
	propertyTypeIds,
	submarketIds,
	portfolio,
	compType = 'lease',
	buildingSize,
}: FiltersState & { portfolio: Portfolio; compType?: CreateFiltersArg }) => {
	const filters = createFilters(compType, {
		portfolioId: portfolio.id,
		partial: 'false',
	});
	if (buildingClassIds) {
		filters.buildingClassId = buildingClassIds;
	}
	if (leaseExpirationsMonths) {
		filters.expirationDate = {
			min: null,
			max: dayjs().add(leaseExpirationsMonths, 'month').toDate(),
		};
	}
	if (propertyTypeIds) {
		filters.buildingPropertyTypeId = propertyTypeIds;
	}
	if (submarketIds) {
		filters.submarkets = portfolio.markets
			.flatMap((market) => market.submarkets)
			.map(({ submarketId, submarket }) => ({
				id: submarketId,
				name: submarket,
			}))
			.filter(({ id }) => submarketIds.includes(id));
	}

	if (buildingSize) {
		filters.buildingSize = buildingSize;
	}

	return filters;
};

export const getFiltersFromPortfolioLeasesFilters = ({
	activeLeasesOnly,
	filterBy,
	portfolio,
}: PortfolioLeasesFilters & { portfolio: Portfolio }) => {
	const filters: Partial<LeaseFilters> = {
		portfolioId: portfolio.id,
		partial: 'false',
		sortField: 'executionQuarter',
		sortDirection: 'desc',
	};
	if (activeLeasesOnly) {
		filters.expirationDate = {
			min: new Date(),
			max: null,
		};
	}
	if (filterBy) {
		const criterion = filterBy.criterion;
		const duration = filterBy.duration.value;
		const unit = filterBy.duration.unit;
		switch (criterion) {
			case 'newOnCompstak': {
				filters.dateCreated = {
					min: dayjs().subtract(duration, unit).toDate(),
					max: null,
				};
				break;
			}
			case 'recentlySigned': {
				filters.executionDate = {
					min: dayjs().subtract(duration, unit).toDate(),
					max: null,
				};
				break;
			}
			case 'recentlyUpdated': {
				filters.lastAttributeUpdate = {
					min: dayjs().subtract(duration, unit).toDate(),
					max: null,
				};
				break;
			}
		}
	}

	return filters;
};
