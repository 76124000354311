import { useCallback, useEffect, useMemo, useState } from 'react';

export type SelectionState<Row extends { id: number } = { id: number }> = {
	selection: number[];
	getIsRowSelected: (id: number) => boolean;
	toggleRow: (id: number) => void;
	areAllSelected: boolean;
	toggleAllSelected: () => void;
	areSomeSelected: boolean;
	selectedRows: Row[];
	resetSelection: () => void;
};

export const useSelectionState = <Row extends { id: number }>(
	rows: Row[] | undefined,
	getInitialSelection?: () => number[]
): SelectionState<Row> => {
	const [selection, setSelection] = useState<number[]>(() => {
		return getInitialSelection ? getInitialSelection() : [];
	});

	const getIsRowSelected = (id: number) => selection.includes(id);

	const toggleRow = (id: number) => {
		const isSelected = getIsRowSelected(id);

		if (isSelected) {
			setSelection((s) => s.filter((_id) => _id !== id));
		} else {
			setSelection((s) => [...s, id]);
		}
	};

	const areAllSelected = useMemo(() => {
		if (!rows) return false;
		return (
			rows.length > 0 &&
			selection.length === rows.length &&
			rows.every((p) => selection.includes(p.id))
		);
	}, [selection, rows]);

	const toggleAllSelected = () => {
		if (!rows) return;

		if (areAllSelected) {
			setSelection([]);
		} else {
			setSelection(rows.map((p) => p.id));
		}
	};

	const areSomeSelected = selection.length > 0;

	const selectedRows = useMemo(() => {
		if (!rows) return [];

		const _selectedRows: Row[] = [];

		for (const id of selection) {
			const property = rows.find((p) => p.id === id);
			if (property) {
				_selectedRows.push(property);
			}
		}

		return _selectedRows;
	}, [selection, rows]);

	const resetSelection = useCallback(() => {
		setSelection([]);
	}, []);

	useEffect(() => {
		setSelection((prevSelection) =>
			prevSelection.filter((id) => rows?.find((p) => p.id === id))
		);
	}, [rows]);

	return {
		selection,
		getIsRowSelected,
		toggleRow,
		areAllSelected,
		toggleAllSelected,
		areSomeSelected,
		selectedRows,
		resetSelection,
	};
};
