import { useMemo } from 'react';
import { Spinner } from '@compstak/ui-kit';
import { usePortfolioByIdQuery } from 'api';
import { routes } from 'router';
import styled from 'styled-components';
import { usePortfolioFilters } from '../PortfolioFiltersProvider';
import { PortfolioStatsV2 } from 'PortfolioAnalytics/Components/PortfolioStatsV2';
import {
	BackToDashboardArrowLeft,
	BackToDashboardLink,
	MapListLoadingContainer,
	Toolbar,
} from '../UI';
import { PortfolioTable } from './PortfolioTable';
import { PortfolioTableLeases } from './PortfolioTableLeases';
import { ListMapToggler } from 'PortfolioAnalytics/Components/ListMapToggler';
import { useNavigate } from 'react-router';
import { PROPERTY_TYPE_ID_TO_NAME, BUILDING_CLASS_ID_TO_NAME } from 'api';

type Props = {
	portfolioId: number;
};

export const ListViewV2 = ({ portfolioId }: Props) => {
	const [searchParams] = routes.portfolioByIdView.useSearchParams();

	const isLeasesList =
		!!searchParams.expirationYears ||
		!!searchParams.tenantNames ||
		!!searchParams.tenantIndustry;

	const { filters } = usePortfolioFilters();

	const { data: portfolio } = usePortfolioByIdQuery({
		id: portfolioId,
		filters,
	});

	const navigate = useNavigate();

	const dataType = useMemo(() => {
		const mappings = [
			{
				key: 'tenantNames',
				label: 'Tenant Name',
				value: searchParams.tenantNames?.[0],
			},
			{
				key: 'tenantIndustry',
				label: 'Industry',
				value:
					searchParams.tenantIndustry && searchParams.tenantIndustry?.length > 1
						? 'Other Industries'
						: searchParams.tenantIndustry?.[0],
			},
			{
				key: 'expirationYears',
				label: 'Expiration Year',
				value: searchParams.expirationYears?.[0],
			},
			{
				key: 'propertyTypeIds',
				label: 'Property Type',
				value: searchParams.propertyTypeIds?.[0]
					? PROPERTY_TYPE_ID_TO_NAME[searchParams.propertyTypeIds?.[0]]
					: undefined,
			},
			{
				key: 'buildingClassIds',
				label: 'Building Class',
				value: searchParams.buildingClassIds?.[0]
					? BUILDING_CLASS_ID_TO_NAME[searchParams.buildingClassIds?.[0]]
					: undefined,
			},
		];
		const match = mappings.find(({ value }) => value);
		return match ? `${match.label}: ${match.value}` : undefined;
	}, [searchParams]);

	if (!portfolio) {
		return (
			<MapListLoadingContainer>
				<Spinner />
			</MapListLoadingContainer>
		);
	}

	const title = `
	${isLeasesList ? 'Leases' : 'Properties'} • ${dataType}`;

	return (
		<Root>
			<Toolbar>
				<Container>
					<BackToDashboardLink
						to={routes.portfolioByIdView.toHref({
							portfolioId: portfolioId,
							viewType: 'dashboard',
						})}
					>
						<BackToDashboardArrowLeft />
						Back to Dashboard
					</BackToDashboardLink>
					<PortfolioStatsV2 portfolioId={portfolioId} />
				</Container>
				<Spacer />
				<Container>
					<ControlsContainer>
						<ListTitle>{title}</ListTitle>
					</ControlsContainer>
					<ControlsContainer>
						<ListMapToggler
							view={'list'}
							onViewClick={(view) =>
								navigate(
									routes.portfolioByIdView.toHref({
										portfolioId,
										viewType: view,
									})
								)
							}
						/>
					</ControlsContainer>
				</Container>
			</Toolbar>
			<>
				{isLeasesList ? (
					<PortfolioTableLeases portfolioId={portfolioId} />
				) : (
					<PortfolioTable portfolioId={portfolioId} />
				)}
			</>
		</Root>
	);
};

const LIST_VIEW_TOOLBAR_HEIGHT = 140;

const Root = styled.div`
	display: grid;
	grid-template-rows: ${LIST_VIEW_TOOLBAR_HEIGHT}px auto;
	box-sizing: border-box;
	height: 100%;
	* {
		box-sizing: border-box;
	}
`;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.5rem;
`;

const Spacer = styled.div`
	height: 1rem;
`;

const ListTitle = styled.span`
	font-size: 1rem;
	font-style: normal;
	font-weight: 325;
	line-height: 1.5rem;
	letter-spacing: 0.215px;
	color: ${({ theme }) => `${theme.colors.gray.gray700}`};
	overflow: hidden;
	max-width: 500px;
	white-space: nowrap;
	text-overflow: ellipsis;
	${({ theme }) => theme.breakpoints.up('D_1280')} {
		max-width: 800px;
		font-size: 1.25rem;
	}
`;

const ControlsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;
