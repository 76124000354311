import { useMemo } from 'react';
import { Portfolio, PortfolioFilterQuery } from 'api';

export type PortfolioStats = {
	propertyCount: number;
	activeLeaseCount: number;
	totalSqFt: number;
	expiringSqFt24Months: number;
	marketsCount: number;
};

export const usePortfolioStats = (
	portfolio: Portfolio | undefined,
	filters: PortfolioFilterQuery
): PortfolioStats => {
	return useMemo(() => {
		if (!portfolio)
			return {
				propertyCount: 0,
				activeLeaseCount: 0,
				totalSqFt: 0,
				expiringSqFt24Months: 0,
				marketsCount: 0,
			};
		let propertyCount = 0;
		let activeLeaseCount = 0;
		let totalSqFt = 0;
		let expiringSqFt24Months = 0;
		let marketsCount = 0;

		portfolio.markets.map((market) => {
			propertyCount +=
				filters.leaseExpirationsMonths !== null
					? market.propertyThatHasActiveLeasesCount
					: market.propertyCount;
			activeLeaseCount += market.activeLeaseCount;
			totalSqFt += market.totalSqFt;
			expiringSqFt24Months += market.expiringSqFt24Months;
			marketsCount++;
		});

		return {
			propertyCount,
			activeLeaseCount,
			totalSqFt,
			expiringSqFt24Months,
			marketsCount,
		};
	}, [portfolio, filters.leaseExpirationsMonths]);
};
