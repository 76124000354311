import React from 'react';
import { VictoryTooltipProps } from 'victory';

import { metricDropdownData } from '../Filters';

import { TrendMetricFilter } from 'PortfolioAnalytics/portfolioAnalyticsTypes';

import { Styled } from './styled';

import { formatDate } from '../util';
import money from 'ui/util/money';

type MarketTrendChartFlyoutProps = VictoryTooltipProps & {
	isMonthsMetric: boolean;
	width: number;
	metricFilter: TrendMetricFilter;
	chartWidth: number;
};

export const PortfolioTrendChartFlyout = ({
	x,
	activePoints,
	isMonthsMetric,
	width,
	metricFilter,
	chartWidth,
}: MarketTrendChartFlyoutProps) => {
	if (!activePoints || !activePoints[0] || !x) return null;
	const xValue = activePoints[0].x;
	const yValueData = {
		portfolio: activePoints[0].y,
	};
	const metricType = metricDropdownData.find(
		(m) => m.id === metricFilter
	)?.value;
	const symbol = isMonthsMetric ? '(Months)' : '($/PSF)';
	const value = isMonthsMetric
		? yValueData.portfolio.toFixed(1)
		: money(yValueData.portfolio, 'USD', 2, false);
	const showRightTooltipBar = x > chartWidth / 2;

	const tooltipBarWidth = 5;
	const translateX = showRightTooltipBar
		? x + tooltipBarWidth - width
		: x - tooltipBarWidth;

	return (
		<g transform={`translate(${translateX}, ${20})`}>
			<foreignObject width={width} height={280}>
				<Styled.CustomTooltipContainer>
					<Styled.CustomTooltipBar hide={showRightTooltipBar} height={'280px'}>
						<div />
					</Styled.CustomTooltipBar>
					<Styled.CustomTooltipInfoBox style={{ height: '50px' }}>
						<Styled.CustomTooltipInfoBoxHeading>
							<div>{formatDate(xValue, false)}</div>
							<div>{`${metricType} ${symbol}`}</div>
						</Styled.CustomTooltipInfoBoxHeading>
						<Styled.CustomTooltipInfoBoxData>
							<div>
								<Styled.CustomTooltipInfoBoxIndicator color="#5C59E8" />
							</div>
							<div>
								<span>{value}</span>
							</div>
						</Styled.CustomTooltipInfoBoxData>
					</Styled.CustomTooltipInfoBox>
					<Styled.CustomTooltipBar
						hide={!showRightTooltipBar}
						right={showRightTooltipBar}
						height={'280px'}
					>
						<div />
					</Styled.CustomTooltipBar>
				</Styled.CustomTooltipContainer>
			</foreignObject>
		</g>
	);
};
