import { Button, ButtonProps } from '@compstak/ui-kit';
import { useAppConfig } from 'Pages/Login/reducers';
import { PORTFOLIO_LIMIT_EXCEEDED_MESSAGE } from 'PortfolioAnalytics/constants';
import { PORTFOLIO_LIMIT } from 'api/portfolio/constants';
import {
	useIsSendToAnalyticsBtnAllowed,
	useSendToAnalyticsBtn,
} from 'hooks/chartBuilderHooks';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { FiltersObject } from 'models/filters/types';
import { getSetFilterKeysExceptMarkets } from 'models/filters/util/filterHelpers';
import { MouseEvent, memo } from 'react';
import { Link } from 'router';
import styled from 'styled-components';
import { Comp, CompType } from 'types/comp';
import { useAppSelector } from 'util/useAppSelector';

export function ExportButtons({
	filters,
	selection,
	compType,
	showSaveSearchMenu,
	showAddToPortfolioModal,
}: {
	filters: FiltersObject;
	selection: Comp[];
	compType: CompType;
	showSaveSearchMenu: (ev: MouseEvent) => void;
	showAddToPortfolioModal?: NoArgCallback;
}) {
	const featureFlags = useFeatureFlags();
	const appConfig = useAppConfig();
	const hasSetFilters = getSetFilterKeysExceptMarkets(filters).length > 0;
	const hasSelection = selection.length > 0;
	const portfolioLimitExceeded = selection.length > PORTFOLIO_LIMIT;
	const isLoadingComps = useAppSelector(
		(state) => state.searchReducer.isLoadingComps
	);

	const isSaveSearchBtnAllowed = hasSetFilters;
	const isSendToAnalyticsBtnAllowed =
		useIsSendToAnalyticsBtnAllowed(compType) && (hasSetFilters || hasSelection);
	const isAddToPortfolioBtnAllowed =
		featureFlags['portfolio-analytics'] &&
		appConfig.isEnterprise &&
		compType === 'property' &&
		(hasSetFilters || hasSelection);

	return (
		<ExportBtnsContainer>
			{isSaveSearchBtnAllowed && (
				<SaveSearchButton onClick={showSaveSearchMenu} />
			)}
			{isAddToPortfolioBtnAllowed && (
				<AddToPortfolioButton
					disabled={isLoadingComps || !hasSelection || portfolioLimitExceeded}
					onClick={showAddToPortfolioModal}
					data-tooltip={
						portfolioLimitExceeded ? PORTFOLIO_LIMIT_EXCEEDED_MESSAGE : ''
					}
					data-tooltip-position="above"
				/>
			)}
			{isSendToAnalyticsBtnAllowed && (
				<SendToAnalyticsButton filters={filters} compType={compType} />
			)}
		</ExportBtnsContainer>
	);
}

const SendToAnalyticsButton = memo(
	({ filters, compType }: { filters: FiltersObject; compType: CompType }) => {
		const { isEnabled, href, tooltipText } = useSendToAnalyticsBtn({
			filters,
			compType,
		});

		return (
			<Button
				variant="success"
				className="safari-flexbox-bugged"
				disabled={!isEnabled}
				data-tooltip={isEnabled && tooltipText ? undefined : tooltipText}
				data-tooltip-position="above"
				data-tooltipdelay="0"
			>
				<Link
					to={href ?? ''}
					data-qa-id={`${isEnabled ? '' : 'disabled-'}send-to-analytics-link`}
				>
					Send To Chartbuilder
				</Link>
			</Button>
		);
	}
);

SendToAnalyticsButton.displayName = 'SendToAnalyticsLinkButton';

const SaveSearchButton = memo((props: ButtonProps) => {
	return (
		<Button
			dataTestId="showSaveSearchMenu"
			variant="primary3"
			className="safari-flexbox-bugged"
			{...props}
		>
			Save Search
		</Button>
	);
});

SaveSearchButton.displayName = 'SaveSearchButton';

const AddToPortfolioButton = memo((props: ButtonProps) => {
	return (
		<Button
			variant="primary3"
			dataTestId="showAddToPortfolioModal"
			className="safari-flexbox-bugged"
			{...props}
		>
			Add to portfolio
		</Button>
	);
});

AddToPortfolioButton.displayName = 'AddToPortfolioButton';

const ExportBtnsContainer = styled.div`
	transition: bottom 0.2s ease-in-out;
	background-color: ${(props) => props.theme.colors.gray.gray700};
	z-index: ${(props) => props.theme.zIndex.menu};
	position: sticky;
	bottom: 0;
	&:has(button) {
		padding: 0 20px 20px 20px;
	}

	@keyframes savedSearchGlow {
		0% {
			background: auto;
		}
		20% {
			background: ${({ theme }) => theme.colors.blue.blue500};
		}
		100% {
			background: auto;
		}
	}

	button {
		animation: savedSearchGlow 1.5s ease-in-out forwards;
		font-size: 13px;
		font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
		width: 100%;
		letter-spacing: 1px;
		&:not(:last-child) {
			margin-bottom: 20px;
		}

		a {
			color: inherit;
			width: 100%;
		}
	}
`;
