import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { PortfolioLeaseExpirationsMonths } from 'api';
import { usePortfolioFilters } from '../../../PortfolioFiltersProvider';
import {
	FilterButtonIconArrow,
	FilterButtonIconContainer,
	FilterButtonLabel,
	FilterButtonLabelContainer,
	FilterButtonValue,
	FilterDropdownButton,
	FilterDropdownContent,
	FilterItemIconTick,
	FilterRadioItem,
} from '../UI';

export const LeaseExpirationFilter = () => {
	const { filters, setFilters } = usePortfolioFilters();

	const displayValue =
		filters.leaseExpirationsMonths === null
			? 'All'
			: `${filters.leaseExpirationsMonths} months`;

	const value =
		filters.leaseExpirationsMonths === null
			? 'all'
			: String(filters.leaseExpirationsMonths);

	return (
		<DropdownMenu.Root modal={false}>
			<FilterDropdownButton style={{ width: 110 }}>
				<FilterButtonLabelContainer>
					<FilterButtonLabel>Lease Exp</FilterButtonLabel>
					<FilterButtonValue>{displayValue}</FilterButtonValue>
				</FilterButtonLabelContainer>
				<FilterButtonIconContainer>
					<FilterButtonIconArrow width={10} height={6} />
				</FilterButtonIconContainer>
			</FilterDropdownButton>
			<DropdownMenu.Portal>
				<FilterDropdownContent>
					<DropdownMenu.DropdownMenuRadioGroup
						value={value}
						onValueChange={(newValue) => {
							setFilters((s) => ({
								...s,
								leaseExpirationsMonths:
									newValue === 'all'
										? null
										: (Number(newValue) as PortfolioLeaseExpirationsMonths),
							}));
						}}
					>
						{OPTIONS.map((option) => {
							const isSelected =
								filters.leaseExpirationsMonths === null
									? option.value === 'all'
									: filters.leaseExpirationsMonths === Number(option.value);

							return (
								<FilterRadioItem
									key={option.value}
									value={String(option.value)}
								>
									<div>{option.label}</div>
									{isSelected && <FilterItemIconTick />}
								</FilterRadioItem>
							);
						})}
					</DropdownMenu.DropdownMenuRadioGroup>
				</FilterDropdownContent>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};

const OPTIONS = [
	{ label: 'All', value: 'all' },
	{ label: '12 months', value: '12' },
	{ label: '24 months', value: '24' },
	{ label: '36 months', value: '36' },
	{ label: '48 months', value: '48' },
	{ label: '60 months', value: '60' },
];
