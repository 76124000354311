import { CompsetDTO, CompsetType, useCompsetType } from 'api';
import styled from 'styled-components';
import { LeaseComp } from 'types';
import { SelectionState } from 'utils';
import { RealPage } from '../../RealPage';
import { EditPdfTable } from '../EditPdfTable';
import { ExportPdfLeasesStatus } from '../ExportPdfStatus';
import { PropertyEditPage } from '../PropertyEditPage';
import { EditPageSectionTitle } from '../UI';
import { EditableCompsetRow, EditableLeaseComp, PdfColumn } from '../types';
import { ExportPdfData } from '../useExportPdfData';
import { useCompsetMapImageUrl } from './useCompsetMapImageUrl';

type Props = {
	rows: CompsetDTO[];
	editableCompsetRows: EditableCompsetRow[];
	setEditableCompsetRows: React.Dispatch<
		React.SetStateAction<EditableCompsetRow[]>
	>;
	columns: PdfColumn<EditableCompsetRow, CompsetDTO>[];
	setEditableCompsetColumns: React.Dispatch<
		React.SetStateAction<PdfColumn<EditableCompsetRow, CompsetDTO>[]>
	>;
	editableLeasesInCompset: EditableLeaseComp[];
	setEditableLeasesInCompset: React.Dispatch<
		React.SetStateAction<EditableLeaseComp[]>
	>;
	editableLeasesInCompsetColumns: PdfColumn<EditableLeaseComp, LeaseComp>[];
	setEditableLeasesInCompsetColumns: React.Dispatch<
		React.SetStateAction<PdfColumn<EditableLeaseComp, LeaseComp>[]>
	>;
	data: ExportPdfData;
	leasesInCompsetSelection: SelectionState<EditableLeaseComp>;
	showLeasesInCompset: boolean;
};

export const EditCompsetPage = ({
	rows,
	editableCompsetRows,
	setEditableCompsetRows,
	columns,
	setEditableCompsetColumns,
	editableLeasesInCompset,
	setEditableLeasesInCompset,
	editableLeasesInCompsetColumns,
	setEditableLeasesInCompsetColumns,
	data,
	leasesInCompsetSelection,
	showLeasesInCompset,
}: Props) => {
	const url = useCompsetMapImageUrl({
		width: 1136,
		height: 350,
		properties: rows,
	});

	const compsetType = useCompsetType({ propertyId: data.property.id });

	return (
		<PropertyEditPage pageKey="compset">
			<CompsetContainer>
				<EditPageSectionTitle>Competitive Set</EditPageSectionTitle>
				<img src={url.href} width={1136} height={350} alt="Compset" />
				<EditPdfTable
					rows={editableCompsetRows}
					columns={columns}
					onChange={({ row, column, value }) => {
						setEditableCompsetRows((state) => {
							return state.map((r) => {
								if (r.id === row.id) {
									return {
										...r,
										[column.id]: value,
									};
								}
								return r;
							});
						});
					}}
					onToggleColumn={({ column }) => {
						setEditableCompsetColumns((cols) =>
							cols.map((c) => {
								if (c.id === column.id) {
									return {
										...c,
										isVisible: !c.isVisible,
									};
								}
								return c;
							})
						);
					}}
				/>
				{compsetType === CompsetType.REALPAGE && (
					<RealPageLogoContainer>
						<RealPage />
					</RealPageLogoContainer>
				)}
			</CompsetContainer>
			{showLeasesInCompset && editableLeasesInCompset.length > 0 && (
				<LeasesInCompsetContainer>
					<EditPageSectionTitle>
						<div>
							Leases in Competitive Set
							{data.leases.length > 0 && (
								<InfoText>
									Subject property leases are highlighted in grey
								</InfoText>
							)}
						</div>
						<ExportPdfLeasesStatus data={data} />
					</EditPageSectionTitle>
					<EditPdfTable
						rows={editableLeasesInCompset}
						columns={editableLeasesInCompsetColumns}
						onChange={({ row, column, value }) => {
							setEditableLeasesInCompset((state) => {
								return state.map((r) => {
									if (r.id === row.id) {
										return {
											...r,
											[column.id]: value,
										};
									}
									return r;
								});
							});
						}}
						onToggleColumn={({ column }) => {
							setEditableLeasesInCompsetColumns((cols) =>
								cols.map((c) => {
									if (c.id === column.id) {
										return {
											...c,
											isVisible: !c.isVisible,
										};
									}
									return c;
								})
							);
						}}
						getShouldHighlightRow={(propertyId) =>
							Number(propertyId) === data.property.id
						}
						selection={{
							...leasesInCompsetSelection,
							getIsRowSelected: (row) =>
								leasesInCompsetSelection.getIsRowSelected(row.id),
							toggleRow: (row) => {
								leasesInCompsetSelection.toggleRow(row.id);
							},
						}}
					/>
				</LeasesInCompsetContainer>
			)}
		</PropertyEditPage>
	);
};

const CompsetContainer = styled.div`
	margin-top: 1.5rem;
`;

const LeasesInCompsetContainer = styled.div`
	margin-top: 2rem;
`;

const RealPageLogoContainer = styled.div`
	margin-top: 1rem;
`;

const InfoText = styled.span`
	font-size: 0.875rem;
	margin-left: 2rem;
`;
