import { Checkbox } from 'Components/Checkbox';
import { ComponentProps, ReactNode, useId } from 'react';
import styled from 'styled-components';
import { FormControlLabel } from '../pdf/UI';

type Props = {
	checked: boolean;
	onCheckedChange: (checked: boolean) => void;
	label: ReactNode;
	horizontal?: boolean;
	subContent?: ReactNode;
	sideLabel?: ReactNode;
	disabled?: boolean;
	containerProps?: ComponentProps<'div'>;
};

export const CheckboxControl = ({
	label,
	horizontal,
	subContent,
	sideLabel,
	containerProps,
	...props
}: Props) => {
	const id = useId();

	return (
		<CheckboxControlContainer
			{...containerProps}
			style={{
				marginBottom: horizontal ? 0 : '1.5rem',
				...containerProps?.style,
			}}
		>
			<Checkbox {...props} id={id} />
			<div style={{ width: '100%' }}>
				<LabelContainer>
					<FormControlLabel htmlFor={id}>{label}</FormControlLabel>
					{sideLabel}
				</LabelContainer>
				{subContent}
			</div>
		</CheckboxControlContainer>
	);
};

const CheckboxControlContainer = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 0.5rem;
`;

const LabelContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
