import { Spinner } from '@compstak/ui-kit';
import { invalidatePortfolioQueries } from 'PortfolioAnalytics/invalidatePortfolioQueries';
import { Portfolio, useUpdatePortfolioMutation } from 'api';
import { PopupActionButton, StyledArrowRightIcon } from 'maps';
import styled from 'styled-components';
import { PropertyComp } from 'types';

type Props = {
	property: PropertyComp;
	portfolio: Portfolio;
};

export const RemovePropertyPopupButton = ({ property, portfolio }: Props) => {
	const { mutate: updatePortfolio, isLoading: isLoadingUpdatePortfolio } =
		useUpdatePortfolioMutation({
			onSuccess: ({ queryClient }) => {
				invalidatePortfolioQueries(queryClient);
			},
		});

	return (
		<PopupActionButton
			variant="secondary"
			disabled={isLoadingUpdatePortfolio}
			onClick={() => {
				updatePortfolio({
					id: portfolio.id,
					body: {
						id: portfolio.id,
						name: portfolio.name,
						description: portfolio.description,
						removedPropertyIds: [property.id],
					},
				});
			}}
		>
			<RemoveIcon>
				{isLoadingUpdatePortfolio ? <Spinner size="s" /> : <span>&mdash;</span>}
			</RemoveIcon>
			<span>Remove From Portfolio</span>
			<StyledArrowRightIcon />
		</PopupActionButton>
	);
};

const RemoveIcon = styled.span`
	min-width: 1rem;
`;
