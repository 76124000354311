import {
	AngleArrow,
	Button,
	Close,
	Excel,
	Flex,
	Pdf,
	Spinner,
} from '@compstak/ui-kit';
import * as Popover from '@radix-ui/react-popover';
import { defaultDropdownContentCss } from 'Components/UI';
import styled from 'styled-components';
import { useCompExport } from './useCompExport';
import { CompType, PropertyComp } from 'types';
import { PropertyLease, PropertySale } from 'api';
import { uniq } from 'lodash';
import { useMarkets } from 'hooks/useMarkets';
import { useMemo, useState } from 'react';
import { shouldUseMonthlyMetricsByMarkets } from 'models/filters/util/shouldUseMonthlyMetrics';

type Props<T extends CompType> = {
	comps: (T extends 'lease'
		? PropertyLease
		: T extends 'sale'
			? PropertySale
			: PropertyComp)[];
	type: T;
	closePopup: () => void;
	side?: Popover.PopoverContentProps['side'];
};

export const CompExportPopup = <T extends CompType>({
	comps,
	type,
	closePopup,
	side,
}: Props<T>) => {
	const markets = useMarkets();
	const compsMarkets = useMemo(() => {
		return uniq(
			comps.map((comp) => {
				if ('marketDisplayName' in comp) {
					return comp.marketDisplayName as string;
				}
				if ('marketName' in comp) {
					return comp.marketName as string;
				}
				return comp.market;
			})
		).map((marketName) => markets[marketName]);
	}, [comps, markets]);

	const [isMonthly, setIsMonthly] = useState(
		shouldUseMonthlyMetricsByMarkets(compsMarkets)
	);
	const [isAnnualMonthlySelectorOn, setIsAnnualMonthlySelectorOn] =
		useState(false);

	const {
		title: _title,
		isFetching,
		isLoadingExportExcel,
		onClickExportExcel,
		onClickExportPdf,
		onClickCopyIds,
		shouldShowIdsButton,
	} = useCompExport({
		comps,
		type,
		closePopup,
	});

	const title = isAnnualMonthlySelectorOn ? 'Excel Export' : _title;

	return (
		<>
			<Popover.Portal>
				<Content
					side={side}
					onCloseAutoFocus={(e) => {
						e.preventDefault();
					}}
				>
					<Popover.Arrow />
					{isFetching ? (
						<SpinnerContainer>
							<Spinner size="l" />
						</SpinnerContainer>
					) : (
						<Root>
							<Header>
								{isAnnualMonthlySelectorOn && (
									<div onClick={() => setIsAnnualMonthlySelectorOn(false)}>
										<StyledAngleArrow rotate={90} />
									</div>
								)}
								<Title>{title}</Title>
								<Close
									onClick={closePopup}
									data-qa-id="popup-content-close-btn"
									isInheritedColor
								/>
							</Header>
							{isAnnualMonthlySelectorOn ? (
								<AnnualMonthlySelector>
									<p>Show values monthly or annually</p>
									<AnnualMonthlySelectorButtons>
										<AnnualMonthlySelectorButton
											variant={isMonthly ? 'secondary' : 'ghost'}
											onClick={() => setIsMonthly(true)}
										>
											Monthly
										</AnnualMonthlySelectorButton>
										<AnnualMonthlySelectorButton
											variant={!isMonthly ? 'secondary' : 'ghost'}
											onClick={() => setIsMonthly(false)}
										>
											Annually
										</AnnualMonthlySelectorButton>
									</AnnualMonthlySelectorButtons>
									<Button
										icon={<Excel isInheritedColor />}
										isLoading={isLoadingExportExcel}
										onClick={() => onClickExportExcel({ monthly: isMonthly })}
									>
										EXPORT
									</Button>
								</AnnualMonthlySelector>
							) : (
								<Flex gap="0.5rem">
									<StyledButton
										icon={<Excel isInheritedColor />}
										onClick={() => setIsAnnualMonthlySelectorOn(true)}
										variant="primary"
										data-qa-id="popup-export-excel-btn"
									>
										EXCEL
									</StyledButton>
									{type !== 'property' && (
										<StyledButton
											icon={<Pdf isInheritedColor />}
											onClick={onClickExportPdf}
											data-qa-id="popup-export-pdf-btn"
										>
											PDF
										</StyledButton>
									)}
									{shouldShowIdsButton && (
										<StyledButton
											onClick={onClickCopyIds}
											data-qa-id="popup-export-ids-btn"
										>
											IDS
										</StyledButton>
									)}
								</Flex>
							)}
						</Root>
					)}
				</Content>
			</Popover.Portal>
		</>
	);
};

const Content = styled(Popover.Content)`
	${defaultDropdownContentCss};
	background-color: ${(p) => p.theme.colors.gray.gray700};
	z-index: ${(p) => p.theme.zIndex.overlay};
`;

const SpinnerContainer = styled.div`
	height: 92px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const StyledButton = styled(Button)`
	flex: 1;
`;

export const Root = styled.div`
	padding: 1rem;
	min-width: 260px;
	color: ${(p) => p.theme.colors.white.white};
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 0.5rem;
`;

export const Title = styled.div`
	display: flex;
	gap: 4px;
	align-items: center;
	font-size: 0.75rem;
	text-transform: uppercase;
`;

const AnnualMonthlySelector = styled.div`
	display: flex;
	gap: 0.5rem;
	flex-direction: column;

	p {
		font-weight: 400;
		opacity: 0.6;
		font-size: 0.6875rem;
		letter-spacing: 0.025em;
		line-height: 1.333em;
		text-transform: none;
		margin: 0;
		text-align: center;
	}
`;

const AnnualMonthlySelectorButtons = styled.div`
	display: flex;
	padding: 0.25rem;
	border-radius: 3px;
	background-color: ${({ theme }) => theme.colors.neutral.n800};
`;

const AnnualMonthlySelectorButton = styled(StyledButton)`
	font-size: 0.75rem;
	height: 1.5rem;
	color: ${({ theme }) => theme.colors.white.white};
`;

const StyledAngleArrow = styled(AngleArrow)`
	cursor: pointer;
	path {
		stroke: ${(p) => p.theme.colors.neutral.n0};
	}
`;
