import {
	ModalButton,
	ModalButtons,
	ModalCloseButton,
	ModalCloseIcon,
	ModalContent,
	ModalOverlay,
	onInteractOutsideModal,
	onPointerDownOutsideModal,
} from 'Components/Modals/common/UI';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import styled from 'styled-components';
import {
	SECTIONS_MAP,
	SectionKey,
	useExportPdfContext,
} from '../pdf/ExportPdfProvider';
import {
	ExportPdfLeasesStatus,
	ExportPdfSalesStatus,
} from '../pdf/ExportPdfStatus';
import { LeaseOptions } from '../pdf/LeaseOptions';
import { Subtext } from '../pdf/UI';
import {
	DEFAULT_NUM_OF_LEASES_SELECTED,
	DEFAULT_NUM_OF_SALES_SELECTED,
} from '../pdf/constants';
import { ExportPdfStep } from '../pdf/types';
import { ExportPdfData } from '../pdf/useExportPdfData';
import { CheckboxControl } from './CheckboxControl';

type Props = {
	data: ExportPdfData;
};

export const ExportPdfStepOne = ({ data }: Props) => {
	const {
		setStep,
		sections,
		leasesInCompsetOptionsState,
		leaseOptionsState,
		toggleSection,
	} = useExportPdfContext();

	const flags = useFeatureFlags();

	return (
		<ModalOverlay>
			<ModalCloseButton>
				<ModalCloseIcon />
			</ModalCloseButton>
			<DialogContent
				onInteractOutside={onInteractOutsideModal}
				onPointerDownOutside={onPointerDownOutsideModal}
			>
				<Section>
					<SectionLabel>
						Select the sections to include in the PDF:
					</SectionLabel>
					{Object.keys(sections).map((name) => {
						let subContent;
						let sideLabel;

						if (
							name === 'leasesInCompset' ||
							name === 'leaseSnapshot' ||
							name === 'marketEstimates' ||
							name === 'marketStats'
						) {
							return null;
						}

						if (name === 'cover') {
							subContent = (
								<Subtext>
									Specify preparation details and add additional notes.
								</Subtext>
							);
						} else if (name === 'overview') {
							subContent = (
								<>
									<Subtext style={{ marginBottom: '0.625rem' }}>
										Summarizes property details and key characteristics.
									</Subtext>
									<CheckboxControl
										label={SECTIONS_MAP.leaseSnapshot}
										checked={!!sections.leaseSnapshot}
										onCheckedChange={(value) => {
											toggleSection('leaseSnapshot', value);
										}}
										disabled={!sections.overview}
										containerProps={{ style: { marginBottom: '0.5rem' } }}
										subContent={
											<Subtext>
												Displays active lease data specific to the property.
											</Subtext>
										}
									/>
									<CheckboxControl
										label={SECTIONS_MAP.marketEstimates}
										checked={!!sections.marketEstimates}
										onCheckedChange={(value) => {
											toggleSection('marketEstimates', value);
										}}
										containerProps={{
											style: {
												marginBottom: flags.PropertyPagePhase3 ? '0.5rem' : 0,
											},
										}}
										subContent={
											<Subtext>
												Shows estimated rent prices reflecting current market
												value.
											</Subtext>
										}
									/>
									{flags.PropertyPagePhase3 && (
										<CheckboxControl
											label={SECTIONS_MAP.marketStats}
											checked={!!sections.marketStats}
											onCheckedChange={(value) => {
												toggleSection('marketStats', value);
											}}
											disabled={!sections.overview}
											containerProps={{ style: { marginBottom: 0 } }}
											subContent={
												<Subtext>
													Provides detailed metrics on a property's performance.
												</Subtext>
											}
										/>
									)}
								</>
							);
						} else if (name === 'compset' && data.leasesInCompset.length > 0) {
							sideLabel = <ExportPdfLeasesStatus data={data} />;
							subContent = (
								<CheckboxControl
									label={SECTIONS_MAP.leasesInCompset}
									checked={!!sections.leasesInCompset}
									onCheckedChange={(value) => {
										toggleSection('leasesInCompset', value);
									}}
									disabled={!sections.compset}
									subContent={
										<>
											<Subtext>
												The {DEFAULT_NUM_OF_LEASES_SELECTED} most recent leases
												from the competitive set are selected by default. To
												export within limit constraints, you can customize your
												selection on the next page.
											</Subtext>
											<LeaseOptions
												leaseOptionsState={leasesInCompsetOptionsState}
												radioName="pdf-leases-in-compset-status"
												leases={data.leasesInCompset}
												disabled={
													!sections.compset || !sections.leasesInCompset
												}
											/>
										</>
									}
									containerProps={{
										style: { marginBottom: 0, marginTop: '0.5rem' },
									}}
								/>
							);
						} else if (name === 'submarketTrends') {
							subContent = (
								<Subtext>
									Highlights rent trends over time, submarket rent distribution
									by square footage, and recent leases in the submarket.
								</Subtext>
							);
						} else if (name === 'leases') {
							sideLabel = <ExportPdfLeasesStatus data={data} />;
							subContent = (
								<>
									<Subtext>
										The {DEFAULT_NUM_OF_LEASES_SELECTED} most recent leases for
										the property are selected by default. To export within limit
										constraints, you can customize your selection on the next
										page.
									</Subtext>
									<LeaseOptions
										leaseOptionsState={leaseOptionsState}
										radioName="pdf-leases-status"
										leases={data.leases}
										disabled={!sections.leases}
									/>
								</>
							);
						} else if (name === 'sales') {
							sideLabel = <ExportPdfSalesStatus data={data} />;
							subContent = (
								<>
									<Subtext>
										The {DEFAULT_NUM_OF_SALES_SELECTED} most recent sales for
										the property are selected by default. To export within limit
										constraints, you can customize your selection on the next
										page.
									</Subtext>
								</>
							);
						} else if (name === 'loans') {
							subContent = (
								<Subtext>
									Contains loan details, either CMBS data from Trepp or public
									record loan information.
								</Subtext>
							);
						}

						return (
							<CheckboxControl
								key={name}
								label={SECTIONS_MAP[name as SectionKey]}
								checked={!!sections[name as SectionKey]}
								onCheckedChange={(value) => {
									toggleSection(name as SectionKey, value);
								}}
								subContent={subContent}
								sideLabel={sideLabel}
							/>
						);
					})}
				</Section>
				<StyledModalButtons>
					<ModalButton
						variant="primary"
						onClick={() => {
							setStep(ExportPdfStep.TWO);
						}}
					>
						Next
					</ModalButton>
				</StyledModalButtons>
			</DialogContent>
		</ModalOverlay>
	);
};

const DialogContent = styled(ModalContent)`
	width: fit-content;
	max-width: 700px;
	text-align: left;
	padding: 3rem 3rem 1.5rem 3rem;
`;

const Section = styled.div`
	margin-bottom: 2rem;
	padding-right: 2.5rem;
`;

const SectionLabel = styled.div`
	color: ${(p) => p.theme.colors.gray.gray700};
	font-size: 1.125rem;
	font-weight: 325;
	line-height: 1.5;
	margin-bottom: 1.25rem;
`;

const StyledModalButtons = styled(ModalButtons)`
	justify-content: center;
`;
