import * as Tabs from '@radix-ui/react-tabs';
import { usePortfolioByIdQuery } from 'api';
import styled from 'styled-components';
import { PortfolioOptionsButtonV2 } from '../../PortfolioOptions/PortfolioOptionsButtonV2';
import { PortfolioFiltersV2 } from './Filters/PortfolioFiltersV2';
import IconExportBlue from 'ui/svg_icons/export_blue.svg';
import IconShareBlue from 'ui/svg_icons/share_icon_blue.svg';
import IconPlusVeryLight from 'ui/svg_icons/plus_very_light.svg';
import { PortfolioStatsV2 } from '../../Components/PortfolioStatsV2';
import { exportPortfolio } from 'PortfolioAnalytics/Export/exportPortfolio';
import { Button } from '@compstak/ui-kit';
import { routes, useNavigate } from 'router';
import { PortfolioTabEnum } from '../Dashboard';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useModal } from 'providers/ModalProvider';
import { DataRequestDialog } from 'PortfolioAnalytics/Components/DataRequestDialog';
import { ShareWithColleagueModal } from 'Components/Modals/ShareWithColleagueModal/ShareWithColleagueModal';

type Props = {
	portfolioId: number;
	dashboardElement: HTMLElement | null;
};

export const DashboardHeaderV2 = ({ portfolioId, dashboardElement }: Props) => {
	const { portfolioRevampPropertiesTabFF } = useFeatureFlags();
	const { data: portfolio } = usePortfolioByIdQuery({ id: portfolioId });
	const { openModal, closeModal } = useModal();

	const navigate = useNavigate();

	if (!portfolio) return null;

	const hasDescription =
		typeof portfolio.description === 'string' &&
		portfolio.description.length > 0;

	return (
		<>
			<Header>
				<TopRow>
					<TitleContainer>
						<TitleRow>
							<Title>{portfolio.name}</Title>
							<PortfolioOptionsButtonV2 portfolio={portfolio} />
						</TitleRow>
						{hasDescription && (
							<Description>{portfolio.description}</Description>
						)}
					</TitleContainer>
					<RightControls>
						<PortfolioStatsV2 portfolioId={portfolioId} />
						<ButtonsRow>
							<IconButton>
								<IconShareBlue
									onClick={() => {
										openModal({
											modalContent: (
												<ShareWithColleagueModal
													itemName={portfolio.name}
													modalTitle="Share Portfolio"
													buttonText="Send portfolio"
													//TODO: replace with actual action when AP-17685 is done
													action={() => {}}
												/>
											),
										});
									}}
								/>
							</IconButton>
							<IconButton>
								<IconExportBlue
									onClick={() => {
										exportPortfolio(portfolio, dashboardElement);
									}}
								/>
							</IconButton>
							<Button
								variant="primary"
								icon={true}
								onClick={() => {
									navigate(routes.searchPropertiesHome.toHref());
								}}
							>
								<IconPlusVeryLight />
								Add New Property
							</Button>
						</ButtonsRow>
						<RequestAProperty>
							{"Can't find your property? "}
							<SendDataRequest
								onClick={() => {
									openModal({
										modalContent: (
											<DataRequestDialog closeDialog={closeModal} />
										),
									});
								}}
							>
								Send a data request
							</SendDataRequest>
						</RequestAProperty>
					</RightControls>
				</TopRow>
				<PortfolioFiltersV2 portfolioId={portfolioId} />
				<TabList>
					<Tab value={PortfolioTabEnum.OVERVIEW}>Overview</Tab>
					<Tab value={PortfolioTabEnum.MARKETS}>Markets</Tab>
					{/* // TODO This should be under separate feature flag */}
					{/* <Tab value={PortfolioTabEnum.BENCHMARKING}>Benchmarking</Tab> */}
					<Tab value={PortfolioTabEnum.LEASE_ACTIVITY}>Lease Activity</Tab>
					{portfolioRevampPropertiesTabFF && (
						<Tab value={PortfolioTabEnum.PROPERTIES}>Properties</Tab>
					)}
				</TabList>
			</Header>
		</>
	);
};

const Header = styled.div`
	padding: 1rem 1.5rem 0 1.5rem;
	background-color: ${(p) => p.theme.colors.white.white};
`;

const TopRow = styled.div`
	font-style: normal;
	display: grid;
	gap: 4rem;
	grid-template-columns: minmax(50px, 1fr) auto;
	margin-bottom: 2rem;
	${({ theme }) => theme.breakpoints.up('D_1920')} {
		margin-bottom: 1rem;
	}
`;

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 0.5rem;
	max-width: 650px;
`;

const TitleRow = styled.div`
	display: flex;
`;

const Title = styled.div`
	font-feature-settings:
		'liga' off,
		'clig' off;
	font-style: normal;
	font-weight: 400;
	line-height: 2.25rem;
	text-transform: uppercase;
	font-size: 1.125rem;
	color: ${(p) => p.theme.colors.gray.gray500};
	overflow: hidden;
	text-align: left;
`;

const Description = styled.p`
	color: ${(p) => p.theme.colors.gray.n100};
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 325;
	line-height: 1.125rem;

	word-break: break-word;
`;

const RightControls = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

const ButtonsRow = styled.div`
	display: flex;
	gap: 1.5rem;
	margin-bottom: 0.5rem;
	margin-top: 0.75rem;
`;

const IconButton = styled.button`
	all: unset;
	cursor: pointer;
`;

const RequestAProperty = styled.div`
	color: ${(p) => p.theme.colors.gray.gray700};
	text-align: right;
	font-size: 0.875rem;
	font-weight: 325;
	line-height: 1rem;
	letter-spacing: 0.25px;
	text-transform: uppercase;
`;

const SendDataRequest = styled.span`
	cursor: pointer;
	color: ${(p) => p.theme.colors.blue.blue500};
	text-decoration: none;
	display: block;
	margin-top: 0.25rem;
	${({ theme }) => theme.breakpoints.up('D_1280')} {
		display: inline-block;
		margin-top: 0;
	}
`;

const TabList = styled(Tabs.List)`
	display: flex;
	gap: 1rem;
	margin-top: 1.5rem;
`;

const Tab = styled(Tabs.Trigger)`
	all: unset;
	font-size: 0.75rem;
	line-height: 1rem;
	letter-spacing: 0.3px;
	font-weight: 325;
	padding: 0.75rem 0.5rem;
	cursor: pointer;
	box-sizing: border-box;
	text-transform: uppercase;
	position: relative;
	color: ${(p) => p.theme.colors.gray.n100};
	text-align: center;

	text-transform: uppercase;
	&:hover {
		background-color: ${(p) => p.theme.colors.neutral.n20};
	}
	&[data-state='active'] {
		font-weight: 400;
		color: ${(p) => p.theme.colors.blue.blue400};
		border-right: 1px solid ${(p) => p.theme.colors.gray.gray300};
		border-left: 1px solid ${(p) => p.theme.colors.gray.gray300};
		background: linear-gradient(
				0deg,
				rgba(0, 0, 0, 0.03) 0%,
				rgba(0, 0, 0, 0.03) 100%
			),
			#fff;
		border-top: 3px solid ${(p) => p.theme.colors.blue.blue400};
	}
`;
