import { Flex, SimpleTable, Spinner } from '@compstak/ui-kit';
import { ExportCompsButton } from 'Components/Export';
import { UnlockButton } from 'Components/Unlock';
import { UnlockButtonTooltip } from 'Components/Unlock/UnlockButtonTooltip';
import {
	useMarketsHaveFeature,
	usePropertyById,
	usePropertySalesQuery,
	useUnlockConditions,
	useUnlockSalesMutation,
} from 'api';
import { useIsExchange } from 'hooks';
import { ReactNode, useMemo } from 'react';
import { routes, useNavigate } from 'router';
import { createSearchSalesUrl } from 'utils';
import { NoDataTab } from '../../../Components/NoDataMessaging/NoDataMessaging';
import { RequestDemo } from '../RequestDemo';
import { TabContainer, TabContentTitle, TableActionsContainer } from '../UI';
import { UploadCompButton } from '../UploadCompButton';
import { PropertyPageTab } from '../types';
import { useSalesSelectionContext } from './SalesSelectionProvider';
import { useSalesTableColumns } from './hooks/useSalesTableColumns';
import { BlueLink } from 'Components';
import { useUnlockButtonLongText } from 'Components/Unlock/useUnlockButtonLongText';
import { useMarketByNameOrId } from 'Pages/Login/reducers';
import { MIN_TAB_HEIGHT } from '../constants';

const MAX_TABLE_HEIGHT = 586;

export const SalesTab = () => {
	const { selectedRows } = useSalesSelectionContext();
	const params = routes.propertyById.useParams();

	const { data: property, isFetching: isFetchingProperty } = usePropertyById({
		propertyId: params.propertyId,
	});

	const { data: hasSalesAccess, isFetching: isFetchingSalesAccess } =
		useMarketsHaveFeature({
			feature: 'salesAccess',
			marketIds: property ? [property.marketId] : [],
			isEnabled: property != null,
		});

	const { data: salesData, isLoading: isLoadingSales } = usePropertySalesQuery({
		propertyId: params.propertyId,
	});

	const columns = useSalesTableColumns({
		propertySalesComps: salesData?.comps,
	});

	const unlockedPropertySales = useMemo(
		() => selectedRows.filter((row) => row.own),
		[selectedRows]
	);
	const lockedPropertySales = useMemo(
		() => selectedRows.filter((row) => !row.own),
		[selectedRows]
	);

	const navigate = useNavigate();
	const isExchange = useIsExchange();

	const unlockButtonText = useUnlockButtonLongText({
		comps: lockedPropertySales,
	});

	const { hasEnoughPoints, isUserFetching } = useUnlockConditions({
		comps: lockedPropertySales,
	});

	const { mutate: unlockSales, isLoading: isUnlocking } =
		useUnlockSalesMutation();

	const market = useMarketByNameOrId(property!.marketId);

	const salesUrl = useMemo(
		() =>
			createSearchSalesUrl({
				market,
				address: [property!.buildingAddressAndCity],
			}),
		[market, property]
	);

	const isTabFetching =
		!salesData || isFetchingProperty || isFetchingSalesAccess || isLoadingSales;

	if (isTabFetching) {
		return (
			<TabContainer>
				<Spinner isCentered />
			</TabContainer>
		);
	}

	if (!hasSalesAccess) {
		return (
			<TabContainer>
				<RequestDemo tab={PropertyPageTab.SALES} />
			</TabContainer>
		);
	}

	const hasSales = salesData.comps.length > 0;

	if (!hasSales) {
		let emptyMessage: ReactNode =
			"CompStak doesn't have any Sales for this property at the moment.";

		if (isExchange) {
			emptyMessage = (
				<>
					<div>CompStak now offers Sale Comps!</div>
					<div>
						Be the first to report a sale for this property and earn an extra
						FREE comp!
					</div>
				</>
			);
		}

		return (
			<NoDataTab
				minHeight={`${MIN_TAB_HEIGHT}px`}
				message={emptyMessage}
				action={
					isExchange && (
						<UploadCompButton section="sale">Upload sale comp</UploadCompButton>
					)
				}
			/>
		);
	}

	return (
		<TabContainer>
			<Flex
				alignItems="center"
				justifyContent="space-between"
				style={{ marginBottom: '16px' }}
			>
				<TabContentTitle>
					Property Sales History ({salesData.comps.length})
				</TabContentTitle>
				<div>
					<BlueLink to={salesUrl} data-qa-id="SeeAllSalesLink" target="_blank">
						SEE ALL SALES IN LIST VIEW
					</BlueLink>
				</div>
			</Flex>
			<SimpleTable
				id="sale"
				rows={salesData.comps ?? []}
				columns={columns}
				isBodyHoverable
				scrollContainerProps={{
					style: { maxHeight: MAX_TABLE_HEIGHT },
				}}
				renderBodyCellContent={({ columnRender }) => {
					return columnRender();
				}}
				getBodyCellProps={({ row }) => ({
					onClick: () => {
						navigate(routes.saleById.toHref({ id: row.id }));
					},
				})}
			/>
			<TableActionsContainer>
				<ExportCompsButton
					compType="sale"
					comps={unlockedPropertySales}
					buttonType="table"
				/>
				{isExchange && (
					<UnlockButtonTooltip comps={lockedPropertySales}>
						<div>
							<UnlockButton
								onClick={() => {
									unlockSales({
										ids: lockedPropertySales.map((row) => row.id),
									});
								}}
								isLoading={isUnlocking}
								disabled={
									lockedPropertySales.length === 0 ||
									isUserFetching ||
									isUnlocking ||
									!hasEnoughPoints
								}
							>
								{unlockButtonText}
							</UnlockButton>
						</div>
					</UnlockButtonTooltip>
				)}
			</TableActionsContainer>
		</TabContainer>
	);
};
