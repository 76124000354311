import { usePortfolioLeasesFilters } from '../PortfolioLeasesFiltersProvider';
import styled from 'styled-components';
import { RadioButton } from 'Components/RadioButton/RadioButton';
import { CriterionRadioButton } from './CriterionRadioButton';
import { Checkbox } from 'Components';
import { ROW_HEIGHT } from 'PortfolioAnalytics/constants';

export const LeaseActivityToolbar = () => {
	const { filters, setFilters } = usePortfolioLeasesFilters();

	return (
		<Container>
			<strong>View:</strong>
			<RadioButton
				checked={filters.filterBy == null}
				onChange={() =>
					setFilters((prevFilters) => ({ ...prevFilters, filterBy: null }))
				}
			>
				All Leases
			</RadioButton>
			<CriterionRadioButton
				criterion="recentlySigned"
				filters={filters}
				setFilters={setFilters}
			>
				Recently Signed
			</CriterionRadioButton>
			<CriterionRadioButton
				criterion="recentlyUpdated"
				filters={filters}
				setFilters={setFilters}
			>
				Recently Updated
			</CriterionRadioButton>
			<CriterionRadioButton
				criterion="newOnCompstak"
				filters={filters}
				setFilters={setFilters}
			>
				New on Compstak
			</CriterionRadioButton>
			<Checkbox
				id="activeLeasesOnly"
				checked={filters.activeLeasesOnly}
				onClick={() =>
					setFilters((prevFilters) => ({
						...prevFilters,
						activeLeasesOnly: !prevFilters.activeLeasesOnly,
					}))
				}
			>
				Active Leases Only
			</Checkbox>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	padding: 0.5rem;
	min-height: ${ROW_HEIGHT}px;
`;
