import constate from 'constate';
import { useSelectionState } from 'utils/useSelectionState';
import { useListViewLeases } from './useListViewLeases';

type Props = {
	portfolioId: number;
};

export const [LeasesSelectionProvider, useLeasesSelectionContext] = constate(
	({ portfolioId }: Props) => {
		const { data: leases } = useListViewLeases({
			portfolioId,
		});

		return useSelectionState(leases);
	}
);
