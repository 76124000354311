import { VirtualTable } from '@compstak/ui-kit';
import { ExportCompsButton } from 'Components/Export';
import { NoDataTab } from 'Components/NoDataMessaging/NoDataMessaging';
import { LeaseActivityToolbar } from 'PortfolioAnalytics/LeaseActivity/Components/LeaseActivityToolbar';
import { usePortfolioLeases } from 'PortfolioAnalytics/LeaseActivity/PortfolioLeasesProvider';
import { usePortfolioLeasesColumns } from 'PortfolioAnalytics/LeaseActivity/usePortfolioLeasesColumns';
import { MAX_BODY_HEIGHT, ROW_HEIGHT } from 'PortfolioAnalytics/constants';
import { useNavigate } from 'react-router';
import { routes } from 'router';
import styled from 'styled-components';

export const LeaseActivityTab = () => {
	const {
		data: rows,
		isFetchingNextPage,
		isFetching,
		hasNextPage,
		fetchNextPage,
		selectionState: { selectedRows },
	} = usePortfolioLeases();
	const portfolioLeasesColumns = usePortfolioLeasesColumns();

	const navigate = useNavigate();

	return (
		<Container>
			<Title>Lease Activity</Title>
			<TableContainer>
				<LeaseActivityToolbar />
				{rows.length === 0 && (
					<NoDataTab
						minHeight={`${MAX_BODY_HEIGHT}px`}
						message="Try removing some filters or expanding the area of your search"
					/>
				)}
				{rows.length > 0 && (
					<VirtualTable
						id="lease-activity"
						rows={rows}
						columns={portfolioLeasesColumns}
						maxBodyHeight={MAX_BODY_HEIGHT}
						bodyRowHeight={ROW_HEIGHT}
						headerHeight={ROW_HEIGHT}
						isBodyHoverable
						getBodyCellProps={({ row }) => ({
							onClick: () => {
								navigate(routes.leaseById.toHref({ id: row.id }));
							},
						})}
						onLoadMore={() => {
							if (hasNextPage && !isFetchingNextPage) {
								fetchNextPage();
							}
						}}
						isLoadingMore={isFetching || isFetchingNextPage}
					/>
				)}
			</TableContainer>
			<div>
				<ExportCompsButton
					buttonType="table"
					compType="lease"
					comps={selectedRows}
				/>
			</div>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin: 1rem 0;
`;

const Title = styled.div`
	font-size: 1rem;
	font-weight: 500;
	text-transform: uppercase;
`;

const TableContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.white.white};
`;
