import { useQueries } from '@tanstack/react-query';
import { Submarket } from 'api/submarkets/useSubmarketsByMarket';
import { useSubmarketsByMarkets } from 'api/submarkets/useSubmarketsByMarkets';
import {
	DataSet,
	DataSetType,
	InsightData,
	Shape,
} from 'Pages/Analytics/analytics';
import { isPermittedDataset } from 'Pages/Analytics/Builder/chartBuilderHelpers';
import { usePermissions } from 'Pages/Login/reducers';
import { ApiQueryKeys } from 'Pages/PropertyPageV2_1/api/queryKeys';
import React from 'react';
import insightsService, {
	mufaInsightsService,
	salesInsightsService,
} from 'services/insights';
import spinner from 'ui/styles/spinner.less';
import { shapeToInsight, Insight } from './shapeToInsights';
import { SalesAttributeToPlotValues } from 'Pages/Analytics/Builder/chartBuilderConstants';
import { FULL_DATA_THRESHOLD } from 'Components/Graphs/constants';

function Spinner() {
	return <div className={spinner.large} />;
}

const BLANK_INSIGHT_DATA: InsightData = Object.freeze({
	count: 0,
	averages: {
		effectiveRent: 0,
		freeMonths: 0,
		leaseTerm: 0,
		startingRent: 0,
		ti: 0,
		transactionSize: 0,
		concessionsPercentage: 0,
		totalSalePrice: 0,
	},
	trendLine: [],
	summaries: [],
});

function shapeAndSubmarketsToInsight({
	shape,
	submarketsForShapeMarket,
}: {
	shape: Shape;
	submarketsForShapeMarket: Submarket[];
}): Insight {
	const insight = shapeToInsight(shape);

	if (!Array.isArray(submarketsForShapeMarket)) {
		console.error(
			'submarketsForShapeMarket should be an array!',
			submarketsForShapeMarket
		);
		return insight;
	}

	console.assert(
		submarketsForShapeMarket.every(
			(sm) => !sm?.marketId || sm?.marketId === shape?.filters?.market?.id
		),
		'submarkets should belong to shape market!?'
	);

	const filteredSubmarketsCount = shape?.filters?.submarkets?.length;
	if (
		typeof filteredSubmarketsCount === 'number' &&
		filteredSubmarketsCount >= submarketsForShapeMarket.length
	) {
		// Relevant ticket: https://compstak.atlassian.net/browse/AP-7976
		//
		// If a dataset has "all" selected in the submarket filters we should
		// treat this as if none of the submarkets were selected, because
		// there are leases/properties that are not assigned to any submarket
		// and backend (insightsService) discards these instances if we don't
		// remove those filters.
		insight.filter = insight.filter?.filter(
			(f) => f.property !== 'submarketId'
		);
	}
	return insight;
}

interface UseInsightsDataParams {
	shapes: Shape[];
	chartDraft: { dataSets?: DataSet[] };
}

export function useInsightsData({ shapes, chartDraft }: UseInsightsDataParams) {
	const permissions = usePermissions();

	const submarketsPerMarketsQueries = useSubmarketsByMarkets(
		shapes.flatMap((shape) =>
			shape.filters.markets ? shape.filters.markets : shape.filters.market
		)
	);

	const results = useQueries({
		queries: shapes.map((shape, idx) => {
			const submarketsForShapeMarketQuery = submarketsPerMarketsQueries[idx];
			if (submarketsForShapeMarketQuery.isLoading) {
				return { enabled: false };
			}
			const submarketsForShapeMarket = submarketsForShapeMarketQuery.data ?? [];

			const insight = shapeAndSubmarketsToInsight({
				shape,
				submarketsForShapeMarket,
			});
			const dataset = chartDraft?.dataSets?.[idx];
			const dataSetType = dataset?.type ?? DataSetType.COMMERCIAL;
			const key =
				insightsService.keyOf(insight) + `;dataSetType:${dataSetType}`;
			return {
				queryKey: [ApiQueryKeys.chartBuilderShapeLoader, key],
				queryFn: async (): Promise<InsightData> => {
					if (dataset && !isPermittedDataset(dataset, permissions)) {
						return { ...BLANK_INSIGHT_DATA, isIgnored: true };
					}
					if (dataSetType === DataSetType.MUFA) {
						// @ts-expect-error TS2322: Type 'unknown' is not assignab...
						return mufaInsightsService.load(insight);
					}
					if (dataSetType === DataSetType.SALES) {
						if (shape.y === SalesAttributeToPlotValues.CAP_RATE) {
							const [dataWithMaxThreshold, dataWith50Threshold] =
								await Promise.all([
									salesInsightsService.load(insight),
									salesInsightsService.load({
										...insight,
										fullDataThreshold: FULL_DATA_THRESHOLD,
									}),
								]);

							// To not show the few extreme points that distort the y axis
							// @ts-expect-error TS18046: 'dataWithMaxThreshold' is of t...
							const points = dataWithMaxThreshold.points.filter((p) => {
								return p.capRate != null && p.capRate >= -10 && p.capRate <= 10;
							});

							return {
								// @ts-expect-error TS2698: Spread types may only be creat...
								...dataWithMaxThreshold,
								points,
								// @ts-expect-error TS18046: 'dataWith50Threshold' is of ty...
								summaries: [...dataWith50Threshold.summaries],
								// @ts-expect-error TS18046: 'dataWith50Threshold' is of ty...
								trendLine: [...dataWith50Threshold.trendLine],
							};
						}
						// @ts-expect-error TS2322: Type 'unknown' is not assignab...
						return salesInsightsService.load(insight);
					}
					// @ts-expect-error TS2322: Type 'unknown' is not assignab...
					return insightsService.load(insight);
				},
				refetchOnWindowFocus: false,
			};
		}),
	});

	return results;
}

export default <Props extends UseInsightsDataParams>(
	GraphComponent: React.ComponentType<Props>,
	LoadingComponent = Spinner,
	extraOptions?: {
		allowErrorData: boolean;
	}
) => {
	return function ShapeLoaderV2(props: Props) {
		const results = useInsightsData(props);

		if (results.some((r) => r.isError) && !extraOptions?.allowErrorData) {
			console.error({
				errors: results.filter((r) => r.isError).map((r) => r?.error),
			});
			throw Error('ShapeLoaderV2Hoc error');
		}

		if (
			results.some(
				(r) =>
					(r.fetchStatus === 'idle' && r.status === 'loading') || r.isLoading
			)
		) {
			// @ts-expect-error TS2322: Type 'Props' is not assignable...
			return <LoadingComponent {...props} />;
		}

		const insights = results.map((r) => {
			if (r.isError) {
				return { ...BLANK_INSIGHT_DATA, isError: true };
			}
			return r.data as InsightData;
		});

		// TODO: Remove data and just keep insights and shapes.
		const data = insights.map((insight, i) => ({
			data: insight,
			shape: props.shapes[i],
		}));

		return <GraphComponent {...props} insights={insights} data={data} />;
	};
};

export function ChartBuilderContentSpinner() {
	return <div className={spinner.largeSpinnerV2} />;
}
