import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button } from '@compstak/ui-kit';
import { Portfolio } from 'api';
import { useState } from 'react';
import styled from 'styled-components';
import IconVerticalDots from 'ui/svg_icons/vertical-dots.svg';
import { CopyPortfolioDialog } from './CopyPortfolioDialog';
import { DeletePortfolioDialog } from './DeletePortfolioDialog';
import { PortfolioOptionsMenuV2 } from './PortfolioOptionsMenuV2';
import { UpdatePortfolioDialog } from './UpdatePortfolioDialog';

type Props = {
	portfolio: Portfolio;
};

export const PortfolioOptionsButtonV2 = ({ portfolio }: Props) => {
	const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
	const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

	return (
		<>
			<DropdownMenu.Root>
				<DropdownMenu.Trigger asChild>
					<OptionsButton variant={'ghost'} fitContent={true}>
						<IconVerticalDots />
					</OptionsButton>
				</DropdownMenu.Trigger>
				<PortfolioOptionsMenuV2
					openUpdateDialog={() => setIsUpdateDialogOpen(true)}
					openCopyDialog={() => setIsCopyDialogOpen(true)}
					openDeleteDialog={() => setIsDeleteDialogOpen(true)}
				/>
			</DropdownMenu.Root>
			{isUpdateDialogOpen && (
				<UpdatePortfolioDialog
					portfolio={portfolio}
					closeDialog={() => setIsUpdateDialogOpen(false)}
				/>
			)}
			{isCopyDialogOpen && (
				<CopyPortfolioDialog
					portfolio={portfolio}
					closeDialog={() => setIsCopyDialogOpen(false)}
				/>
			)}
			{isDeleteDialogOpen && (
				<DeletePortfolioDialog
					portfolioId={portfolio.id}
					closeDialog={() => setIsDeleteDialogOpen(false)}
				/>
			)}
		</>
	);
};

const OptionsButton = styled(Button)`
	display: flex;
	margin-top: 0.375rem;
	margin-left: 0.875rem;
	align-items: center;
	& :hover {
		background-color: ${(p) => p.theme.colors.gray.gray200};
	}
`;
