import { User } from '@compstak/common';
import { RecommendedSearch } from 'Pages/Home/Components/SearchBar/RecommendedSearches/useRecommendedSearches';
import { AllPortfoliosResponse } from 'api/portfolio/allPortfolios/useAllPortfoliosQuery';
import { Portfolio } from 'api/types';
import { MarketsState } from 'Pages/Login/reducers';
import { filtersToQueryString } from 'models/filters/util';

export function initializeSegment() {
	//* eslint-disable *//
	(window.analytics = window.analytics ?? []),
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'mehtods' does not exist on type 'analytics... Remove this comment to see the full error message
		(window.analytics.methods = [
			'identify',
			'group',
			'track',
			'page',
			'pageview',
			'alias',
			'ready',
			'on',
			'once',
			'off',
			'trackLink',
			'trackForm',
			'trackClick',
			'trackSubmit',
		]),
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'factory' does not exist on type 'analytics... Remove this comment to see the full error message
		(window.analytics.factory = function (t) {
			return function () {
				const a = Array.prototype.slice.call(arguments);
				// @ts-expect-error ts-migrate(2339) FIXME: Property 'analytics' does not exist on type 'Windo... Remove this comment to see the full error message
				return a.unshift(t), window.analytics.push(a), window.analytics;
			};
		});
	// @ts-expect-error ts-migrate(2339) FIXME: Property 'methods' does not exist on type 'analytics... Remove this comment to see the full error message
	for (let i = 0; i < window.analytics.methods.length; i++) {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'methods' does not exist on type 'analytics... Remove this comment to see the full error message
		const key = window.analytics.methods[i];
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'factory' does not exist on type 'analytics... Remove this comment to see the full error message
		window.analytics[key] = window.analytics.factory(key);
	}
	(window.analytics.load = function (t) {
		if (!document.getElementById('analytics-js')) {
			const a = document.createElement('script');
			(a.type = 'text/javascript'),
				(a.id = 'analytics-js'),
				(a.async = !0),
				(a.src =
					('https:' === document.location.protocol ? 'https://' : 'http://') +
					'cdn.segment.io/analytics.js/v1/' +
					t +
					'/analytics.min.js');
			const n = document.getElementsByTagName('script')[0];
			n.parentNode?.insertBefore(a, n);
		}
	}),
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'SNIPPET_VERSION' does not exist on type 'analytics... Remove this comment to see the full error message
		(window.analytics.SNIPPET_VERSION = '2.0.9');
	//* eslint-enable *//

	window.analytics.load(window.appConfig.segmentKey);
}

export function trackSegmentIdentify(user: User, markets: MarketsState) {
	if (!window.analytics) {
		return;
	}

	// @ts-expect-error ts-migrate(2339) FIXME: Property 'reduxTrackingRetrievalCalledOnce' does n... Remove this comment to see the full error message
	window.reduxTrackingRetrievalCalledOnce = true;
	const isOnboardingExperiment = !!user.experimentData;
	const onboardingData = user.experimentData || {};

	const userMarket = markets[user.primaryMarketId];

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { company, companyName, ...userTraits } = user;

	const traits = {
		// fields from user object
		...userTraits,

		// and custom data
		createdAt: user.unix_date_created,
		name: user.firstName + ' ' + user.lastName,
		isOnboardingExperiment: isOnboardingExperiment,
		// @ts-expect-error TS2339: Property 'startTime' does not ...
		promotionStartDate: onboardingData.startTime,
		// @ts-expect-error TS2339: Property 'expirationTime' does...
		promotionEndDate: onboardingData.expirationTime,
		// @ts-expect-error TS2339: Property 'numUnlocksToday' doe...
		numUnlocksToday: onboardingData.numUnlocksToday,
		// @ts-expect-error TS2339: Property 'totalUnlocks' does n...
		totalUnlocks: onboardingData.totalUnlocks,
		isBetaMarket:
			user.userType === 'enterprise'
				? userMarket.enterpriseBeta
				: userMarket.exchangeBeta,
		isSalesTrader: user.salesTrader,
		groupId:
			user.userType === 'enterprise' || user.userType === 'enterprise_trial'
				? user.enterpriseId
				: user.userType,
		company: {
			id: user.company,
			name: user.companyName,
		},
	};

	// hook in to the segment.io identification
	window.analytics.identify(String(user.id), traits, {
		integrations: {
			// intercom.io integration config object
			Intercom: {
				user_hash: user.user_hash,
			},
		},
	});

	trackSegmentPage();
}

let currentPath = '';
export function trackSegmentPage(path?: string) {
	if (!window.analytics) {
		return;
	}

	path = path ?? window.location.pathname;
	if (path === currentPath) {
		return;
	}
	currentPath = path;
	const splitPath = path.split('/');
	const name = splitPath[0] || splitPath[1];

	window.analytics.page(name, {
		path,
		// AP-14846: segment records page `path`, `search`, `url`, `referrer`, `title` by default
		// Segment requests max size payload is 32KB. Sometimes url is too long breaking the limit.
		// Thus don't record `search` since it's already in the `path`
		search: undefined,
	});
}

export function trackSegmentRecommendedSearch(
	recommendedSearch: RecommendedSearch
) {
	if (!window.analytics) {
		return;
	}

	const payload = {
		title: recommendedSearch.title,
		filters: filtersToQueryString(recommendedSearch.filters),
		filtersText: recommendedSearch.filtersText,
	};

	window.analytics.track('User Selected Recommended Search', payload);
}

type TrackCreatePortfolio = {
	existingPortfolios: AllPortfoliosResponse;
	newPortfolio: Portfolio;
	newPortfolioPropertyIds?: number[];
	duplicatePortfolio?: boolean;
};

export function trackSegmentCreatePortfolio({
	existingPortfolios,
	newPortfolio,
	newPortfolioPropertyIds,
	duplicatePortfolio,
}: TrackCreatePortfolio) {
	if (!window.analytics) {
		return;
	}
	const newPortfolioId = newPortfolio.id;
	const combinedPortfolios = [...existingPortfolios, newPortfolio];
	const portfolioCount = combinedPortfolios.length || 0;
	const portfolioIds = combinedPortfolios.map((p) => p.id);
	window.analytics.track('Portfolio Created', {
		newPortfolioId,
		newPortfolioPropertyIds: newPortfolioPropertyIds || [],
		portfolioCount,
		portfolioIds,
		duplicatePortfolio: duplicatePortfolio || false,
	});
}

export function trackSegmentAddPropertyToPortfolio(
	portfolioId: number,
	propertyIds: number[]
) {
	if (!window.analytics) {
		return;
	}
	const propertiesAddedToPortfolio = propertyIds;
	window.analytics.track('Properties Added to Portfolio', {
		portfolioId,
		propertiesAddedToPortfolio,
	});
}

export function logEventToSegment(
	event: string,
	eventData: Record<string, unknown>
) {
	// Events can be viewed in the segment dashboard:
	// https://app.segment.com/compstak/sources/ondemand-prod/debugger
	// https://app.segment.com/compstak/sources/kraken-prod/debugger
	const { analytics } = window;
	if (!analytics) return;
	// We are using "track" because that seems to be the best option in absence of a "log" method.
	// IMO calling this function trackEventInSegment might be more confusing than calling it logEventToSegment.
	analytics.track(event, eventData);
}
