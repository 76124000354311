import { MQB, colors } from '@compstak/ui-kit';
import IconLock from 'ui/svg_icons/lock.svg';
import styled, { css } from 'styled-components';

export const APP_CONTAINER_ID = 'app-container';
export const APP_WRAPPER_ID = 'app-wrapper';

export const DarkSidebar = styled.div<{ sideBarState: 'open' | 'closed' }>`
	display: flex;
	flex-direction: column;
	min-width: 300px;
	max-width: 300px;
	background-color: #303441;
	position: relative;
	overflow-y: auto;
	transition: transform 0.2s ease-in-out;
	height: 100%;

	${({ sideBarState }) =>
		sideBarState === 'closed' &&
		css`
			@media (max-width: ${MQB.T_834}) {
				position: absolute;
				top: 0;
				transform: translate(-300px);
				z-index: ${(props) => props.theme.zIndex.menu};
			}
		`}
`;

export const IconLockGrey = styled(IconLock)`
	fill: ${colors.neutral.n70};
	width: 13px;
	height: 15px;
`;
