import { PortfolioFilterQuery } from 'api';
import { Portfolio } from 'api/types/portfolio';
import { formatInteger, formatMoney } from 'format';
import { PortfolioHelpIcon } from 'PortfolioAnalytics/Singletons/PortfolioHelpIcon';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { dashWhenNoValue } from '../../../utils';
import { nullValueText } from '@compstak/common';
import { usePortfolioFilters } from '../../PortfolioFiltersProvider';
import { usePortfolioStats } from 'PortfolioAnalytics/usePortfolioStats';
import abbreviateNumber from 'ui/util/abbreviateNumber';

type Props = {
	portfolio: Portfolio;
};

export const AveragesV2 = ({ portfolio }: Props) => {
	const { filters } = usePortfolioFilters();
	const statsAndAveragesFields = useStatsAndAverages(portfolio, filters);

	return (
		<CardsRow>
			{allFieldsKeys.map((field) => {
				const fieldData = statsAndAveragesFields[field];
				return (
					<Card
						key={fieldData.title}
						columnSpanNarrow={fieldData.columnSpanNarrow}
						columnSpanMid={fieldData.columnSpanMid}
						columnSpanWide={fieldData.columnSpanWide}
					>
						<CardTitle>
							{fieldData.title}
							<PortfolioHelpIcon tooltip={fieldData.toolTipText} />
						</CardTitle>
						<CardValue>{fieldData.formattedValue}</CardValue>
					</Card>
				);
			})}
		</CardsRow>
	);
};

const statsKeys = [
	'marketsCount',
	'propertyCount',
	'totalSqFt',
	'activeLeaseCount',
	'expiringSqFt24Months',
] as const;

const averagesKeys = [
	'avgTransactionalSqFt',
	'avgCurrentRent',
	'avgNetEffectiveRent',
	'avgFreeRent',
	'avgLeaseTerm',
	'avgTIImprovement',
	'avgDiscountRentInPercents',
] as const;

const allFieldsKeys = [...statsKeys, ...averagesKeys];

type AverageKey = (typeof averagesKeys)[number];
type AllFiledsKey = (typeof allFieldsKeys)[number];

type StatsAndAveragesFieldsData = {
	[key in AllFiledsKey]: {
		formattedValue: ReturnType<typeof getFormattedValueForField>;
		toolTipText: ReactNode | string;
		title: string;
		columnSpanNarrow: number;
		columnSpanMid: number;
		columnSpanWide: number;
	};
};

const TOOLTIP_TEXT: Record<AllFiledsKey, string | JSX.Element> = {
	marketsCount: 'Number of Markets is the number of markets in the portfolio.',
	propertyCount:
		'Number of Properties is the sum of properties in each market in the portfolio. If an expiration date filter is applied, the property count will only include the number of properties with active leases.',
	totalSqFt:
		'Active Leased SqFt is the total number of active (non-expired) leases in the portfolio across all markets.',
	activeLeaseCount:
		'Total Active Leases is the total number of active (non-expired) leases in the portfolio across all markets.',
	expiringSqFt24Months: `24 Months Expiring SQFT is calculated as the sum of the square footage in active (non-expired) leases which have an expiration date in the next 24 months.`,
	avgTransactionalSqFt: `Average Transaction SQFT is calculated by taking the SQFT in each active (non-expired) lease and dividing by the number of leases`,
	avgCurrentRent: `Average Current Rent is calculated by taking the $/SF/YR in each active (non-expired) lease and dividing by the number of active leases.`,
	avgNetEffectiveRent: `Average Net Effective Rent is calculated by taking the $/SF/YR in each active (non-expired) lease and dividing by the number of active leases.`,
	avgFreeRent: `Average Free Rent is calculated by taking the number of months of free rent in each active (non-expired) lease and dividing by the number of active leases.`,
	avgLeaseTerm: `Average Lease Term is calculated by taking the lease term (in months) of each active (non-expired) lease and dividing by the number of active leases.`,
	avgTIImprovement: `Average Tenant Improvement Allowance is calculated by taking the $/SF/YR in each active (non-expired) lease and dividing by the number of active leases.`,
	avgDiscountRentInPercents: (
		<>
			<div>
				Average Rent Discount is calculated by taking the rate discount in each
				active lease and dividing by the number of active leases.
			</div>
			<div>
				Rent Discount on an individual lease is calculated as the % difference
				between Starting Rent and Effective Rent.
			</div>
		</>
	),
};

const TITLES: Record<AllFiledsKey, string> = {
	marketsCount: 'Markets',
	propertyCount: '# of Properties',
	totalSqFt: 'Active Leased SqFt',
	activeLeaseCount: 'Total Active Leases',
	expiringSqFt24Months: '24 Mo. Expiring SQFT',
	avgTransactionalSqFt: 'Avg. Transaction SQFT',
	avgCurrentRent: 'Avg. Current Rent',
	avgNetEffectiveRent: 'Avg. Net Effective Rent',
	avgFreeRent: 'Avg. Free Rent',
	avgLeaseTerm: 'Avg. Lease Term',
	avgTIImprovement: 'Avg. TI Allowance',
	avgDiscountRentInPercents: 'Avg. Rent Discount',
};

const cardWidths: Record<
	AllFiledsKey,
	{ columnSpanMid: number; columnSpanWide: number; columnSpanNarrow: number }
> = {
	marketsCount: { columnSpanMid: 4, columnSpanWide: 2, columnSpanNarrow: 5 },
	propertyCount: { columnSpanMid: 4, columnSpanWide: 3, columnSpanNarrow: 6 },
	totalSqFt: { columnSpanMid: 5, columnSpanWide: 4, columnSpanNarrow: 6 },
	activeLeaseCount: {
		columnSpanMid: 5,
		columnSpanWide: 5,
		columnSpanNarrow: 7,
	},
	expiringSqFt24Months: {
		columnSpanMid: 6,
		columnSpanWide: 5,
		columnSpanNarrow: 6,
	},
	avgTransactionalSqFt: {
		columnSpanMid: 6,
		columnSpanWide: 5,
		columnSpanNarrow: 6,
	},
	avgCurrentRent: { columnSpanMid: 6, columnSpanWide: 4, columnSpanNarrow: 6 },
	avgNetEffectiveRent: {
		columnSpanMid: 7,
		columnSpanWide: 5,
		columnSpanNarrow: 6,
	},
	avgFreeRent: { columnSpanMid: 5, columnSpanWide: 3, columnSpanNarrow: 5 },
	avgLeaseTerm: { columnSpanMid: 8, columnSpanWide: 4, columnSpanNarrow: 6 },
	avgTIImprovement: {
		columnSpanMid: 8,
		columnSpanWide: 4,
		columnSpanNarrow: 6,
	},
	avgDiscountRentInPercents: {
		columnSpanMid: 8,
		columnSpanWide: 4,
		columnSpanNarrow: 7,
	},
};

const useStatsAndAverages = (
	portfolio: Portfolio,
	filters: PortfolioFilterQuery
) => {
	const statsAndAveragesFieldsData = {} as StatsAndAveragesFieldsData;
	const statsValues = usePortfolioStats(portfolio, filters);
	const isAveragesKey = (key: string): key is AverageKey =>
		averagesKeys.includes(key as AverageKey);
	allFieldsKeys.map((key) => {
		const value = isAveragesKey(key)
			? getAverageForField(portfolio, key) || 0
			: statsValues[key];
		const formattedValue = getFormattedValueForField(key, value);
		statsAndAveragesFieldsData[key] = {
			formattedValue,
			toolTipText: TOOLTIP_TEXT[key],
			title: TITLES[key],
			columnSpanNarrow: cardWidths[key].columnSpanNarrow,
			columnSpanMid: cardWidths[key].columnSpanMid,
			columnSpanWide: cardWidths[key].columnSpanWide,
		};
	});
	return statsAndAveragesFieldsData;
};

const getAverageForField = (portfolio: Portfolio, field: AverageKey) => {
	const marketsWithValue = portfolio.markets.filter((m) => m[field] != null);

	if (marketsWithValue.length === 0) return null;

	const totalActiveLeaseCountInAllMarkets = marketsWithValue.reduce(
		(total, m) => {
			return total + m.activeLeaseCount;
		},
		0
	);

	if (totalActiveLeaseCountInAllMarkets === 0) return null;

	let totalForField = 0;

	marketsWithValue.map(
		(market) => (totalForField += market[field] * market.activeLeaseCount)
	);
	return totalForField / totalActiveLeaseCountInAllMarkets;
};

const getFormattedValueForField = (field: AllFiledsKey, value: number) => {
	switch (field) {
		case 'expiringSqFt24Months':
		case 'avgTransactionalSqFt':
			return dashWhenNoValue(value, formatInteger);
		case 'avgCurrentRent':
		case 'avgNetEffectiveRent':
		case 'avgTIImprovement':
			return dashWhenNoValue(value, formatMoney);
		case 'avgFreeRent':
		case 'avgLeaseTerm':
			return dashWhenNoValue(value, formatMonths);
		case 'avgDiscountRentInPercents': {
			const numberOrDash = dashWhenNoValue(value, (v) => v.toFixed(1));
			return numberOrDash === nullValueText ? numberOrDash : `${numberOrDash}%`;
		}
		default:
			return dashWhenNoValue(value, abbreviateNumber);
	}
};

const formatMonths = (months: number) =>
	months > 0 && `${months.toFixed(1)} mo`;

const CardsRow = styled.div`
	display: grid;
	grid-template-columns: auto;
	gap: 0.5rem;
	grid-template-columns: repeat(24, 1fr);
	margin-bottom: 1rem;
`;

const Card = styled.div<{
	columnSpanMid: number;
	columnSpanWide: number;
	columnSpanNarrow: number;
}>`
	display: flex;
	flex-direction: column;
	padding: 16px;
	align-items: flex-start;
	gap: 0.5rem;
	height: 90px;
	border-radius: 0.5rem;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray100};
	background: ${({ theme }) => theme.colors.white.white};
	box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
	grid-column: span ${({ columnSpanNarrow }) => columnSpanNarrow};

	${({ theme }) => theme.breakpoints.up('D_1536')} {
		padding: 20px;
		height: 105px;
		grid-column: span ${({ columnSpanMid }) => columnSpanMid};
	}

	${({ theme }) => theme.breakpoints.up('D_1920')} {
		grid-column: span ${({ columnSpanWide }) => columnSpanWide};
	}
`;

const CardTitle = styled.div`
	display: flex;
	gap: 0.5rem;
	color: #667085;
	font-size: 0.875rem;
	font-weight: 325;
	line-height: 1rem;
	letter-spacing: 0.08px;

	${({ theme }) => theme.breakpoints.up('D_1536')} {
		font-size: 1rem;
		line-height: 1.5rem;
	}
`;

const CardValue = styled.div`
	font-size: 1.25rem;
	font-weight: 400;
	color: #333843;
	font-weight: 500;
	line-height: 1.75rem;
	letter-spacing: 0.12px;

	${({ theme }) => theme.breakpoints.up('D_1536')} {
		line-height: 2rem;
	}
`;
