import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { Portfolio, PortfolioFilterQuery } from '../../types';
import { composeEnabled, createFetchFn } from '../../utils';
import { getQueryStringFromPortfolioFilters } from 'util/getQueryStringsFromPortfolioFilter';

const fetchPortfolioById = createFetchFn<
	PortfolioByIdParams,
	PortfolioByIdResponse
>({
	method: 'get',
	getURL: ({ id, filters }) => {
		let url = `/pa/v2/api/portfolios/${id}`;

		if (filters) {
			url += `/${getQueryStringFromPortfolioFilters(filters)}`;
		}

		return url;
	},
});

export const usePortfolioByIdQuery = (
	params: PortfolioByIdParams,
	options?: UseQueryOptions<PortfolioByIdResponse>
) =>
	useQuery({
		queryKey: [QUERY_KEYS.PORTFOLIO_BY_ID, params.id, params.filters],
		queryFn: fetchPortfolioById(params),
		...options,
		enabled: composeEnabled(params.id != null, options?.enabled),
	});

export type PortfolioByIdParams = {
	id: number | undefined;
	filters?: PortfolioFilterQuery;
};

export type PortfolioByIdResponse = Portfolio;
