import { PROPERTY_TYPE_ID_TO_NAME, PropertyTypeId } from 'api';
import { usePortfolioFilters } from '../../../PortfolioFiltersProvider';
import { CheckboxFilter } from './CheckboxFilter';
import { FilterOption } from '../types';

export const PropertyTypeFilter = () => {
	const { filters, setFilters } = usePortfolioFilters();

	return (
		<CheckboxFilter
			label="Property Type"
			emptyValueLabel="All Types"
			options={OPTIONS}
			value={filters.propertyTypeIds}
			onChange={(value) => {
				setFilters((s) => ({
					...s,
					propertyTypeIds: value,
				}));
			}}
			buttonProps={{
				style: {
					width: 140,
				},
			}}
		/>
	);
};

const OPTIONS: FilterOption<PropertyTypeId>[] = Object.entries(
	PROPERTY_TYPE_ID_TO_NAME
).reduce<FilterOption<PropertyTypeId>[]>((o, [id, name]) => {
	o.push({
		label: name,
		value: Number(id) as PropertyTypeId,
	});
	return o;
}, []);
