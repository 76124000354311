import styled from 'styled-components';
import { Button } from '@compstak/ui-kit';
import { usePortfolioFilters } from '../../../PortfolioFiltersProvider';
import { BuildingClassFilter } from './BuildingClassFilter';
import { LeaseExpirationFilter } from './LeaseExpirationFilter';
import { PropertyTypeFilter } from './PropertyTypeFilter';
import { SubmarketFilter } from './SubmarketFilter';
import { BuildingSizeFilter } from './BuildingSizeFilter';

type Props = {
	portfolioId: number;
};

export const PortfolioFiltersV2 = ({ portfolioId }: Props) => {
	const { resetFilters } = usePortfolioFilters();

	return (
		<Root>
			<FiltersContainer>
				<SubmarketFilter portfolioId={portfolioId} />
				<PropertyTypeFilter />
				<BuildingClassFilter />
				<BuildingSizeFilter
					label="Building Size"
					emptyValueLabel="All"
					buttonProps={{
						style: { width: 124 },
					}}
				/>
				<LeaseExpirationFilter />
			</FiltersContainer>
			<ResetFiltersButton variant="ghost" onClick={resetFilters}>
				Reset filters
			</ResetFiltersButton>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	align-items: center;
	column-gap: 36px;
	row-gap: 8px;
`;

const FiltersContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
`;

const ResetFiltersButton = styled(Button)`
	color: ${(p) => p.theme.colors.blue.blue500};
	font-size: 0.75rem;
	text-transform: uppercase;
	font-weight: 325;
	line-height: 1.125rem;
	letter-spacing: 0.3px;
	height: 44px;
	&:hover {
		background-color: ${(p) => p.theme.colors.gray.gray400TransparentCC};
	}
`;
