import { useUser } from 'Pages/Login/reducers';
import { ChangeEvent, MouseEvent, useState } from 'react';
import { domainsMatch, validateEmail } from 'util/validate';
import './send-to-colleague.nomodule.less';
import SendToColleagueInput from './ShareWithColleagueInput';
import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from '../common/UI';
import { useModal } from 'providers/ModalProvider';
import styled from 'styled-components';

type Props = {
	itemName: string;
	modalTitle: string;
	buttonText: string;
	action: (emails: string[]) => void;
};

export const ShareWithColleagueModal = (props: Props) => {
	const [emails, setEmails] = useState([
		{
			text: '',
			class: '',
		},
	]);
	const [badSubmitClass, setBadSubmitClass] = useState('');
	const [thankYou, setThankYou] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const user = useUser();
	const { closeModal } = useModal();

	const hasDuplicates = (emailList: string[]) => {
		return new Set(emailList).size !== emailList.length;
	};
	const isEmailFromCurrentUser = (value: string) =>
		user.email.toLowerCase() === value.toLowerCase();

	const hasEmptyEmails = (email: string) => !email;

	const handleChange =
		(idx: number) => (event: ChangeEvent<HTMLInputElement>) => {
			const isValid = validateEmail(event.target.value);
			const currentUserEmailMatch = isEmailFromCurrentUser(event.target.value);

			let classMessage;
			if (isValid && event.target.value && !currentUserEmailMatch) {
				classMessage = 'has-content';
			} else if (!isValid || currentUserEmailMatch) {
				classMessage = 'invalid';
			} else {
				classMessage = 'is-ok-anyway';
			}

			const updatedEmails = emails.slice();
			updatedEmails[idx] = {
				text: event.target.value,
				class: classMessage,
			};

			setEmails(updatedEmails);
			setErrorMessage('');
		};

	const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const submittedEmails = emails.map((email) => email.text);
		if (submittedEmails.some(hasEmptyEmails)) {
			setErrorMessage(`There should be no empty fields`);
		} else if (hasDuplicates(submittedEmails)) {
			setErrorMessage('Duplicate email');
		} else if (
			!submittedEmails.every(validateEmail) ||
			!submittedEmails.every((email) => domainsMatch(email, user))
		) {
			const domain = user.email.substr(user.email.indexOf('@') + 1);
			setBadSubmitClass('flash-invalid');
			setErrorMessage(
				`Email domain must match your account's email domain (@${domain})`
			);
		} else if (submittedEmails.some(isEmailFromCurrentUser)) {
			setErrorMessage(`You can't use your own email`);
		} else {
			props.action(submittedEmails);
			setThankYou(true);
		}
	};

	const addEmail = () => {
		const updatedEmailList = emails.slice();
		updatedEmailList.push({
			text: '',
			class: '',
		});
		setEmails(updatedEmailList);
	};

	const removeEmail = (idx: number) => {
		const updatedEmailList = emails.slice();
		updatedEmailList.splice(idx, 1);
		setEmails(updatedEmailList);
	};

	if (thankYou) {
		return (
			<div className={`send-to-colleague ${badSubmitClass}`}>
				<ModalTitle>Successfully sent to:</ModalTitle>
				<ul>
					{emails.map((email, idx) => (
						<li key={idx}>{email.text}</li>
					))}
				</ul>
				<ModalButtons>
					<ModalButton
						variant="primary"
						onClick={() => closeModal()}
						dataTestId="send-search-done-button"
					>
						DONE
					</ModalButton>
				</ModalButtons>
			</div>
		);
	} else {
		return (
			<div className={`send-to-colleague ${badSubmitClass}`}>
				<ModalTitle>{props.modalTitle}</ModalTitle>
				<ModalParagraph>
					Enter your colleagues' emails below to send them{' '}
					<b>{props.itemName}</b>
				</ModalParagraph>
				<RecipientsLabel>Recipients' Email Addresses:</RecipientsLabel>
				<div className="email-list">
					{emails.map((email, idx) => (
						<SendToColleagueInput
							key={idx}
							idx={idx}
							email={email}
							handleChange={handleChange}
							addEmail={addEmail}
							removeEmail={removeEmail}
						/>
					))}
				</div>
				<WarningMessage>{errorMessage}</WarningMessage>
				<ModalButtons>
					<ModalButton
						variant="primary"
						dataTestId="send-search-submit-button"
						onClick={handleSubmit}
					>
						{props.buttonText}
					</ModalButton>
				</ModalButtons>
			</div>
		);
	}
};

const RecipientsLabel = styled.label`
	color: ${({ theme }) => theme.colors.blue.blue400};
`;

const WarningMessage = styled.div`
	color: ${({ theme }) => theme.colors.red.red500};
`;
