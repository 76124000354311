import { SimpleTable } from '@compstak/ui-kit';
import { PropertyLease } from 'api';
import { useNavigate } from 'router/migration';
import { routes } from 'router/routes';
import { useLeasesTableColumns } from '../useLeasesTableColumns';
import { useLeasesSelectionContext } from './LeasesSelectionProvider';
import { UseUnlockCompsProps } from 'Components/Unlock/useUnlockComps';

type Props = {
	leases: PropertyLease[];
} & Pick<UseUnlockCompsProps, 'onUnlockLeasesSuccess'>;

export const LeasesTable = ({ leases, onUnlockLeasesSuccess }: Props) => {
	const navigate = useNavigate();
	const selection = useLeasesSelectionContext();
	const columns = useLeasesTableColumns({
		selection,
		onUnlockLeasesSuccess,
	});

	return (
		<SimpleTable
			id="lease"
			rows={leases}
			columns={columns}
			isBodyHoverable
			scrollContainerProps={{
				style: { maxHeight: MAX_BODY_HEIGHT, textAlign: 'right' },
			}}
			renderBodyCellContent={({ columnRender }) => {
				return columnRender();
			}}
			getBodyCellProps={({ row }) => ({
				onClick: () => {
					navigate(routes.leaseById.toHref({ id: row.id }), {
						state: {
							fromNewPropertyPage: true,
						},
					});
				},
			})}
		/>
	);
};

const MAX_BODY_HEIGHT = 586;
