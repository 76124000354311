import {
	Pending,
	RowFlexContainer,
	Spinner,
	Tabs as UIKitTabs,
} from '@compstak/ui-kit';
import {
	AddToPortfolioButton,
	SearchPropertiesNavHeader,
	useSearchPropertiesNavHeaderContext,
} from 'Components';
import { MultiMarketUpgradeModal } from 'Components/Modals/UpgradeModal/MultiMarketUpgradeModal';
import { Modal } from 'Components/Modals/common/UI';
import { usePermissions } from 'Pages/Login/reducers';
import { usePropertyById } from 'api';
import { MQB } from 'constants/mediaQueryBreakpoints';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { routes, useNavigate } from 'router';
import styled, { CSSProperties } from 'styled-components';
import { APP_HEADER_HEIGHT } from '../../constants';
import { CompstakEstimates } from './CompstakEstimates';
import { ExportButton } from './ExportPdf';
import { LeaseProvider } from './LeasesTab/LeaseProvider';
import { MainInfo, PropertyTitle } from './MainInfo';
import { MarketStats } from './MarketStats/MarketStats';
import { PropertyLeaseSnapshot } from './PropertyLeaseSnapshot';
import { SubmarketTrendsProvider } from './SubmarketTrendsTab/SubmarketTrendsProvider';
import { TabsContent, TabsList, useTabContext } from './Tabs';
import { PropertyPageTab } from './types';
import { isMarketAccessible } from 'util/marketAccessUtils';
import { usePropertyPageData } from './hooks/usePropertyPageData';
import { MarketStatsFiltersProvider } from './MarketStats/MarketStatsFiltersProvider';

export const PropertyPage = () => {
	const params = routes.propertyById.useParams();
	const location = useLocation();

	const { tab, setTab, scrollToTabs } = useTabContext();

	const [isMarketStatsCollapsed, setIsMarketStatsCollapsed] = useState(false);

	useEffect(() => {
		setIsMarketStatsCollapsed(false);
	}, [params.propertyId]);

	useEffect(() => {
		if (location.state?.scrollToLeases) {
			setTab(PropertyPageTab.LEASES);
			scrollToTabs();
		}
	}, [location.state]);

	const { data: property, isLoading: isLoadingProperty } = usePropertyById({
		propertyId: params.propertyId,
	});

	const permissions = usePermissions();
	const navigate = useNavigate();

	const { getIsNavHeaderActive } = useSearchPropertiesNavHeaderContext();

	const isNavHeaderActive = getIsNavHeaderActive();

	const flags = useFeatureFlags();

	const { summary, compset, compstakEstimates, isLoading } =
		usePropertyPageData();

	const isMarketStatsShown = flags.PropertyPagePhase3;

	const gridStyle: CSSProperties =
		isMarketStatsShown && !isMarketStatsCollapsed
			? {
					gridTemplateAreas: `'${GRID_AREA.main} ${GRID_AREA.main} ${GRID_AREA.main} ${GRID_AREA.right}'
				'${GRID_AREA.tabs} ${GRID_AREA.tabs} ${GRID_AREA.tabs} ${GRID_AREA.right}'
`,
				}
			: {
					gridTemplateAreas: `'${GRID_AREA.main} ${GRID_AREA.main} ${GRID_AREA.main} ${GRID_AREA.right}'
			'${GRID_AREA.tabs} ${GRID_AREA.tabs} ${GRID_AREA.tabs} ${GRID_AREA.tabs}'
`,
				};

	return (
		<Container>
			{isNavHeaderActive && <SearchPropertiesNavHeader />}
			<Content>
				{!property || isLoadingProperty ? (
					<SpinnerContainer>
						<Spinner size="xl" />
					</SpinnerContainer>
				) : (
					<>
						{!isMarketAccessible(property.marketId, 'property', permissions) ? (
							<Modal onClose={() => navigate(routes.searchPropertiesHome.path)}>
								<MultiMarketUpgradeModal
									compType="property"
									selectedMarkets={[property.marketId]}
									onClose={() => navigate(routes.searchPropertiesHome.path)}
								/>
							</Modal>
						) : (
							<SubmarketTrendsProvider propertyId={property.id}>
								<MarketStatsFiltersProvider>
									<LeaseProvider>
										<UIKitTabs
											value={tab}
											onValueChange={(value) => {
												setTab(value as PropertyPageTab);
											}}
										>
											<TopContainer>
												<PropertyTitle property={property} />
												<RowFlexContainer gap="0.5rem">
													<AddToPortfolioButton propertyIds={[property.id]} />

													{flags.PropertyPageExport && (
														<ExportButtonContainer>
															<ExportButton />
														</ExportButtonContainer>
													)}
												</RowFlexContainer>
											</TopContainer>
											<Grid style={gridStyle}>
												<MainInfoContainer
													isShowingEstimates={
														compstakEstimates.showCompstakEstimates
													}
												>
													<MainInfo />
													{compstakEstimates.showCompstakEstimates && (
														<GreyLine />
													)}
													<TabsList />
												</MainInfoContainer>
												<RightContainer>
													{isLoading ? (
														<StyledPending />
													) : (
														<>
															<PropertyLeaseSnapshot
																summary={summary}
																compset={compset}
															/>
															{compstakEstimates.showCompstakEstimates && (
																<CompstakEstimates
																	startingRent={compstakEstimates.startingRent}
																	effectiveRent={
																		compstakEstimates.effectiveRent
																	}
																/>
															)}
															{isMarketStatsShown && (
																<MarketStats
																	isMarketStatsCollapsed={
																		isMarketStatsCollapsed
																	}
																	setIsMarketStatsCollapsed={
																		setIsMarketStatsCollapsed
																	}
																/>
															)}
														</>
													)}
												</RightContainer>
												<TabsContentContainer
													key={`tabs${isMarketStatsCollapsed.toString()}`}
												>
													<TabsContent />
												</TabsContentContainer>
											</Grid>
										</UIKitTabs>
									</LeaseProvider>
								</MarketStatsFiltersProvider>
							</SubmarketTrendsProvider>
						)}
					</>
				)}
			</Content>
		</Container>
	);
};

const GRID_AREA = {
	main: 'main',
	right: 'right',
	tabs: 'tabs',
};

const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100vh - ${APP_HEADER_HEIGHT}px);
	background-color: ${(p) => p.theme.colors.white.white};
	overflow-y: scroll;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	color: ${(p) => p.theme.colors.gray.gray700};
	* {
		box-sizing: border-box;
	}
`;

const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Content = styled.div`
	padding: 1rem 1.5rem;
`;

const TopContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`;

const Grid = styled.div`
	display: grid;
	transition: none;
	grid-template-columns: 1fr 1fr 1fr minmax(240px, 400px);
	grid-template-rows: minmax(400px, auto) auto;
	column-gap: 1.5rem;
	@media (min-width: ${MQB.D_1920}px) {
		gap: 2rem;
	}
`;

const RightContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	margin-bottom: 1rem;
	grid-area: ${GRID_AREA.right};
`;

const ExportButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const MainInfoContainer = styled.div<{ isShowingEstimates: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: ${({ isShowingEstimates }) => (isShowingEstimates ? '1.5rem' : '3rem')};
	grid-area: ${GRID_AREA.main};
`;

const GreyLine = styled.div`
	border-top: 1px solid ${({ theme }) => theme.colors.neutral.n30};
`;

const TabsContentContainer = styled.div`
	grid-area: ${GRID_AREA.tabs};
	overflow-x: auto;
	flex: 1;
`;

const StyledPending = styled(Pending)`
	height: 100%;
`;
