import {
	ModalButton,
	ModalButtons,
	ModalInputLabel,
	ModalInputMessage,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { Chart, Project } from 'Pages/Analytics/analytics';
import { useModal } from 'providers/ModalProvider';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { saveChart as saveChartAction } from '../../actions';
import { useMarkets } from 'hooks/useMarkets';

type Props = {
	chart: Chart;
	project: Project;
};

export const DuplicateChartModal = ({ chart, project }: Props) => {
	const { closeModal } = useModal();
	const markets = useMarkets();
	const dispatch = useDispatch();

	const [newName, setNewName] = useState(() => {
		const nameWithoutCopy = chart.title?.replace(/ Copy (\d+)$/i, '');

		const highestCopyNumber = project.charts.reduce((acc, chart) => {
			if (nameWithoutCopy && chart.title?.startsWith(nameWithoutCopy)) {
				const match = chart.title.match(/Copy (\d+)$/i);
				if (match !== null) {
					const number = parseInt(match[1]);
					return Math.max(number, acc);
				}
				return Math.max(acc, 1);
			}
			return acc;
		}, 0);

		return `${nameWithoutCopy} Copy ${highestCopyNumber + 1}`;
	});

	const [errorMessage, setErrorMessage] = useState('');

	const updateName = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewName(event.target.value);
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			saveChart();
		}
	};

	const saveChart = () => {
		const trimmedName = newName.trim();
		if (project.charts.some((c) => c.title === trimmedName)) {
			setErrorMessage(
				`The chart ${trimmedName} already exists. Enter a new name to continue.`
			);
		} else {
			const newChart = {
				...chart,
				title: newName,
				id: null,
			};

			dispatch(saveChartAction(newChart, markets));
			closeModal();
		}
	};

	return (
		<>
			<ModalTitle>Duplicate Chart</ModalTitle>
			<ModalParagraph>
				Duplicating this chart will create a copy: <b>{newName}.</b>
			</ModalParagraph>
			<ModalParagraph>Change the title below, if you want to.</ModalParagraph>
			<div>
				<ModalInputLabel isError={!!errorMessage}>
					Chart Name
					<input
						type="text"
						value={newName}
						onChange={updateName}
						onKeyDown={handleKeyDown}
					/>
					{!!errorMessage && (
						<ModalInputMessage>{errorMessage}</ModalInputMessage>
					)}
				</ModalInputLabel>

				<ModalButtons>
					<ModalButton onClick={closeModal} variant="secondary">
						Cancel
					</ModalButton>
					<ModalButton onClick={saveChart}>Duplicate</ModalButton>
				</ModalButtons>
			</div>
		</>
	);
};
