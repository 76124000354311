import React from 'react';
import * as Styled from './styled';
import { styled } from 'styled-components';

import ArrowBottom from '../../../../ui/svg_icons/arrow_bottom.svg';
import useOnClickOutside from '../../../../util/useOnClickOutside';

export type DropdownItem<T extends number | string> = { id: T; value: string };

type Props<T extends number | string> = {
	activeItemId: null | DropdownItem<T>['id'];
	items: Array<DropdownItem<T>>;
	onSelect: (itemId: DropdownItem<T>['id']) => void;
	title?: string;
	width?: number;
};

const Dropdown = <T extends number | string>({
	activeItemId,
	items,
	onSelect,
	title,
	width,
}: Props<T>) => {
	const dropdownMenuRef = React.useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = React.useState(false);

	const close = () => setIsOpen(false);

	const handleClick = () => setIsOpen((stateIsOpen) => !stateIsOpen);

	useOnClickOutside(dropdownMenuRef, close);

	React.useEffect(() => {
		return close;
	}, []);

	const onSelectItem = (item: DropdownItem<T>) => {
		const itemId = item.id;
		if (activeItemId !== itemId) {
			onSelect(itemId);
		}
		close();
	};

	const activeItem = items.find((item) => item.id === activeItemId);
	const activeItemLabel = activeItem ? activeItem.value : '';

	return (
		<Styled.Wrapper width={width}>
			<Styled.Selector
				ref={dropdownMenuRef}
				id="selector"
				data-qa-id="qa-id-7548393"
				onClick={handleClick}
			>
				<Styled.SelectorContentWrapper>
					{title && <SelectorTitle>{title}</SelectorTitle>}
					<SelectorLabel>{activeItemLabel}</SelectorLabel>
				</Styled.SelectorContentWrapper>
				<Styled.SelectorIconWrapper isOpen={isOpen}>
					<ArrowBottom width={10} />
				</Styled.SelectorIconWrapper>
			</Styled.Selector>
			{isOpen && (
				<Styled.ListWrapper>
					{items.map((item) => (
						<ListItem
							data-qa-id="qa-id-8585859590"
							key={item.id}
							onClick={() => onSelectItem(item)}
						>
							<Styled.ListItemText active={activeItemId === item.id}>
								{item.value}
							</Styled.ListItemText>
						</ListItem>
					))}
				</Styled.ListWrapper>
			)}
		</Styled.Wrapper>
	);
};

const ListItem = styled(Styled.ListItem)`
	padding: 10px 20px;
	font-size: 12px;
`;

const SelectorTitle = styled(Styled.SelectorTitle)`
	font-size: 12px;
`;

const SelectorLabel = styled(Styled.SelectorLabel)`
	font-size: 12px;
`;

export default Dropdown;
