import { formatMoney } from 'format';
import { BlueBoxLabel } from '../UI';
import { CompsetLabel } from './CompsetLabel';
import { MarketLabel } from './MarketLabel';
import { MarketStatsChart } from './MarketStatsChart';
import { SubmarketLabel } from './SubmarketLabel';
import { useMarketStatsChartData } from './useMarketStatsChartData';

export const TenantImprovementMarketStats = () => {
	const { data, isFetching } = useMarketStatsChartData({ metric: 'ti' });

	return (
		<div>
			<BlueBoxLabel>Tenant Improvement (PSF)</BlueBoxLabel>
			<MarketStatsChart
				items={[
					{ label: 'Subj. Property', middle: data?.leases.middle },
					{
						label: <CompsetLabel />,
						...data?.compset,
					},
					{ label: <SubmarketLabel />, ...data?.submarket },
					{ label: <MarketLabel />, ...data?.market },
				]}
				formatValue={formatMoney}
				formatXTick={(v) => formatMoney(v, { decimalPlaces: 0 })}
				isLoading={isFetching}
			/>
		</div>
	);
};
