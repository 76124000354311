import { Flex, Spinner } from '@compstak/ui-kit';
import * as Tabs from '@radix-ui/react-tabs';
import { ExportCompsButton } from 'Components/Export';
import { UnlockButton } from 'Components/Unlock';
import { UnlockButtonTooltip } from 'Components/Unlock/UnlockButtonTooltip';
import {
	OnUnlockLeasesSuccessFn,
	QUERY_KEYS,
	usePropertyById,
	usePropertyLeasesQuery,
	usePropertyLeasesSummaryQuery,
	useUnlockConditions,
	useUnlockLeasesMutation,
} from 'api';
import { useIsExchange } from 'hooks';
import { useCallback, useEffect, useMemo } from 'react';
import { routes, useLocation } from 'router';
import styled from 'styled-components';
import { NoDataTab } from '../../../Components/NoDataMessaging/NoDataMessaging';
import {
	NestedTab,
	TabContainer,
	TabContentTitle,
	TableActionsContainer,
	TableContainer,
	TabsList,
} from '../UI';
import { UploadCompButton } from '../UploadCompButton';
import { RadioButtonGroup } from './Components';
import { LeasesTabEnum, useLeaseContext } from './LeaseProvider';
import { useLeasesSelectionContext } from './LeasesSelectionProvider';
import { LeasesTable } from './LeasesTable';
import { BlueLink } from 'Components';
import { getIsLeaseCompActive } from 'utils/getIsLeaseCompActive';
import { useUnlockButtonLongText } from 'Components/Unlock/useUnlockButtonLongText';
import { usePropertyLeasesUrl } from '../hooks/propertyLeases';
import { MIN_TAB_HEIGHT } from '../constants';

export const LeasesTab = () => {
	const isExchange = useIsExchange();
	const {
		tab,
		setTab,
		leases,
		ownedLeases,
		filteredLeases,
		checkedSpaceType,
		checkedStatus,
		leasesGrouppedBySpaceType,
		leasesByStatus,
		setCheckedSpaceType,
		setCheckedStatus,
		leasesActive,
	} = useLeaseContext();
	const params = routes.propertyById.useParams();

	const { data: leasesData, isLoading } = usePropertyLeasesQuery({
		propertyId: params.propertyId,
	});

	const location = useLocation();

	useEffect(() => {
		if (
			!location.state?.scrollToLeases &&
			!isLoading &&
			leasesActive.length > 0
		)
			setCheckedStatus('Only Active Leases');
	}, [setCheckedStatus, isLoading, location]);

	const { data: summary } = usePropertyLeasesSummaryQuery(
		params.propertyId,
		isExchange
	);

	const { selectedRows } = useLeasesSelectionContext();

	const unlockedPropertyLeases = useMemo(
		() => selectedRows.filter((row) => row.own),
		[selectedRows]
	);

	const lockedPropertyLeases = useMemo(
		() => selectedRows.filter((row) => !row.own),
		[selectedRows]
	);

	const unlockButtonText = useUnlockButtonLongText({
		comps: lockedPropertyLeases,
	});

	const onUnlockSuccess = useCallback<OnUnlockLeasesSuccessFn>(
		({ data, queryClient }) => {
			if (
				isExchange &&
				summary?.activeLeaseCount === 1 &&
				data.comps.find((lease) => getIsLeaseCompActive(lease))
			) {
				queryClient.invalidateQueries([
					QUERY_KEYS.PROPERTY_LEASES_SUMMARY,
					params.propertyId,
				]);
			}
		},
		[isExchange, summary, params.propertyId]
	);

	const { mutate: unlockLeases, isLoading: isUnlocking } =
		useUnlockLeasesMutation({
			onSuccess: onUnlockSuccess,
		});

	const { hasEnoughPoints, isUserFetching } = useUnlockConditions({
		comps: lockedPropertyLeases,
	});

	const { data: property } = usePropertyById({
		propertyId: params.propertyId,
	});

	const leasesUrl = usePropertyLeasesUrl({
		propertyId: params.propertyId,
		addresses: [property!.buildingAddressAndCity],
		checkedSpaceType,
		checkedStatus,
	});

	if (isLoading)
		return (
			<TabContainer>
				<Spinner isCentered />
			</TabContainer>
		);

	// @ts-expect-error leasesData' is possibly 'undefined'
	const hasLeasesAtAll = leasesData?.comps.length > 0;

	if (!hasLeasesAtAll) {
		return (
			<NoDataTab
				minHeight={`${MIN_TAB_HEIGHT}px`}
				message={
					<>
						<div>
							CompStak doesn't have any Leases for this property at the moment.
						</div>
						{isExchange && (
							<div>
								Be the first to report a lease for this property and earn an
								extra FREE comp!
							</div>
						)}
					</>
				}
				action={
					isExchange && (
						<UploadCompButton section="lease">
							upload lease comp
						</UploadCompButton>
					)
				}
			/>
		);
	}

	const hasTabLeases =
		tab === LeasesTabEnum.ALL_LEASES
			? leases.length > 0
			: ownedLeases.length > 0;
	const hasFilteredLeases = filteredLeases.length > 0;

	return (
		<TabContainer>
			<TableContainer>
				{isExchange && (
					<Tabs.Root
						value={tab}
						onValueChange={(value) => setTab(value as LeasesTabEnum)}
					>
						<TabsList>
							<NestedTab value={LeasesTabEnum.UNLOCKED_LEASES}>
								Unlocked Leases
							</NestedTab>
							<NestedTab value={LeasesTabEnum.ALL_LEASES}>All leases</NestedTab>
						</TabsList>
					</Tabs.Root>
				)}
				{!hasTabLeases && tab === LeasesTabEnum.UNLOCKED_LEASES && (
					<NoDataTab
						minHeight={`${MIN_TAB_HEIGHT}px`}
						message={
							<Flex alignItems="center" gap="0.5em">
								You haven't unlocked any comps for this property, please visit
								the{' '}
								<AllLeasesButton
									onClick={() => setTab(LeasesTabEnum.ALL_LEASES)}
								>
									All Leases
								</AllLeasesButton>{' '}
								tab to unlock now!
							</Flex>
						}
					/>
				)}
				{hasTabLeases && (
					<>
						<Flex alignItems="center">
							<TabContentTitle>
								{isExchange
									? tab === LeasesTabEnum.UNLOCKED_LEASES
										? `Unlocked Property Leases (${ownedLeases.length})`
										: `All Property Leases (${leases.length})`
									: 'Property Leases'}
							</TabContentTitle>
							<div>
								<BlueLink
									to={leasesUrl}
									data-qa-id="SeeAllLeasesLink"
									target="_blank"
								>
									SEE ALL LEASES IN LIST VIEW
								</BlueLink>
							</div>
						</Flex>
						<RadioButtonGroup
							disableSpaceType={tab === LeasesTabEnum.UNLOCKED_LEASES}
							leasesGrouppedBySpaceType={leasesGrouppedBySpaceType}
							leasesByStatus={leasesByStatus}
							checkedSpaceType={checkedSpaceType}
							setCheckedSpaceType={setCheckedSpaceType}
							checkedStatus={checkedStatus}
							setCheckedStatus={setCheckedStatus}
						/>
					</>
				)}
				{hasTabLeases && !hasFilteredLeases && (
					<NoDataTab
						minHeight={`${MIN_TAB_HEIGHT}px`}
						message={
							<>
								<div>
									CompStak doesn't have any Active Leases for this property at
									the moment.
								</div>
								{isExchange && (
									<>
										<div>
											Be the first to report an Active Lease for this property
											and earn an extra FREE comp!
										</div>
									</>
								)}
							</>
						}
						action={
							isExchange && (
								<UploadCompButton section="lease">
									Upload lease comp
								</UploadCompButton>
							)
						}
					/>
				)}
				{hasFilteredLeases && (
					<>
						<LeasesTable
							leases={filteredLeases}
							onUnlockLeasesSuccess={onUnlockSuccess}
						/>
						<TableActionsContainer>
							<ExportCompsButton
								compType="lease"
								comps={unlockedPropertyLeases.map((row) => row)}
								buttonType="table"
							/>
							{isExchange && tab === LeasesTabEnum.ALL_LEASES && (
								<UnlockButtonTooltip comps={lockedPropertyLeases}>
									<div>
										<UnlockButton
											onClick={() => {
												unlockLeases({
													ids: lockedPropertyLeases.map((row) => row.id),
												});
											}}
											isLoading={isUnlocking}
											disabled={
												lockedPropertyLeases.length === 0 ||
												isUserFetching ||
												isUnlocking ||
												!hasEnoughPoints
											}
										>
											{unlockButtonText}
										</UnlockButton>
									</div>
								</UnlockButtonTooltip>
							)}
						</TableActionsContainer>
					</>
				)}
			</TableContainer>
		</TabContainer>
	);
};

const AllLeasesButton = styled.button`
	background: none;
	border: 0;
	padding: 0;
	color: ${(p) => p.theme.colors.blue.blue400};
`;
