import * as Tabs from '@radix-ui/react-tabs';
import { PortfolioTab } from 'PortfolioAnalytics/Tabs/PortfolioTab';
import { MarketsTab } from 'PortfolioAnalytics/Tabs/MarketsTab';
import { useState } from 'react';
import styled from 'styled-components';
import { DashboardHeader } from './DashboardHeader';
import { DashboardHeaderV2 } from './DashboardHeader/DashboardHeaderV2';
import { DashboardSidebar } from './DashboardSidebar';
import { PortfolioOverview } from './PortfolioOverview';
import { PortfolioOverviewV2 } from './PortfolioOverview/PortfolioOverviewV2';
import { LeaseActivityTab } from 'PortfolioAnalytics/Tabs/LeaseActivityTab';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { PortfolioLeasesProvider } from 'PortfolioAnalytics/LeaseActivity/PortfolioLeasesProvider';
import { PropertiesTab } from 'PortfolioAnalytics/Tabs/PropertiesTab';
import { PortfolioPropertiesProvider } from 'PortfolioAnalytics/Properties/PortfolioPropertiesProvider';
import { routes, useNavigate } from 'router';

type Props = {
	portfolioId: number;
};

export enum PortfolioTabEnum {
	OVERVIEW = 'overview',
	MARKETS = 'markets',
	BENCHMARKING = 'benchmarking',
	LEASE_ACTIVITY = 'leaseActivity',
	PROPERTIES = 'properties',
}

export const Dashboard = ({ portfolioId }: Props) => {
	const {
		portfolioRevampFF,
		portfolioRevampOverviewTabFF,
		portfolioRevampPropertiesTabFF,
	} = useFeatureFlags();

	const [portfolioSearchParams] = routes.portfolioByIdView.useSearchParams();

	const HeaderComponent = portfolioRevampOverviewTabFF
		? DashboardHeaderV2
		: DashboardHeader;
	const OverviewComponent = portfolioRevampOverviewTabFF
		? PortfolioOverviewV2
		: PortfolioOverview;
	const portfolioTabValue = portfolioRevampOverviewTabFF
		? PortfolioTabEnum.OVERVIEW
		: 'portfolio';

	const [tab, _setTab] = useState<PortfolioTabEnum | 'portfolio' | undefined>(
		portfolioRevampFF
			? portfolioSearchParams.tab ?? PortfolioTabEnum.OVERVIEW
			: portfolioTabValue
	);

	const setTab = (tab: PortfolioTabEnum) => {
		_setTab(tab);

		if (portfolioRevampFF) {
			navigate(
				routes.portfolioByIdView.toHref(
					{
						portfolioId,
						viewType: 'dashboard',
					},
					{ tab }
				)
			);
		}
	};

	const [contentEl, setContentEl] = useState<HTMLDivElement | null>(null);

	const navigate = useNavigate();

	return (
		<Root>
			<Content ref={setContentEl}>
				<Tabs.Root
					value={tab}
					onValueChange={(value) => {
						setTab(value as PortfolioTabEnum);
					}}
				>
					<HeaderComponent
						portfolioId={portfolioId}
						dashboardElement={contentEl}
					/>

					<TabContentContainer>
						<Tabs.Content value={portfolioTabValue}>
							<OverviewComponent portfolioId={portfolioId} />
							<PortfolioTab portfolioId={portfolioId} />
						</Tabs.Content>
						<Tabs.Content value={PortfolioTabEnum.MARKETS}>
							<OverviewComponent portfolioId={portfolioId} />
							<MarketsTab portfolioId={portfolioId} />
						</Tabs.Content>
						<Tabs.Content value={PortfolioTabEnum.BENCHMARKING}>
							<h1>Benchmarking</h1>
						</Tabs.Content>
						<Tabs.Content value={PortfolioTabEnum.LEASE_ACTIVITY}>
							<PortfolioLeasesProvider>
								<LeaseActivityTab />
							</PortfolioLeasesProvider>
						</Tabs.Content>
						<Tabs.Content value={PortfolioTabEnum.PROPERTIES}>
							<PortfolioPropertiesProvider>
								<PropertiesTab />
							</PortfolioPropertiesProvider>
						</Tabs.Content>
					</TabContentContainer>
				</Tabs.Root>
			</Content>
			{!portfolioRevampPropertiesTabFF && (
				<DashboardSidebar portfolioId={portfolioId} />
			)}
		</Root>
	);
};

const Root = styled.div`
	background: #f6f6f6;
	display: flex;
	height: 100%;
`;

const Content = styled.div`
	height: 100%;
	flex: 1;
	overflow: auto;
`;

const TabContentContainer = styled.div`
	padding: 0 1.5rem;
`;
